import React, { useState, useEffect } from 'react'
import Widget from "../DashbordWidget";
import ProfileTable from "../Tables/ProfileTable";
import utils from '../../utils'

//Redux
import { getRoads } from 'src/redux/SocialServices/action';
import { useDispatch, useSelector } from 'react-redux';




const tableHeading = {
    name: "District",
    feeder: "Total Feeder Roads",
    urban: "Total Urban Roads",
    tarred: "Total Tarred Roads",
    motorable: "Total Motorable Roads",
    network: "Total Road Network"
};


function Roads() {
    const dispatch = useDispatch()
    const { roads } = useSelector((state) => state.socialServices);
    
    let pagination = roads.pagination;
    
    const [tableMeta, setTableMeta] = useState({
      currentPage: 1,
      totalPages: 1,
      total: 0,
      limit: 10,
    });

    const [profile, setprofile] = useState({})
    const [loading, setloading] = useState(false)
    const [data, setdata] = useState([])

    const SetProfile = (Roads) => {
        const newRoads = roads?.list?.find(
          (el) => el.id === Roads?.id
        );
        setprofile(newRoads);
    };

    const dataItems = [
        {
          id: 1,
          number: "",
          link: "",
          name: "Feeder Roads",
          bck: "#7A6EFE",
        },
        {
          id: 2,
          number: "",
          link: "",
          name: "Urban Roads",
          bck: "#FF5363",
        },
        {
          id: 3,
          number: "",
          link: "",
          name: "Tarred Roads",
          bck: "#FFA901",
        },
        {
          id: 4,
          number: "",
          link: "",
          name: "Motorable Roads",
          bck: "#24A8FA",
        },
    ];

    const TableDetailsInfo = () => (
        <section className="text-lg">
          <div className="w-full flex items-center justify-start pb-1">
            <div className="w-1/2">District</div>
            <div className="text-gray-1">{profile.name}</div>
          </div>
          <div className="w-full flex items-center justify-start pb-1">
            <div className="w-1/2">Total Feeder Roads</div>
            <div className="text-gray-1">{profile.feeder}</div>
          </div>
          <div className="w-full flex items-center justify-start pb-1">
            <div className="w-1/2">Total Urban Roads</div>
            <div className="text-gray-1">{profile.urban}</div>
          </div>
          <div className="w-full flex items-center justify-start pb-1">
            <div className="w-1/2">Total Tarred Roads</div>
            <div className="text-gray-1">{profile.tarred}</div>
          </div>
          <div className="w-full flex items-center justify-start pb-1">
            <div className="w-1/2">Total Motorable Roads</div>
            <div className="text-gray-1">{profile.motorable}</div>
          </div>
          <div className="w-full flex items-center justify-start pb-1">
            <div className="w-1/2">Total Road Network</div>
            <div className="text-gray-1">{profile.network}</div>
          </div>
        </section>
    );

    useEffect(() => {
        let tepmArray = roads?.list?.map((el) => {
          return {
            id: el.id,
            name: el.district,
            feeder:'',
            urban: '',
            tarred: '',
            motorable:'',
            network: '',
          };
        });
    
        setdata(tepmArray);
    
        // if revenue.pagination is available
        if (utils.present(roads?.pagination)) {
          setTableMeta({
            ...tableMeta,
            total: pagination?.count,
            totalPages: pagination.totalPages,
            // limit: pagination.limit
          });
        }
    }, [roads]);
    

    const fetchRoads = (page, limit) => {
        setloading(true);
        dispatch(getRoads(page, limit))
        .then(() => {
          setloading(false);
        },() => {
          setloading(false);
        });
    };  

    const handleRoadFetch = (type, value) => {
        switch (type) {
          case 'limit':
            setTableMeta((prev) => { return { ...prev, currentPage: 1, limit: value } });
            fetchRoads(1, value);
          break;
    
          case 'currentPage':
            setTableMeta((prev) => { return {  ...prev, currentPage: value }});        
            fetchRoads(value, tableMeta.limit);
          break;
        
          default:
            break;
        }
    };

    useEffect(() => {
        fetchRoads()
    }, []);
    
  return (
    <div>

        <div className="w-full flex flex-row">
         {dataItems.map((item) => (
          <div className="w-full sm:w-1/4">
            <Widget
              id={item.id}
              link={item.link}
              number={item.number}
              name={item.name}
              bck={item.bck}
              lastItem={dataItems.length}
            />
          </div>
         ))}
        </div>

        <ProfileTable
            title="Roads"
            tableHeading={tableHeading}
            SetProfile={SetProfile}
            list={data}
            hasFilter
            hasPagination
            entity="List"
            loading={loading}
            // meta={tableMeta}
            fetchList={handleRoadFetch}
        >
            <TableDetailsInfo />
        </ProfileTable>
    </div>
  )
}

export default Roads