import utils from "../../utils";

export const tableHeading = (info) => {
  switch (info) {
    case "1D1F":
      return {
        name: "District",
        name_of_factory: "Name of Factory",
        no_of_factories: "No Of Factories",
        location_of_factory: "Location of Factory",
        status: "Status of Completion",
        expected_no_of_jobs: "No Of Expected Jobs",
        nature_of_business: "Nature Of Business",
        feedback_on_performance: "Feedback Performance",
      };

    case "1V1D":
      return {
        name: "District",
        no_of_dams: "Number of Dams",
        location: "Location of Dam",
        status: "Status of Dam",
        feedback_on_performance: "Performance Feedback",
      };

    case "Greenery Ghana":
    case "GreeneryGhana":
      return {
        name: "District",
        no_of_trees_planted: "Number of Trees Planted",
        short_term_experts: "Experts",
        specific_support_provided: "Specific Support Provided",
        funds: "Support Type",
        feedback_on_performance_on_flagship_program: "Feedback on the performance",
      };

    case "PERD":
      return {
        name: "District",
        no_of_seedling_developed: "Number of Seedlings Developed so Far",
        no_of_beneficiary: "Number of Beneficiary Farmers",
        total_size_land_planted: "Total Size of Land Planted",
        type_of_crops: "Type of Crops",
        feedback: "Feedback on the performance of the Flagship Programme",
      };

    case "Agenda111":
      return {
        name: "District",
        name_of_hospital: "Name of Hospital",
        location: "Location of Hospital",
        status_of_project: "Status of Completion",
        start_date: "Start Date",
        expected_date: "Expected Completion Date",
        percentage_of_completion: "Percentage of Completion",
        contractors: "Contractor(s)",
      };

    case "PFJ":
      return {
        name: "District",
        no_of_farmers: "Number of  Farmers",
        feedback: "Feedback on the performance of the Flagship Programme",
      };

    case "Scholarship For Needy":
    case "ScholarshipForNeedy":
      return {
        name: "District",
        amount_disbursed_assembly_scholarship:
          "District Scholarship Amount Disbursed",
        amount_disbursed_mps_scholarship: "Mps Scholarship Amount Disbursed",
        no_of_beneficiaries_assembly_scholarship:
          "Number of Beneficiary For Scholarship",
        no_of_beneficiaries_mps_scholarship: "Number of Mps For Scholarship",
      };

    case "School Feeding Program":
    case "SchoolFeedingProgram":
      return {
        name: "District",
        no_of_schools: "Number of Schools on the Program",
        no_of_pupils: "No. of Pupils",
        no_of_caterers: "Number of Caterers",
        no_of_school_without_feeding: "No. of Schools without School feeding",
      };

    default:
      break;
  }
};

export const SetTableBody = (info = "", data = []) => {
  switch (info) {
    case "1D1F":
      return data.map((el) => {
        return {
          id: el.id,
          name: el.district,
          name_of_factory: el?.name_of_factory,
          no_of_factories: el?.no_of_factories,
          location_of_factory: el?.location_of_factory,
          status: (
            <div className="text-center text-white bg-green-600 rounded-full p-1">
              {el.status_of_factory || "N/A"}
            </div>
          ),
          expected_no_of_jobs: (
            <div className="text-center">
              {utils.numberWithCommas(el?.expected_no_of_jobs)}
            </div>
          ),
          nature_of_business: el?.nature_of_business,
          feedback_on_performance: el?.feedback_on_performance,
        };
      });

    case "1V1D":
      return data.map((el) => {
        return {
          name: el.district,
          no_of_dams: el?.no_of_dams,
          location: el?.location_of_dam,
          status: (
            <div className="text-center text-white bg-green-600 rounded-full px-4 py-1">
              {el?.status_of_dam || "N/A"}
            </div>
          ),
          feedback_on_performance: el?.feedback_on_performance,
        };
      });

    case "Greenery Ghana":
    case "GreeneryGhana":
      return data.map((el) => {
        return {
          name: el.district,
          no_of_trees_planted: utils.numberWithCommas(el?.no_of_trees),
          short_term_experts: el?.short_term_experts,
          specific_support_provided: el?.specific_support_provided,
          funds: el?.funds,
          feedback_on_performance_on_flagship_program: el?.feedback_on_performance,
        };
      });

    case "PERD":
      return data.map((el) => {
        return {
          name: el.district,
          no_of_seedling_developed: utils.numberWithCommas(el?.no_of_seedlings),
          no_of_beneficiary: utils.numberWithCommas(el?.no_of_farmers),
          total_size_land_planted: utils.numberWithCommas(
            el?.total_size_of_land
          ),
          type_of_crops: el?.type_of_crop,
          feedback: el?.feedback_on_performance,
        };
      });

    case "Agenda111":
      return data.map((el) => {
        return {
          name: el.district,
          name_of_hospital: el?.name_of_hospital,
          location: el?.location,
          status_of_project: (
            <div className="text-center text-white bg-green-600 rounded-full px-4 py-1">
              {el?.status_of_project || "N/A"}
            </div>
          ),
          start_date: new Date(el?.start_date)?.toLocaleDateString(),
          expected_date: new Date(
            el?.expected_completion_date
          )?.toLocaleDateString(),
          percentage_of_completion: el?.percentage_of_completion,
          contractors: el?.contractor,
        };
      });

    case "PFJ":
      return data.map((el) => {
        return {
          name: el.district,
          no_of_farmers: utils.numberWithCommas(el?.no_of_farmers),
          feedback: el?.feedback,
        };
      });

    case "Scholarship For Needy":
    case "ScholarshipForNeedy":
      return data.map((el) => {
        return {
          name: el.district,
          amount_disbursed_assembly_scholarship:
            el?.amount_disbursed_assembly_scholarship,
          amount_disbursed_mps_scholarship:
            el?.amount_disbursed_mps_scholarship,
          no_of_beneficiaries_assembly_scholarship:
            el?.no_of_beneficiaries_assembly_scholarship,
          no_of_beneficiaries_mps_scholarship:
            el?.no_of_beneficiaries_mps_scholarship,
        };
      });

    case "School Feeding Program":
    case "SchoolFeedingProgram":
      return data.map((el) => {
        return {
          name: el.district,
          no_of_schools: utils.numberWithCommas(el?.no_of_schools_on_program),
          no_of_pupils: utils.numberWithCommas(el?.no_of_pupils),
          no_of_caterers: utils.numberWithCommas(el?.no_of_caterers),
          no_of_school_without_feeding: utils.numberWithCommas(
            el?.no_of_schools_on_program
          ),
        };
      });

    default:
      break;
  }
};
