import React, { useState, useEffect } from "react";
import Widget from "../../component/DashbordWidget";
import ProfileTable from "../Tables/ProfileTable";
import utils from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import { getPoliticalIssuesById } from "src/redux/PoliticalIssues/action";

const tableHeading = {
  stakeholders: "MMDCE  interface With Stakeholders",
  quality: "Quality of interface",
  participants: "No. of participants",
  venue: "Venue",
  date: "Date",
};

const PoliticalIssues = ({ id }) => {
  const dispatch = useDispatch();
  const { politicalIssues, meta } = useSelector(
    (state) => state.politicalIssues
  );
  const [profile, setprofile] = useState({});
  const [data, setdata] = useState([]);

  const SetProfile = (profile) => {
    setprofile(profile);
  };

  const dataItems = [
    {
      id: 1,
      number: meta?.Total_Participants,
      link: " ",
      name: "Total Participants",
      bck: "#7A6EFE",
    },
  ];

  const TableDetailsInfo = () => (
    <section className="text-lg">
      <div className="w-full flex items-start justify-start pb-1">
        <div className="w-1/2">MMDCE interface With Stakeholders</div>
        <div className="text-gray-1 w-1/2">{profile.stakeholders}</div>
      </div>
      <div className="w-full flex items-start justify-start pb-1">
        <div className="w-1/2">Quality of interface</div>
        <div className="text-gray-1 w-1/2">{profile.quality}</div>
      </div>
      <div className="w-full flex items-start justify-start pb-1">
        <div className="w-1/2">No. of participants</div>
        <div className="text-gray-1 w-1/2">{profile.participants}</div>
      </div>
      <div className="w-full flex items-start justify-start pb-1">
        <div className="w-1/2">Venue</div>
        <div className="text-gray-1 w-1/2">{profile.venue}</div>
      </div>
      <div className="w-full flex items-start justify-start pb-1">
        <div className="w-1/2">Date</div>
        <div className="text-gray-1 w-1/2">{profile.date}</div>
      </div>
    </section>
  );

  useEffect(() => {
    let tepmArray = politicalIssues?.list?.map((el) => {
      return {
        id: el.id,
        stakeholders: el.stakeholders,
        quality: el.political_issues_quality_of_interface,
        participants: utils.numberWithCommas(
          el.political_issues_number_of_participants
        ),
        venue: el.political_issues_venue,
        date: new Date(el.political_issues_dates)?.toLocaleDateString(),
      };
    });

    setdata(tepmArray);
  }, [politicalIssues]);

  useEffect(() => {
    dispatch(getPoliticalIssuesById(id));
  }, [id]);

  return (
    <div>
      <div className="w-1/2 flex flex-row">
        {dataItems.map((item) => (
          <div className="w-1/2 sm:w-1/2">
            <Widget
              id={item.id}
              link={item.link}
              number={item.number}
              name={item.name}
              bck={item.bck}
              lastItem={dataItems.length}
            />
          </div>
        ))}
      </div>

      <div className="">
        <ProfileTable
          title="Political Issues"
          tableHeading={tableHeading}
          list={data}
          SetProfile={SetProfile}
        >
          <TableDetailsInfo />
        </ProfileTable>
      </div>
    </div>
  );
};

export default PoliticalIssues;
