import React, { useState, useEffect, memo, useCallback } from "react";
import ProfileTable from "../../component/Tables/ProfileTable";
import Widget from '../DashbordWidget'
import utils from "../../utils";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { getEconomicData, getDetails } from "../../redux/EconomicData/action";

// Images
import goBack from "../../asset/images/arrow-left.svg";

// Components
import DetailsPage from "../Fiscal/DetailsPage";

const meta_colors = ["#7A6EFE", "#FF5363", "#FFA901"];

function EconomicDataTab({ id }) {
  const dispatch = useDispatch();
  const { economicData, meta, pagination, details } = useSelector((state) => state.economicData);

  const [tableMeta, setTableMeta] = useState({});
  const [loading, setloading] = useState(false);
  const [viewDetails, setviewDetails] = useState(false);
  const [info, setinfo] = useState({});
  const [data, setdata] = useState([]);
  const [detailsList, setdetailsList] = useState([]);
  const [dataItems, setdataItems] = useState([]);
  const [metaInfo, setmetaInfo] = useState({});
  const [tableHeading, setTableHeading] = useState({
    name: "District",
    activities: "Economic Activities",
    jobs: "No Of Jobs",
    support: "Supported by REGION",
    type: "Type Of Support",
    status: "Status",
  });

  const SetModalInfo = (econ) => {
    const economic = economicData?.find((el) => el.id === econ?.id);
    setinfo(economic);
  };

  const fetchEconomicData = (page, limit, search="") => {
    setloading(true);
    dispatch(getEconomicData(id, page, limit, search)).then(
      () => {
        setloading(false);
      },
      () => {
        setloading(false);
      }
    );
  };

  const handleGetDetails = async (name, page, limit) => {
    setloading(true);

    dispatch(getDetails(name, page, limit)) 
    .then((response) => {
      setloading(false);

      setTableMeta((prev) => {
        return { 
          ...prev, 
          currentPage: page, 
          limit: parseInt(response?.limit),
          totalPages: response?.pages,
          total: response?.count
        };
      });

      setviewDetails(true);
    })    
  }

  const handleEconomicFetch = (type, value) => {
    switch (type) {
      case "limit":
        if (viewDetails) {
          handleGetDetails(metaInfo.name, 1, value);
        } else {
          setTableMeta((prev) => {
            return { ...prev, currentPage: 1, limit: value,  };
          });
          fetchEconomicData(1, value);
        }

      break;

      case "currentPage":
        if (viewDetails) {
          handleGetDetails(metaInfo.name, value, tableMeta.limit);
        } else {
          setTableMeta((prev) => {
            return { ...prev, currentPage: value };
          });
          fetchEconomicData(value, tableMeta.limit);
        }

      break;

      default:
        break;
    }
  };

  const handleClick = useCallback((item) => {
    setmetaInfo(item);
    handleGetDetails(item.name, 1, 10);
  }, []);

  // Element componets
  const EconomicDetails = () => (
    <section className="text-lg">
      <div className="w-full justify-between flex items-center pb-2">
        <div className="">Local Economic Activity</div>
        <div className="text-gray-1">{info.local_economic_activity}</div>
      </div>
      <div className="w-full justify-between flex items-center pb-2">
        <div className="">Status</div>
        <div className="text-white py-2 rounded-full text-medium">
          <div className="text-center text-white bg-green-600 rounded-full p-2">
            {info.statuss}
          </div>
        </div>
      </div>
      <div className="w-full justify-between flex items-center pb-2">
        <div className="">Support Provided by MMDA </div>
        <div className="text-gray-1">{info.support_provided_by_mmda}</div>
      </div>
      <div className="w-full justify-between flex items-center pb-2">
        <div className="">Types of Support</div>
        <div className="text-gray-1">{info.type_of_support}</div>
      </div>
      <div className="w-full justify-between flex items-center pb-2">
        <div className="">No. of Jobs</div>
        <div className="text-gray-1">
          {info.no_of_new_jobs_district_the_economic_activity || "N/A"}
        </div>
      </div>
      <div className="w-full justify-between flex items-center pb-2">
        <div className=""> Economic Output</div>
        <div className="text-gray-1">{utils.numberWithCommas(info.economic_output)}</div>
      </div>
      <div className="w-full justify-between flex items-center pb-2">
        <div className=""> Target Market</div>
        <div className="text-gray-1">{info.target_market}</div>
      </div>
      <div className="w-full justify-between items-center pb-1">
        <div className=""> Key Challenges</div>
        <div className="text-gray-1">{info.key_challenges}</div>
      </div>
    </section>
  );  

  useEffect(() => {
    let tepmArray = economicData?.map((el) => {
      let eco = {
        id: el.id,
        name: el.district,
        activities: el.local_economic_activity,
        jobs: el.no_of_new_jobs_created_by_the_economic_activity,
        support: el.support_provided_by_mmda,
        type: el.type_of_support,
        status: (
          <div className="text-center text-white bg-green-600 rounded-full p-1">
            {el.statuss || "N/A"}
          </div>
        ),
      };

      if (id) delete eco.name;
      return eco;
    }); 

    setdata(tepmArray);
  }, [economicData]);

  useEffect(() => {
    if (utils.present(pagination)) {
      setTableMeta({
        ...tableMeta,
        total: pagination.count,
        totalPages: pagination.totalPages,
      });
    }
  }, [pagination]);

  useEffect(() => {
    let tepmArray = details?.list?.map((el) => {
      return {
        id: el.id,
        name: el.district,
        activities: el.local_economic_activity,
        jobs: el.no_of_new_jobs_created_by_the_economic_activity,
        support: el.support_provided_by_mmda,
        type: el.type_of_support,
        status: (
          <div className="text-center text-white bg-green-600 rounded-full p-1">
            {el.statuss || "N/A"}
          </div>
        ),
      }
    }); 

    setdetailsList(tepmArray);
  }, [details]);

  useEffect(() => {
    if (utils.present(meta)) {
      let tempDataItems = Object.keys(meta).map((oneKey, i) => {
        return {
          id: i+1,
          number: meta[oneKey],
          link: "",
          name: oneKey,
          bck: meta_colors[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
        }
      });
      setdataItems(tempDataItems);
    }
  }, [meta]);

  useEffect(() => {
    if (id) {
      let headings = tableHeading;
      delete tableHeading.name;
      setTableHeading(headings);
    }

    if (!viewDetails) {
      setTableMeta({
        currentPage: 1,
        totalPages: 1,
        total: 0,
        limit: 10,
      });
      
      fetchEconomicData();
    }
  }, [id, viewDetails]);

  return (
    <div className="w-full">
      {viewDetails ?
        <DetailsPage 
          active={4}
          setviewDetails={setviewDetails} 
          goBack={goBack}
        >
          <ProfileTable
            title={metaInfo.name}
            tableHeading={tableHeading}
            list={detailsList}
            hasFilter={false}
            entity={metaInfo.name}
            meta={tableMeta}
            loading={loading}
            fetchList={handleEconomicFetch}
            hasPagination
            showModal={false}
          />
        </DetailsPage>        
      :
        <>
          {!id &&
            <div className="w-full flex flex-row overflow-y-hidden">
              {!loading ?
                dataItems.map((item) => (
                  <div className="w-1/2 sm:w-auto h-36" style={{ minWidth: "15rem"}}>
                    <Widget 
                      id={item.id} 
                      link={item.link} 
                      number={item.number} 
                      name={item.name} 
                      bck={item.bck} 
                      lastItem={dataItems.length} 
                      handleClick={() => handleClick(item)}
                    />

                  </div>
                ))
              :
                new Array(4).fill()?.map(() => (
                    <div class="w-1/4 h-36 flex justify-center animate-pulse mr-5">
                      <div class="h-full w-full bg-slate-200 rounded-lg" />
                    </div>
                  ))
                }
            </div>
          }

          <ProfileTable
            title="Economic Data"
            tableHeading={tableHeading}
            SetProfile={SetModalInfo}
            list={data}
            hasFilter={id ? false : true}
            entity="Economic Data"
            hasPagination={id ? false : true}
            meta={tableMeta}
            loading={loading}
            fetchList={handleEconomicFetch}
            handleSearch={(e) => fetchEconomicData(1, 10, e)}
          >
            <EconomicDetails />
          </ProfileTable>
        </>
      }
    </div>
  );
};

export default memo(EconomicDataTab);
