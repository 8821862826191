import React, { useState, memo } from "react";

const ProjectPopUp = ({ list = [], handleShowImag = () => {} }) => {
  const [loading, setloading] = useState(true);
  const [noPhotos, setnoPhotos] = useState(false);

  return (
    <div className="w-full pt-4 text-lg flex flex-wrap items-start">
      {list?.map((el, index) => (
        <div
          key={index}
          className="w-full relative cursor-pointer"
          onClick={() => handleShowImag(index + 1)}
        >
          {loading &&
            <div className="h-64 w-full absolute mb-4 inset-0 bg-black bg-opacity-25 rounded-b-lg transition-all ease-in duration-700 flex justify-center items-center">
              <svg className="animate-spin h-10 w-10 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            </div>
          }

          <img
            src={el}
            className="hover:scale-105 transition duration-150 ease-in-out"
            onLoad={() => { setloading(false) }}
            onError={() => { 
              setloading(false) 
              setnoPhotos(true);
            }}
            alt=""
          />

          {noPhotos && 
           <div className="text-base font-bold pt-4">No Photos available for this project</div>
          }
        </div>
      ))
      }
    </div>
  );
}

export default memo(ProjectPopUp);
