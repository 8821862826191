import Layout from "../component/Layout/Layout";
import EconomicDataTable from "../component/ProfileTabs/EconomicDataTab";

function EconomicData() {
  return (
    <Layout pageTitle="Economic Data">
      <EconomicDataTable />
    </Layout>
  );
}

export default EconomicData;
