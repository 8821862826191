import * as types from './types';

const initState = {
    politicalIssues: {
        list: [],
        meta: {},
        pagination: {}
    },
    meta:{}
};

export const politicalIssues = (state = initState, action) => {
    switch (action.type) {
        case types.POLITICAL_ISSUES:
            return {
                ...state,
                politicalIssues: {
                    list: action.payload.results, 
                    meta: action.payload.meta,
                    pagination: { 
                      count: action.payload.count,
                      totalPages: action.payload.pages,
                      limit: action.payload.limit,                       
                      next: action.payload.next, 
                      previous: action.payload.previous 
                    }
                }
            }

        case types.SET_POLITICAL_ISSUES:
        const payload = action.payload;

        return {
            ...state,
            meta: payload.meta,
            politicalIssues: {
            ...state.politicalIssues,
            list: payload.results,
            },
        };

        default:
            return state;
    }
}
