import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase/firebase";

import { toast } from "react-toastify";

import loginGif from "../asset/images/login.gif";

function Login() {
  const navigate = useNavigate();

  const [data, setData] = useState({
    email: "",
    username: "",
    password: "",
    errorMsg: "",
    loading: false,
  });

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setData({ ...data, errorMsg: null, loading: true });

    try {
      signInWithEmailAndPassword(auth, username, password)
        .then((response) => {
          sessionStorage.setItem("Token", response._tokenResponse.refreshToken);
          toast.success("Login Successful");
          navigate("/profile");
        })
        .catch((error) => {
          setData({ ...data, errorMsg: error.code, loading: false });

          console.log("error:", error.code);

          switch (error.code) {
            case "auth/wrong-password":
            case "auth/invalid-email":
              toast.error("Wrong or invalid email/password");
              break;

            case "auth/user-not-found":
              toast.error("User not found");
              break;

            default:
              toast.error(error.code);
              break;
          }
        });
    } catch (error) {
      setData({ ...data, errorMsg: error.message, loading: false });
    }
  };

  const { username, password, loading } = data;

  return (
    <div className="h-screen w-full flex justify-start flex-wrap">
      <div className="w-full sm:w-1/2 h-full bg-gray-100 flex flex-col justify-center items-center">
        <div className="w-full flex justify-center items-center p-4 sm:p-0">
          <div className="w-full sm:w-2/3 items-center rounded-lg overflow-hidden shadow-lg bg-white p-4 sm:py-10 sm:px-16">
            <section className="">
              {/* <p>{errorMsg ? <p>{errorMsg}</p> : null}</p> */}
              <h2 className="text-center text-2xl font-bold py-4 block sm:hidden">
                Regional Insights Admin
              </h2>
            </section>

            <section className="p-5 mt-5 bg-gray-100 rounded-lg">
              <p className="text-center text-3xl font-bold">Login</p>
            </section>

            <section>
              <form className="flex flex-col mt-6" onSubmit={handleSubmit}>
                <div className="mb-6 pt-3 rounded">
                  <label className="block text-sm font-semibold pb-1">
                    Username
                  </label>
                  <input
                    type="text"
                    value={username}
                    name="username"
                    onChange={handleChange}
                    placeholder="Username"
                    required
                    className="rounded border border-gray-200 px-2 py-2 w-full"
                  />
                </div>

                <div className="mb-6 pt-2">
                  <label className="block text-sm font-semibold pb-1">
                    Password
                  </label>
                  <input
                    type="password"
                    required
                    value={password}
                    name="password"
                    onChange={handleChange}
                    className="rounded border border-gray-200 px-2 py-2 w-full"
                    placeholder="Password"
                  />
                </div>

                <div className="mt-6">
                  <button
                    type="submit"
                    className="flex items-center justify-center w-full bg-blue-600 rounded shadow-lg px-3 py-2 text-white relative top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                  >
                    {loading && (
                      <svg
                        className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    )}
                    Sign In
                  </button>
                </div>
              </form>
            </section>
          </div>
        </div>
      </div>

      <div className="w-full sm:w-1/2 h-full hidden sm:flex justify-center items-center">
        <div className="flex justify-center items-center">
          <div>
            <p className="text-center text-3xl font-bold pt-8">
              Regional Insights Admin Page
            </p>
            <img src={loginGif} alt="Error page" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
