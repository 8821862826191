import * as types from "./types";

const initState = {
  staffingData: {
    list: [],
    meta: {},
    pagination: {}
  },
  iteneraries: {
    list: [],
    meta: {},
    months: [],
    pagination: {}
  },
  capacityBuilding: {
    plans: [],
    implemented: []
  },
  complaints: []
};

export const administrative = (state = initState, action) => {
  switch (action.type) {
    case types.STAFFING_DATA:
      const { meta, results, limit, pages, count, next, previous } = action.payload;

      return {
        ...state,
        staffingData: { 
          list: results, 
          meta,
          pagination: { 
            count,
            limit,
            totalPages: pages,            
            next, 
            previous 
          }
        }
      };

    case types.ITENERARIES:
      return {
        ...state,
        iteneraries: { 
          list: action.payload.results, 
          meta: action.payload.meta,
          pagination: { 
            count: action.payload.count, 
            next: action.payload.next, 
            previous: action.payload.previous 
          }
        }
      };

    case types.CAPACITY_PLANS:
      return {
        ...state,
        capacityBuilding: {
          ...state.capacityBuilding,
          plans: action.payload
        },
      };

    case types.CAPACITY_IMPLEMENTED:
      return {
        ...state,
        capacityBuilding: {
          ...state.capacityBuilding,
          implemented: action.payload
        },
      };

    case types.COMPLAINTS:
      return {
        ...state,
        complaints: action.payload,
      };
      
    case types.SET_ADMINISTRATIVE:
      const payload = action.payload;

      return {
        ...state,
        staffingData: { 
          ...state.staffingData,
          list: payload.results[0]?.staffing, 
        },
        iteneraries: { 
          ...state.iteneraries,
          list: payload.results[0]?.itinerary, 
          months: payload.meta,
        },
        capacityBuilding: {
          plans: payload.results[0]?.capacity_plans,
          implemented: payload.results[0]?.capacity_implemented
        },
        complaints: payload.results[0]?.all_complaints,
      };
      
    default:
      return state;
  }
};
