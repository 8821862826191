import api from "../../services/config";
import * as types from './types';

export const getRevenue = (page=1, limit=10, search="") => async (dispatch) => {
    return new Promise((resolve, reject) => {
        let url = `/revenue/`;
        if (page) url += `?page=${page}`;
        if (limit) url += `&limit=${limit}`;
        if (search) url += `&search=${search}`;

        return api.get(url)
        .then((response) => {
            console.log('Response', response.data);

            dispatch({
                type: types.REVENUE,
                payload: response.data
            })

            resolve(response);
        })
        .catch((error) => {
            reject(error);
        })
    });
};

export const getExpenditure = (page=1, limit=10, search="") => async (dispatch) => {
    return new Promise((resolve, reject) => {
        let url = `/expenditure/`;
        if (page) url += `?page=${page}`;
        if (limit) url += `&limit=${limit}`; 
        if (search) url += `&search=${search}`;

        return api.get(url)
        .then((response) => {
            console.log('Response', response.data);

            dispatch({
                type: types.EXPENDITURE,
                payload: response.data
            })

            resolve(response);
        })
        .catch((error) => {
            reject(error);
        })
    });
};

export const getAudit = (page=1, limit=10, search="") => async (dispatch) => {
    return new Promise((resolve, reject) => {
        let url = `/audit/`;
        if (page) url += `?page=${page}`;
        if (limit) url += `&limit=${limit}`; 
        if (search) url += `&search=${search}`;

        return api.get(url)
        .then((response) => {
            console.log('Audit Response', response.data);

            dispatch({
                type: types.AUDIT,
                payload: response.data
            })

            resolve(response);
        })
        .catch((error) => {
            reject(error);
            console.log("Rob's error:", error);
        })
    });
};

export const getFiscalById = (id) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        return api.get(`/all_profiles/districts/${id}/fiscal/`)
        .then((response) => {
            dispatch({
                type: types.SET_FISCAL,
                payload: response.data
            })
            resolve(response);
        })
        .catch((error) => {
            reject(error);
        })
    });
};

export const getRevenueChart = (id) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        return api.get(`/revenue/chart/`)
        .then((response) => {
            dispatch({
                type: types.SET_REVENUE_CHARTS,
                payload: response.data
            })
            resolve(response);
        })
        .catch((error) => {
            reject(error);
        })
    });
};

export const getExpenditureChart = (id) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        return api.get(`/expenditure/chart/`)
        .then((response) => {
            dispatch({
                type: types.SET_EXPENDITURE_CHARTS,
                payload: response.data
            })
            resolve(response);
        })
        .catch((error) => {
            reject(error);
        })
    });
};

export const getAuditInfraction = (id, page=1, limit=10) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        let url = `/audit/infraction/${id}/`;
        if (page) url += `?page=${page}`;
        if (limit) url += `&limit=${limit}`; 

        return api.get(url)
        .then((response) => {
            dispatch({
                type: types.SET_AUDIT_INFRACTION,
                payload: response.data
            })
            resolve(response);
        })
        .catch((error) => {
            reject(error);
        })
    });
};
