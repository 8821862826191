import React, {useState, useEffect} from 'react'
import ProfileTable from './Tables/ProfileTable';
import { useSelector } from 'react-redux';

const tableHeading = {
  site: "Specific sites",
  location: "location",
  support: "Specific Support Provided by the Assembly",
};



const ParksAndGardens = () => {
  const { parksAndGardens } = useSelector(
    (state) => state.environmentAndSanitation
  );
  const [profile, setprofile] = useState({});
  const [data, setdata] = useState([])

  const SetProfile = (profile) => {
    setprofile(profile);
  };


  const TableDetailsInfo = () => (
    <section className="text-lg">
    <div className="w-full flex items-center justify-start pb-1">
      <div className="w-1/2">Specific sites</div>
      <div className="text-gray-1">{profile.site}</div>
    </div>
    <div className="w-full flex items-center justify-start pb-1">
      <div className="w-1/2">Location</div>
      <div className="text-gray-1">{profile.location}</div>
    </div>
    <div className="w-full flex items-center justify-start pb-1">
      <div className="w-1/2">Specific Support Provided by the Assembly</div>
      <div className="text-gray-1">{profile.support}</div>
    </div>
  </section>
  );

  useEffect(() => {
    let tepmArray = parksAndGardens?.list?.map((el) => {
      return  {
        site: el.sites,
        location: el.location,
        support: el.specific_support_provided
      };
    });

    setdata(tepmArray);

  },[parksAndGardens]);

  


  return (
    <div>
        <ProfileTable title='Parks And Gardens' tableHeading={tableHeading} showDivider list={data} SetProfile={SetProfile} > 
        <TableDetailsInfo/> 
      </ProfileTable>
    </div>
  )
}

export default ParksAndGardens