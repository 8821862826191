import api from "../../services/config";
import * as types from './types';

export const getPoliticalIssues = (page=1, limit=10, search="") => async (dispatch) => {
    return new Promise((resolve, reject) => {
        let url = `/political_issues/`;
        if (page) url += `?page=${page}`;
        if (limit) url += `&limit=${limit}`;
        if (search) url += `&search=${search}`

        return api.get(url)
        .then((response) => {
            console.log('Response', response.data);

            dispatch({
                type: types.POLITICAL_ISSUES,
                payload: response.data
            })

            resolve(response);
        })
        .catch((error) => {
            reject(error);
        })
    });
};

export const getPoliticalIssuesById = (id) => async (dispatch) => {
    return new Promise((resolve, reject) => {

        return api.get(`/all_profiles/districts/${id}/political_data/`)
        .then((response) => {
            dispatch({
                type: types.SET_POLITICAL_ISSUES,
                payload: response.data
            })
            resolve(response);
        })
        .catch((error) => {
            reject(error);
        })
    });
};

