import api from "../../services/config";
import * as types from './types';

export const getOtherIssuesOfInterest = (id, search="") => async (dispatch) => {
    return new Promise((resolve, reject) => {
        let url = '/other_issues/';
        if (search) url += `?search=${search}`
        if (id) url = `/all_profiles/districts/${id}/district_other_interest/`;

        return api.get(url)
        .then((response) => {
            console.log('Response', response.data.results);

            dispatch({
                type: types.OTHER_ISSUES_OF_INTEREST,
                payload: response.data.results
            })

            resolve(response);
        })
        .catch((error) => {
            reject(error);
        })
    });
};

