import React, { useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

const FancyTab = ({ 
  List = [],
  hasTitle=false,
  title="",
  hasOtherInfo=false,
  otherInfo=""
}) => {
  const [current, setcurrent] = useState(1);

  return (
    <div className="w-full">
      <div className="flex justify-between">
        {hasTitle && 
          <div>
            <h2 className="text-2xl font-semibold py-3 mb-0">{title}</h2>
          </div>        
        }

        <Nav className="p-2 bg-gray-100 rounded-lg" pills>
          {List.map((el, index) => (
            <NavItem className="flex items-center" key={el.id || index}>
              <NavLink
                className={`${current === el.id ? "bg-white text-primary" : "text-black"}`}
                active={current === el.id}
                onClick={() => setcurrent(el.id)}
              >
                {el.name}
              </NavLink>
            </NavItem>
          ))}
        </Nav>

        <div className={hasOtherInfo ? '' : 'px-4'}>
          {hasOtherInfo && <h2 className="text-xl font-semibold py-3 mb-0">{otherInfo}</h2>}
        </div>
      </div>

      <TabContent className="mt-8" activeTab={current}>
        {List.map((el, index) => (
            <TabPane tabId={el.id} key={el.id || index}>
              {el.content}
            </TabPane>
        ))}
      </TabContent>
    </div>
  );
};

export default FancyTab;