import React, {useState, useEffect,useCallback } from "react";
import utils from "../utils";

import ProjectPopUp from "./Infrastructure/ProjectPopUp";
import ProfileTable from "./Tables/ProfileTable";
import DetailsModal from './DetailsModal';

import { useSelector } from "react-redux";

const tableHeading = {
  name: "Project Name",
  road_type: "Type of Road",
  location: "Location",
  length: "Length",
  status: "Status",
  percentage: "Percentage of completion",
  start: "Project Commence",
  completion: "Expected Completion",
  cost:"Project Cost",
};

const RoadInfrastructure = () => {
  const { roadInfrastructure } = useSelector((state) => state.infrastructure)

  const [list, setlist] = useState([]);
  const [current, setcurrent] = useState(1);
  const [profile, setprofile] = useState({});
  const [data, setdata] = useState([]);
  const [showModal, setshowModal] = useState(false);

  const SetProfile = (profile) => {
    const record = roadInfrastructure?.list?.find(el => el.id === profile.id);
    setlist([record.picture_file_url]);
    setprofile({...profile, record});
  };

  const handleShowImag = useCallback((val) => {
    setcurrent(val);
    setshowModal(true);
  }, []);  

  const TableDetailsInfo = () => (
    <section className="text-lg">
    <div className="w-full flex items-start justify-start pb-3">
      <div className="w-1/2">Project Name</div>
      <div className="text-gray-1 w-1/2 text-end">{profile.name}</div>
    </div>
    <div className="w-full flex items-start justify-start pb-3">
      <div className="w-1/2">Type of Road</div>
      <div className="text-gray-1 w-1/2 text-end">{profile.road_type}</div>
    </div>
    <div className="w-full flex items-start justify-start pb-3">
      <div className="w-1/2">Location</div>
      <div className="text-gray-1 w-1/2 text-end">{profile.location}</div>
    </div>
    <div className="w-full flex items-start justify-start pb-3">
      <div className="w-1/2">Length of Road</div>
      <div className="text-gray-1 w-1/2 text-end">{profile.length}km</div>
    </div>
    <div className="w-full flex items-start justify-between pb-3">
      <div className="w-1/2">Construction Status</div>
      <div>{profile.construction_status}</div>
    </div>
    <div className="w-full flex items-start justify-start pb-3">
      <div className="w-1/2">Project Commencement</div>
      <div className="text-gray-1 w-1/2 text-end">{profile.start}</div>
    </div>   
    <div className="w-full flex items-start justify-start pb-3">
      <div className="w-1/2">Project Completion</div>
      <div className="text-gray-1 w-1/2 text-end">{profile.completion}</div>
    </div>   
    <div className="w-full flex items-start justify-start pb-3">
      <div className="w-1/2">Project Cost</div>
      <div className="text-gray-1 w-1/2 text-end">{profile.cost}</div>
    </div>   
    <div className="w-full"><ProjectPopUp list={list} handleShowImag={handleShowImag} /></div>     
  </section>
  );
  
  useEffect(() => {
    let tepmArray = roadInfrastructure?.list?.map((el) => {
      return  {
        id: el.id,
        name: el.project_name,
        road_type: el.road_type,
        location: el.location,
        length: `${el.road_length} km`,
        construction_status: <div className="text-center text-white bg-green-600 rounded-full py-1 px-2">{el.construction_status || "N/A"}</div>,
        percentage: <div className="text-center">{`${el.completion_status}%`}</div>,
        start: new Date(el.project_commence)?.toLocaleDateString(),
        completion: new Date(el.expected_completion)?.toLocaleDateString(),
        cost: utils.numberWithCommas(el.contract_sum),
      };
    });

    setdata(tepmArray);
  },[roadInfrastructure]);

  return (
    <>
      <ProfileTable
        title="Road"
        tableHeading={tableHeading}
        list={data}
        showDivider
        SetProfile={SetProfile}
      >
        <TableDetailsInfo/>
      </ProfileTable>

      {showModal && 
        <DetailsModal 
          current={current}
          showModal={showModal}
          handleClose={() => setshowModal(false)}
          items={list.map((el, index) => {
            return  {
              // altText: '',
              // caption: '',
              key: index+1,
              src: el
            }
          })}
        />
      }      
    </>
  );
};

export default RoadInfrastructure;
