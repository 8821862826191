import React, { useState, useEffect } from "react";
import ProfileTable from "../Tables/ProfileTable";
import Widget from "../DashbordWidget";
import DetailsPage from "./DetailsPage";
import utils from "../../utils";

import FileName from "../../asset/images/file.svg"

// Redux
import { useDispatch, useSelector } from "react-redux";
import { getAudit } from "../../redux/fiscal/action";

const Audit = () => {
  const dispatch = useDispatch();
  const { audit } = useSelector(state=>state.fiscal);
  let pagination = audit.pagination;

  const [tableMeta, setTableMeta] = useState({
    currentPage: 1,
    totalPages: 1,
    total: 0,
    limit: 10    
  });  
  const [info, setinfo] = useState({});
  const [viewDetails, setviewDetails] = useState(false);
  const [loading, setloading] = useState(false);
  const [data, setdata] = useState([]);

  const tableHeading =  {
    name: "Quarterly Audit Report",
    entry: "Date of Entry Conference",
    exit: "Date Of Exit Conference",
    infraction: "Any Major Infraction",
    actions: "Actions"
  };

  const dataItems = [
    {
      id: 1,
      number: audit?.meta?.district_without_report ,
      link: "/fiscal/districts-wto-reports",
      name: "Districts without Report",
      bck: "#7A6EFE",
      handleClick: () => {
        setviewDetails(true);
      },
    },
    {
      id: 2,
      number: audit?.meta?.district_with_cash_management_infractions ,
      link: "/fiscal/districts-wt-cash-mgmt",
      name: "Districts with Cash Management",
      bck: "#FF5363",
      handleClick: () => {},
    },
    {
      id: 3,
      number: audit?.meta?.district_with_procurement_infractions ,
      link: "/fiscal/districts-wt-procurement",
      name: "Districts with Procurement",
      bck: "#FFA901",
      handleClick: () => {},
    },
    {
      id: 4,
      number: audit?.meta?.district_with_project_mgmt_infractions ,
      link: "/fiscal/districts-wt-proj-and-contract",
      name: "Project & Contract Management",
      bck: "#24A8FA",
      handleClick: () => {},
    },
  ];

  const Setinfo = (info) => {
    const record = audit.list.find(el => el.id === info?.actions?.props['data-id']);
    console.log('Extracted record:', record);

    setinfo(record || info);
  };

  const TableDetailsInfo = () => (
    <section className="text-lg">
      <div className="w-full pb-2">
        <div className="">District</div>
        <div className="text-gray-1">{info.district}</div>
      </div>
      <div className="w-full pb-2">
        <div className="">Audit Committee</div>
        <div className="text-gray-1">{info.audit_committee_meeting}</div>
      </div>
      <div className="w-full pb-2">
        <div className="">Date of Entry into Conference</div>
        <div className="text-gray-1">{new Date(info.date_of_entry).toDateString()}</div>
      </div>      
      <div className="w-full pb-2">
        <div className="">Date of Exit Conference</div>
        <div className="text-gray-1">{new Date(info.meeting_date).toDateString()}</div>
      </div>      
      <div className="w-full">
        <div className="">Quarterly Audit Report (Attachment)</div>
        <div className="flex items-center justify-between pt-2">
          <div className="flex items-center">
            <img src={FileName} width={30} alt="" />
            {/* <img src={`data:image/jpeg;charset=utf-8;base64, ${info.audit_report_binary}`} width={30} alt="" /> */}
            <span className="pl-2">{info.audit_report_file_name || 'N/A'}</span>
          </div>

          <div className="hover:underline">
            {info.audit_report_binary && 
              // <a className="text-primary text-base hover:underline" href={info.audit_report_binary} target="_blank" rel="noopener noreferrer" download>Download</a>
              <span className="text-primary cursor-pointer hover:underline" onClick={()=> { 
                utils.downloadBase64(info.audit_report_binary, info.audit_report_file_name, "application/pdf")
              }}>Download</span>              
            }
          </div>
        </div>
      </div>      
    </section>
  );

  const fetchAudit = (page, limit, search="") => {
    setloading(true);
    dispatch(getAudit(page, limit, search))
    .then(() => {
      setloading(false);
    }, () => {
      setloading(false);
    }) 
  };    

  const handleAuditFetch = (type, value) => {
    switch (type) {
      case 'limit':
        setTableMeta((prev) => { return { ...prev, currentPage: 1, limit: value } });
        fetchAudit(1, value);
      break;

      case 'currentPage':
        setTableMeta((prev) => { return {  ...prev, currentPage: value }});        
        fetchAudit(value, tableMeta.limit);
      break;
    
      default:
        break;
    }
  };   

  useEffect(() => {
    let tepmArray = audit?.list?.map((el) => {
      return  {
        name: 
        <div className="flex items-center overflow-hidden"> 
          {el.audit_report_file_name && <img src={FileName} width={20} alt={el.audit_report_file_name} />}
          <span className="pl-2">{el.audit_report_file_name || 'N/A'}</span>
        </div>,
        entry: new Date(el.date_of_entry)?.toDateString('Dd MM, YYYY'),
        exit: new Date(el.date_of_exit)?.toDateString('Dd MM, YYYY'),
        infraction: el.audit_committee_meeting,
        actions: 
        <div data-id={el.id} className="hover:underline">
          {/* <a className="text-primary hover:underline" href={el.audit_report_binary} target="_blank" rel="noopener noreferrer" download>View</a> */}
          <span className="text-primary hover:underline" onClick={()=> { 
            // utils.downloadBase64(el.audit_report_binary, el.audit_report_file_name, "application/png")
          }}>View</span>
        </div>
      };
    });

    setdata(tepmArray);

    // if audit.pagination is available
    if (utils.present(audit?.pagination)) {
      setTableMeta({
        ...tableMeta,
        total: pagination?.count,
        totalPages: pagination.totalPages,
        // limit: pagination.limit
      });
    }
  },[audit]);

  useEffect(() => {
    fetchAudit();
  }, []);

  return (
    <div>
      {viewDetails ? (
        <DetailsPage
          setviewDetails={setviewDetails}
        />
      ) : (
        <div>
          <div className="flex flex-row overflow-y-hidden">
            {dataItems.map((item) => (
              <div className="w-1/2 sm:w-auto" style={{ minWidth: '18rem'}}>
                <Widget
                  id={item.id}
                  link={item.link}
                  number={item.number}
                  name={item.name}
                  bck={item.bck}
                  lastItem={dataItems.length}
                  handleClick={item.handleClick}
                />
              </div>
            ))}
          </div>

          <ProfileTable
            title="Audit"
            tableHeading={tableHeading}
            SetProfile={Setinfo}
            list={data}
            hasFilter={false}
            showSort
            hasPagination
            entity="Audit"
            loading={loading}
            meta={tableMeta}  
            fetchList={handleAuditFetch}  
            handleSearch={(e) => fetchAudit(1, 10, e)}        
          >
            <TableDetailsInfo />
          </ProfileTable>
        </div>
      )}
    </div>
  );
};

export default Audit;
