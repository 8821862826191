import React, {useEffect} from "react";

import RoadInfrastructure from "../RoadInfrastructure";
import MarketInfrastructure from "../MarketInfrastructure";
import BasicSchool from "../BasicSchool";
import Health from "../Health";
import Others from "../Others";
// import ICT from "../ICT";
// import Assembly from "../Assembly";
// import ElectricityInfrastructure from "../ElectricityInfrastructure";


//Redux
import { useDispatch } from "react-redux";
import { getInfrastructureById } from "src/redux/infrastructure/action";

const Infrastructure = ({id}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getInfrastructureById(id));
  }, [id]);

  return (
    <div>
      <RoadInfrastructure />
      <BasicSchool />
      <Health />
      <MarketInfrastructure />
      {/* <ICT /> */}
      {/* <Assembly list={[img1, img2, img3, img4, img5, img6]} /> */}
      {/* <ElectricityInfrastructure /> */}
      <Others />
    </div>
  );
};

export default Infrastructure;
