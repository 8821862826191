import * as types from "./types";

const initState = {
  limt: 10,
  page: 1,
  search: "",
};

export const general = (state = initState, action) => {
  switch (action.type) {
    case types.SET_LIMIT:
      return {
        ...state,
        limit: action.payload,
      };

    case types.SET_PAGE:
      return {
        ...state,
        page: action.payload,
      };

    default:
      return state;
  }
};
