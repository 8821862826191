import React, { useState, useEffect } from "react";
import Widget from "../DashbordWidget";
import ProfileTable from "../Tables/ProfileTable";

// Redux
import { getHealthcare } from "../../redux/SocialServices/action";
import { useDispatch, useSelector } from "react-redux";
import utils from "../../utils";

const tableHeading = {
  name: "District",
  healthcare: "Healthcare type",
  birth: "Births",
  death: "Deaths",
  disease: "Most Prevalent Disease Report",
};

const Healthcare = () => {
  const dispatch = useDispatch();
  const { healthcare } = useSelector((state) => state.socialServices);
  let pagination = healthcare.pagination;

  const [tableMeta, setTableMeta] = useState({
    currentPage: 1,
    totalPages: 1,
    total: 0,
    limit: 10,
  });

  const [profile, setprofile] = useState({});
  const [data, setdata] = useState([]);
  const [loading, setloading] = useState(false);

  const SetProfile = (healthcare) => {
    setprofile(healthcare);
  };

  const dataItems = [
    {
      id: 1,
      number: healthcare?.meta?.total_birth ,
      link: "",
      name: "Total Birth",
      bck: "#7A6EFE",
    },
    {
      id: 2,
      number: healthcare?.meta?.total_deaths ,
      link: "",
      name: "Total Deaths",
      bck: "#FF5363",
    },
    {
      id: 3,
      number: healthcare?.meta?.total_ambulance ,
      link: "",
      name: "Ambulance",
      bck: "#FFA901",
    },
    {
      id: 4,
      number: healthcare?.meta?.clinics ,
      link: "",
      name: "Clinics",
      bck: "#24A8FA",
    },
  ];

  const TableDetailsInfo = () => (
    <section className="text-lg">
      <div className="w-full flex items-center justify-start pb-1">
        <div className="w-1/2">District</div>
        <div className="text-gray-1">{profile.name}</div>
      </div>
      <div className="w-full flex items-center justify-start pb-1">
        <div className="w-1/2">Healthcare type</div>
        <div className="text-gray-1">{profile.healthcare}</div>
      </div>
      <div className="w-full flex items-center justify-start pb-1">
        <div className="w-1/2">Births</div>
        <div className="text-gray-1">{profile.birth}</div>
      </div>
      <div className="w-full flex items-center justify-start pb-1">
        <div className="w-1/2">Deaths</div>
        <div className="text-gray-1">{profile.death}</div>
      </div>
      <div className="w-full flex items-center justify-start pb-1">
        <div className="w-1/2">Most Prevalent Disease Report</div>
        <div className="text-gray-1">{profile.disease}</div>
      </div>
    </section>
  );

  const fetchHealthCare = (page, limit, search="") => {
    setloading(true);
    dispatch(getHealthcare(page, limit, search))
    .then(() => {
        setloading(false);
      },() => {
        setloading(false);
      }
    );
  };  

  const handleHealthcareFetch = (type, value) => {
    switch (type) {
      case 'limit':
        setTableMeta((prev) => { return { ...prev, currentPage: 1, limit: value } });
        fetchHealthCare(1, value);
      break;

      case 'currentPage':
        setTableMeta((prev) => { return {  ...prev, currentPage: value }});        
        fetchHealthCare(value, tableMeta.limit);
      break;
    
      default:
        break;
    }
  };   

  useEffect(() => {
    let tepmArray = healthcare?.list?.map((el) => {
      return {
        name: el.district,
        healthcare: el.type_of_healthcare,
        birth: utils.numberWithCommas(el.births),
        death: utils.numberWithCommas(el.deaths),
        disease: el.most_prevalent_disease_report,
      };
    });

    setdata(tepmArray);

    // if revenue.pagination is available
    if (utils.present(healthcare?.pagination)) {
      setTableMeta({
        ...tableMeta,
        total: pagination?.count,
        totalPages: pagination?.totalPages,
        // limit: pagination.limit
      });
    }
  }, [healthcare]);

  useEffect(() => {
    fetchHealthCare()
  }, []);

  return (
    <div>
      <div className="w-full flex flex-row">
        {dataItems.map((item) => (
          <div className="w-full sm:w-1/4">
            <Widget
              id={item.id}
              link={item.link}
              number={item.number}
              name={item.name}
              bck={item.bck}
              lastItem={dataItems.length}
            />
          </div>
        ))}
      </div>

      <ProfileTable
        title="Healthcare"
        tableHeading={tableHeading}
        SetProfile={SetProfile}
        list={data}
        hasFilter
        hasPagination
        entity="List"
        loading={loading}
        meta={tableMeta}
        fetchList={handleHealthcareFetch}
        handleSearch={(e) => fetchHealthCare(1, 10, e)}
      >
        <TableDetailsInfo />
      </ProfileTable>
    </div>
  );
};

export default Healthcare;
