import React, { useState, useEffect } from "react";
import ProfileTable from "../Tables/ProfileTable";

//Redux
import { getFireService } from "src/redux/SocialServices/action";
import { useDispatch, useSelector } from "react-redux";
import utils from "../../utils";

const tableHeading = {
  name: "District",
  station: "No of stations",
  personnel: "no Of Personnel",
  cases: "no. Of Reported cases",
  tenders: "no. Of fire tenders",
  household: "No. of Household Fires",
  bushfires: "No. of BushFires",
  commercial: "Commercial / Industrial Fires",
};

const FireService = () => {
  const dispatch = useDispatch();
  const { security } = useSelector((state) => state.socialServices);
  let { pagination } = security?.fire_service;

  const [tableMeta, setTableMeta] = useState({
    currentPage: 1,
    totalPages: 1,
    total: 0,
    limit: 10,
  });

  const [profile, setprofile] = useState({});
  const [loading, setloading] = useState(false);
  const [data, setdata] = useState([]);

  const SetProfile = (Fire) => {
    const newFireService = security.fire_service?.list?.find(
      (el) => el.id === Fire?.id
    );

    setprofile(newFireService);
  };

  const TableDetailsInfo = () => (
    <section className="text-lg">
      <div className="w-full flex items-center justify-start pb-1">
        <div className="w-1/2">District</div>
        <div className="text-gray-1">{profile.district}</div>
      </div>
      <div className="w-full flex items-center justify-start pb-1">
        <div className="w-1/2">No of stations</div>
        <div className="text-gray-1">{profile.no_of_stations}</div>
      </div>
      <div className="w-full flex items-center justify-start pb-1">
        <div className="w-1/2">No of Personnel</div>
        <div className="text-gray-1">{profile.no_of_personnels}</div>
      </div>
      <div className="w-full flex items-center justify-start pb-1">
        <div className="w-1/2">No. of Reported Cases</div>
        <div className="text-gray-1">{profile.no_of_reported_cases}</div>
      </div>
      <div className="w-full flex items-center justify-start pb-1">
        <div className="w-1/2">No. Of Fire Tenders</div>
        <div className="text-gray-1">{profile.no_of_fire_tenders}</div>
      </div>
      <div className="w-full flex items-center justify-start pb-1">
        <div className="w-1/2">Household Fires</div>
        <div className="text-gray-1">{profile.no_of_household_fires}</div>
      </div>
      <div className="w-full flex items-center justify-start pb-1">
        <div className="w-1/2">Bushfires</div>
        <div className="text-gray-1">{profile.no_of_bushfires}</div>
      </div>
      <div className="w-full flex items-center justify-start pb-1">
        <div className="w-1/2">Commercial / Industrial Fires</div>
        <div className="text-gray-1">{profile.no_of_industrial_fires}</div>
      </div>
    </section>
  );

  const fetchFireService = (page, limit, search = "") => {
    setloading(true);
    dispatch(getFireService(page, limit, search)).then(
      () => {
        setloading(false);
      },
      () => {
        setloading(false);
      }
    );
  };

  const handleFireFetch = (type, value) => {
    switch (type) {
      case 'limit':
        setTableMeta((prev) => { return { ...prev, currentPage: 1, limit: value } });
        fetchFireService(1, value);
      break;

      case 'currentPage':
        setTableMeta((prev) => { return {  ...prev, currentPage: value }});        
        fetchFireService(value, tableMeta.limit);
      break;
    
      default:
        break;
    }
  };

  useEffect(() => {
    let tepmArray = security.fire_service?.list?.map((el) => {
      return {
        id: el.id,
        name: el.district,
        station: el.no_of_stations,
        personnel: el.no_of_personnels,
        cases: el.no_of_reported_cases,
        tenders: el.no_of_fire_tenders,
        household: el.no_of_household_fires,
        bushfires: el.no_of_bushfires,
        commercial: el.no_of_industrial_fires,
      };
    });

    setdata(tepmArray);

    // if revenue.pagination is available
    if (utils.present(security.fire_service?.pagination)) {
      setTableMeta({
        ...tableMeta,
        total: pagination?.count,
        totalPages: pagination.totalPages,
      });
    }
  }, [security]);

  useEffect(() => {
    fetchFireService();
  }, []);

  return (
    <div>
      <ProfileTable
        title="Fire Service"
        showTitle={false}
        tableHeading={tableHeading}
        SetProfile={SetProfile}
        list={data}
        hasPagination
        hasFilter
        entity="List"
        loading={loading}
        meta={tableMeta}
        fetchList={handleFireFetch}
        handleSearch={(e) => fetchFireService(1, 10, e)}
      >
        <TableDetailsInfo />
      </ProfileTable>
    </div>
  );
};

export default FireService;
