import React from "react";
import { Input } from "reactstrap";
import styles from "./Search.module.scss";

const Search = ({ 
    placeholder="",
    parentSearchStyle="",
    className="",
    handleOnSearch=()=>{}
 }) => {
  return (
    <div className={`flex items-center pl-4 pr-2 ${parentSearchStyle} w-full`}>
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.16699 0.666992C12.3091 0.666992 15.667 4.02486 15.667 8.16699C15.667 9.93764 15.0534 11.565 14.0272 12.848L17.0896 15.9111C17.415 16.2365 17.415 16.7641 17.0896 17.0896C16.7892 17.39 16.3165 17.4131 15.9896 17.1589L15.9111 17.0896L12.848 14.0272C11.565 15.0534 9.93764 15.667 8.16699 15.667C4.02486 15.667 0.666992 12.3091 0.666992 8.16699C0.666992 4.02486 4.02486 0.666992 8.16699 0.666992ZM8.16699 2.33366C4.94533 2.33366 2.33366 4.94533 2.33366 8.16699C2.33366 11.3887 4.94533 14.0003 8.16699 14.0003C11.3887 14.0003 14.0003 11.3887 14.0003 8.16699C14.0003 4.94533 11.3887 2.33366 8.16699 2.33366Z" fill="#000D1F" />
        </svg>

        <Input 
            type="search"
            placeholder={placeholder}
            className={`${className} ${styles.input} pl-2 border-none bg-transparent`} 
            onKeyUp={(e) => {               

                if (e.key === "Enter") {
                    handleOnSearch(e.target.value)
                }
            }}
        />
    </div>
  );
};

export default Search;
