import React, { useState  } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane, Row, Col } from "reactstrap";
import styles from "../../tabs/Tabs.module.scss"
import BasicEducation from "./BasicEducation";
import Electricity from "./Electricity";
import Healthcare from "./Healthcare";
import LandSpatial from "./LandSpatial";
import Roads from "./Roads";
import Security from "./Security";
import WaterCoverage from "./WaterCoverage";


const SocialServicesTabItems = () => {
  const [active, setactive] = useState(1);

  const tabList = [
    {
      id:1,
      name: "Basic Education",
      link: "", 
    },
    {
      id:2,
      name: "Healthcare",
      link: "",
    },
    {
      id:3,
      name: "Electricity",
      link: "",
    },
    {
      id:4,
      name: "Water Coverage",
      link: "",
    },
    {
      id:5,
      name: "Land Use and Spatial Planning",
      link: "",
    },
    {
      id:6,
      name: "Security",
      link: "",
    },
    // {
    //   id:7,
    //   name: "Roads",
    //   link: "",
    // }
   
  ];

  return (
    <div >
      <Nav className={`${styles.navparent} flex flex-nowrap mb-4 bg-gray-100 overflow-y-hidden tab`}  tabs>
        {tabList.map((el) => (
          <NavItem>
            <NavLink 
              className={`${styles.link} ${active === el.id ? 'active bg-transparent' : ''} text-sm border-none uppercase`}
              onClick={() => { setactive(el.id) }}
            >
              {el.name}
          </NavLink>
          </NavItem>
        ))}
      </Nav>

      <TabContent activeTab={active}>
        {tabList.map((el) => (
          <TabPane tabId={el.id} >
            {el.id===1 && <BasicEducation/>}
            {el.id===2 && <Healthcare/>}
            {el.id===3 && <Electricity/>} 
            {el.id===4 && <WaterCoverage/>}
            {el.id===5 && <LandSpatial/>}
            {el.id===6 && <Security/>}
            {/* {el.id===7 && <Roads/>} */}
          </TabPane>
        ))}
      </TabContent>
    </div>
  );
};

export default SocialServicesTabItems;
