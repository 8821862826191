import * as types from './types';

const initState = {
    profiles: {
        list: [],
        meta: {},
        pagination: {},        
    },
    districts: {
        list: [],
        meta: {},
        pagination: {}
    },
    districtInfo: {},
    regions: {
        list: [],
        meta: {},
        pagination: {}
    },
    regionInfo: {}
};

export const profile = (state = initState, action) => {
    switch (action.type) {
        case types.SET_PROFILES:
            const {meta, results, count, limit, pages, next, previous} = action.payload

            return {
                ...state,
                profiles: {
                    list: results,
                    meta,
                    pagination: { 
                        count, 
                        limit,
                        totalPages: pages,
                        next, 
                        previous 
                    }
                }
            }
    
        case types.SET_DISTRICTS:
            return {
                ...state,
                districts: {
                    list: action.payload.results,
                    meta: action.payload.meta,
                    pagination: { 
                        count: action.payload.count, 
                        limit: action.payload.limit,
                        totalPages: action.payload.pages,
                        next: action.payload.next, 
                        previous: action.payload.previous
                    }
                }
        }
        case types.SET_REGIONS:
            return {
                ...state,
                regions: {
                    list: action.payload.results,
                    meta: action.payload.meta,
                    pagination: { 
                        count: action.payload.count, 
                        limit: action.payload.limit,
                        totalPages: action.payload.pages,
                        next: action.payload.next, 
                        previous: action.payload.previous
                    }
                }
        }

        case types.SET_DISTRIC_INFO:
            return {
                ...state,
                districtInfo: action.payload
            }
            
        case types.SET_REGION_INFO:
            return {
                ...state,
                regionInfo: action.payload
            }

        default:
            return state;
    }
}
