import React, { useState  } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import styles from "../../tabs/Tabs.module.scss"

//Tabs
import Iteneraries from "./Iteneraries";
import StaffingData from "./StaffingData";
import CapacityBuilding from "./CapacityBuilding";
import Complaints from "./Compliants";

const AdministrativeTabItems = () => {
  const [active, setactive] = useState(1);

  const tabList = [
    {
      id:1,
      name: "Staffing Data",
      link: "/staffing_data",
    },
    {
      id:2,
      name: "Itineraries",
      link: "",
    },
    {
      id:3,
      name: "Capacity Building",
      link: "",
    },
    {
      id:4,
      name: "Complaints",
      link: "",
    },
   
  ];

  return (
    <div>
      <Nav className={`${styles.navparent} flex flex-nowrap mb-4 bg-gray-100 overflow-y-hidden tab`}  tabs>
        {tabList.map((el) => (
          <NavItem>
            <NavLink 
              className={`${styles.link} ${active === el.id ? 'active bg-transparent' : ''} text-sm border-none uppercase`}
              onClick={() => { setactive(el.id) }}
            >
              {el.name}
          </NavLink>
          </NavItem>
        ))}
      </Nav>

      <TabContent activeTab={active}>
        {tabList.map((el) => (
          <TabPane tabId={el.id} >
            {el.id===1 && <StaffingData/>}
            {el.id===2 && <Iteneraries/>}
            {el.id===3 && <CapacityBuilding/>} 
            {el.id===4 && <Complaints/>}             
          </TabPane>
        ))}
      </TabContent>
    </div>
  );
};

export default AdministrativeTabItems;
