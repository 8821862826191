import React, {useState, useEffect} from 'react'
import Layout from "../component/Layout/Layout";
import Widget from '../../src/component/DashbordWidget'
import ProfileTable from "../component/Tables/ProfileTable";
import utils from '../utils'

import { getPoliticalIssues } from '../redux/PoliticalIssues/action';
import { useDispatch, useSelector } from 'react-redux';

const tableHeading = {
  name: "District",
  stakeholders: "MMDCE  interface With Stakeholders",
  quality: "Quality of interface",
  participants: "No. of participants",
  venue: "Venue",
  date: "Date",
};


const PoliticalIssuesPage = () => {
  const dispatch = useDispatch();
  const {politicalIssues} = useSelector(state=>state.politicalIssues)
  let pagination = politicalIssues.pagination;

  const [tableMeta, setTableMeta] = useState({
    currentPage: 1,
    totalPages: 1,
    total: 0,
    limit: 10    
  });  

  const [profile, setprofile] = useState({});
  const [data, setdata] = useState([])
  const [loading, setloading] = useState(false)

  const SetProfile = (political) => {
    const newPolitics = politicalIssues?.list?.find((el)=> el.id === political?.id)
    setprofile(newPolitics);
  };    

  const dataItems = [
    {
      id: 1,
      number: politicalIssues?.meta?.total_participants,
      link: "",
      name: "Total No. of Participants",
      bck: "#7A6EFE"
    },
    {
      id: 2,
      number: politicalIssues?.meta?.highest_participants,
      link: "",
      name: "Highest Participants",
      bck: "#FF5363"
    },
    {
      id: 3,
      number: politicalIssues?.meta?.poor_interface,
      link: "",
      name: "Poor Interface",
      bck: "#FFA901"
    },
    {
      id: 4,
      number: politicalIssues?.meta?.excellent_interface,
      link: "",
      name: "Excellent Interface",
      bck: "#24A8FA"
    },
  ];

  const TableDetailsInfo = () => (
    <section className="text-lg">
    <div className="w-full flex items-center justify-start pb-1">
      <div className="w-1/2">District</div>
      <div className="text-gray-1">{profile.district}</div>
    </div>
    <div className="w-full flex items-center justify-start pb-1">
      <div className="w-1/2">MMDCE  interface With Stakeholders</div>
      <div className="text-gray-1">{profile.stakeholders}</div>
    </div>
    <div className="w-full flex items-center justify-start pb-1">
      <div className="w-1/2">Quality of interface</div>
      <div className="text-gray-1">{profile.political_issues_quality_of_interface}</div>
    </div>
    <div className="w-full flex items-center justify-start pb-1">
      <div className="w-1/2">No. of participants</div>
      <div className="text-gray-1">{utils.numberWithCommas(profile.political_issues_number_of_participants)}</div>
    </div>
    <div className="w-full flex items-center justify-start pb-1">
      <div className="w-1/2">Venue</div>
      <div className="text-gray-1">{profile.political_issues_venue}</div>
    </div>
    <div className="w-full flex items-center justify-start pb-1">
      <div className="w-1/2">Date</div>
      <div className="text-gray-1">{profile.political_issues_dates}</div>
    </div>
    <div className="w-full items-center justify-start pb-1">
      <div className="">Key Issues</div>
      <div className="text-gray-1">{profile.political_issues_key_issues}</div>
    </div>
    <div className="w-full items-center justify-start pb-1">
      <div className="">Recommendation</div>
      <div className="text-gray-1">{profile.political_issues_recommendations}</div>
    </div>
  </section>
  );

  const fetchPoliticalIssue = (page, limit, search="") => {
    setloading(true);
    dispatch(getPoliticalIssues(page, limit, search))
    .then(() => {
      setloading(false);
    }, () => {
      setloading(false);
    })
  };

  const handlePoliticalIssueFetch = (type, value) => {
    switch (type) {
      case 'limit':
        setTableMeta((prev) => { return { ...prev, currentPage: 1, limit: value } });
        fetchPoliticalIssue(1, value);
      break;

      case 'currentPage':
        setTableMeta((prev) => { return {  ...prev, currentPage: value }});        
        fetchPoliticalIssue(value, tableMeta.limit);
      break;
    
      default:
        break;
    }
  };  

  useEffect(() => {
    let tepmArray = politicalIssues?.list?.map((el) => {
      return  {
        id: el.id,
        name: el.district,
        stakeholders: el.stakeholders,
        quality: el.political_issues_quality_of_interface,
        participants: utils.numberWithCommas(el.political_issues_number_of_participants),
        venue: el.political_issues_venue,
        date: new Date(el.political_issues_dates)?.toLocaleDateString()
      };
    });

    setdata(tepmArray);

    // if revenue.pagination is available
    if (utils.present(politicalIssues?.pagination)) {
      setTableMeta({
        ...tableMeta,
        total: pagination?.count,
        totalPages: pagination.totalPages,
        // limit: pagination.limit
      });
    }

  },[politicalIssues]); 

  useEffect(() => {
    fetchPoliticalIssue();
  }, []);

  return (
    <Layout pageTitle="Political Issues">
      <div className="w-full flex flex-row">
        {dataItems.map((item) => (
          <Widget 
            id={item.id} 
            link={item.link} 
            number={item.number} 
            name={item.name} 
            bck={item.bck} 
            lastItem={dataItems.length} 
          />
        ))}
      </div>

      <ProfileTable
        title="Political Issues"
        tableHeading={tableHeading}
        SetProfile={SetProfile}
        list={data}
        hasFilter
        hasPagination
        entity='Political'
        loading={loading}
        meta={tableMeta}
        fetchList={handlePoliticalIssueFetch}
        handleSearch={(e) => fetchPoliticalIssue(1, 10, e)}
      >
        <TableDetailsInfo />
      </ProfileTable>

    </Layout>
  );
}

export default PoliticalIssuesPage;
