import React, { useState, useEffect } from "react";
import utils from "../utils";

import Card from "./Card";
import FancyTab from "./Tabs/FancyTab";
import CompliantModal from "./Profile/ProfileInfo";
import NoData from "./NoData";

import { useSelector } from "react-redux";

function Complaint() {
  const { complaints } = useSelector(state=>state.administrative);
  const [showDetails, setshowDetails] = useState(false);
  const [info, setinfo] = useState({});
  const [data, setdata] = useState([]);

  const handleShowModal = (val) => {
    setinfo(val);
    setshowDetails(true);
  };

  const handleCloseDetails = () => {
    setinfo({});
    setshowDetails(false);
  };

  const AllCompliants = () => (
    <section>
      <div className="flex flex-wrap items-stretch">
        {data.length > 0 ?
          data.map((item) => (
            <Card
              date={item.date}
              label={item.label}
              text={item.text}
              view={item.view}
              item={item}
              setInfo={handleShowModal}
            />
          ))
      : <NoData entity="Complaints" />
      }
      </div>
    </section>
  );

  const Pending = () => (
    <section>
      <div className="flex flex-wrap items-start">
      {data.length > 0 ? 
        data?.filter(el => el.when_complain_was_resolved === null)?.map((item) => (
          <Card
            date={item.date}
            label={item.label}
            text={item.text}
            view={item.view}
            item={item}
            setInfo={handleShowModal}
          />
        ))
      : 
        <NoData entity="Complaints" />
      }
      </div>
    </section>
  );

  const Resolved = () => (
    <section>
      <div className="flex flex-wrap items-start">
        {data.length > 0 ?
          data?.filter(el => el.when_complain_was_resolved !== null)?.map((item) => (
          <Card
            date={item.date}
            label={item.label}
            text={item.text}
            view={item.view}
            item={item}
            setInfo={handleShowModal}
          />
        ))
      :
        <NoData entity="Complaints" />
      }
      </div>
    </section>
  );

  const CompliantDetails = () => (
    <section className="text-lg">
      <div className="w-full pb-2">
        <div className="text-black">District</div>
        <div className="text-gray-1 text-base capitalize">{info.district || info.created_by}</div>
      </div>
      <div className="w-full pb-2">
        <div className="text-black">Major Complaints from Residents</div>
        <div className="text-gray-1 text-base">
          {/* {info.major_complains_from_residents} */}
          <ul className="p-0">
            {info?.major_complains_from_residents?.split(" \r\n")?.map((el, index) => (
              <li key={index}>{el}</li>
            ))}
          </ul>          
        </div>
      </div>  
      <div className="w-full pb-2">
        <div className="text-black">Communities With the Complaints</div>
        <div className="text-gray-1 text-base">
          <ul className="list-disc">  
            {info?.communities_with_the_complains?.split(" \r\n")?.map((el, index) => (
              <li key={index}>{el}</li>
            ))}
          </ul>
        </div>
      </div>  
      <div className="w-full pb-2">
        <div className="text-black">Actions Taken</div>
        <div className="text-gray-1 text-base">{info.actions_taken}</div>
      </div>
      <div className="w-full pb-2">
        <div className="text-black">Beneficiary</div>
        <div className="text-gray-1 text-base">{info.beneficiary || "N/A"}</div>
      </div>
      <div className="w-full pb-2">
        <div className="text-black">When Complaint was received</div>
        <div className="text-gray-1 text-base">{new Date(info.when_complain_was_received).toDateString()}</div>
      </div>
      <div className="w-full pb-2">
        <div className="text-black">When Complaint was resolved</div>
        <div className="text-gray-1 text-base">{info.when_complain_was_resolved ? new Date(info.when_complain_was_resolved).toDateString() : 'N/A'}</div>
      </div>
    </section> 
  );    

  const List = [
    {
      id: 1,
      name: "All Complaints",
      content: <AllCompliants />,
    },
    {
      id: 2,
      name: "Pending",
      content: <Pending />,
    },
    {
      id: 3,
      name: "Resolved",
      content: <Resolved />,
    },
  ];

  useEffect(() => {
    if (utils.present(complaints)) {
      let tepmArray = complaints.map((el) => {
        return  {
          date: `
            ${new Date(el.when_complain_was_received).toLocaleDateString()} - 
            ${new Date(el.when_complain_was_resolved).toLocaleDateString()}
          `,
          label: el.communities_with_the_complains,
          text:  el.major_complains_from_residents,
          view: "View Complaint",
          district: el.district,
          ...el
        };
      });    
  
      setdata(tepmArray);
    }
  }, [complaints]);    

  return (
    <div>
      <div className="bg-white rounded-lg mb-5">
        <div className="px-6 pt-4 pb-3 flex flex-row justify-between">
          <div className="">
            <h1 className=" text-2xl font-semibold mb-0">Complaint</h1>
          </div>

          <div>
            <h1 className="text-xl font-semibold mb-0">Total Complaint: {data.length}</h1>
          </div>
        </div>

        <hr />

        <div className="px-6">
          <FancyTab List={List} />
        </div>

        {showDetails && (
          <CompliantModal
            title=""
            showModal={info}
            handleClose={handleCloseDetails}
            scrollable
          >
            <CompliantDetails />
          </CompliantModal>
        )}
      </div>
    </div>
  );
}

export default Complaint;
