  // eslint-disable-next-line react-hooks/exhaustive-deps
import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import Layout from "../component/Layout/Layout";
import { useNavigate } from "react-router-dom";
import ProfileTable from "../component/Tables/ProfileTable";
import utils from "../utils";

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { getRegionDistricts } from "../redux/profile/action";

const RegionDistricts = () => {
  const dispatch = useDispatch();
  const { slug } = useParams();
  const navigate = useNavigate();

  const { districts } = useSelector(state => state.profile);
  const pagination = districts.pagination;

  const [tableMeta, setTableMeta] = useState({
    currentPage: 1,
    totalPages: 1,
    total: 0,
    limit: 10    
  });
  const [loading, setloading] = useState(false);
  const [tableData, setTableData] = useState([]);
  
  const breadcrumbList = [
    {
      name: "Profile",
      link: "/profile"
    },
    {
      name: "Regions",
      link: "/profile/regions"
    },
    {
      name: `${decodeURIComponent(slug)}`,
      link: `/profile/regions/${decodeURIComponent(slug) || 'region'}`
    },
  ];

  const handleViewAction = (info) => {
    const record = districts?.list.find(el => el.id === info?.name?.props['data-id']);
    navigate(`/profile/districts/${record?.id}`);
  };

  const fetchRegionDistricts = (page, limit, cached=true, search="") => {
    setloading(true);
    dispatch(getRegionDistricts(slug, page, limit, cached, search))
    .then(() => {
      setloading(false);
    }, (err) => {
      setloading(false);
      console.error('Error fetching profiles:', err);
    })
  }; 
  
  const handleDistrictFetch = (type, value) => {
    switch (type) {
      case 'limit':
        setTableMeta((prev) => { return { ...prev, currentPage: 1, limit: value } });
        fetchRegionDistricts(1, value, false);
      break;

      case 'currentPage':
        setTableMeta((prev) => { return {  ...prev, currentPage: value }});        
        fetchRegionDistricts(value, tableMeta.limit, false);
      break;
    
      default:
        break;
    }
  };  

  useEffect(() => {
    if (utils.present(pagination)) {
      setTableMeta({ 
        ...tableMeta, 
        total: pagination.count,
        totalPages: pagination.totalPages,
        // limit: pagination.limit
      });
    }
  }, [pagination]);

  useEffect(() => {
    let tepmArray = districts?.list.map((el) => {
      return  {
        // id: el.id,
        name: <span data-id={el.id}>{el.district}</span>,
        action: "View District"
      };
    });

    // Set re-mapped profile items to table
    setTableData(tepmArray);
  },[districts]);

  useEffect(() => {
    dispatch({ 
      type: "SET_PROFILES", 
      payload: { pages: 1, limit: '10', next: null, previous: null, meta: {}, results: [] }
    });

    fetchRegionDistricts();
  }, []);

  return (
    <Layout pageTitle={`${decodeURIComponent(slug)} Region`} breadcrumb={breadcrumbList}>
      <ProfileTable
        title={` ${decodeURIComponent(slug)} Districts`}
        list={tableData}
        handleViewAction={handleViewAction}
        loading={loading}
        hasFilter
        hasPagination
        entity="Districts"
        meta={tableMeta}        
        showModal={false}
        handleSearch={(e) => fetchRegionDistricts(1, 10, false, e)}
        fetchList={handleDistrictFetch}
      />
    </Layout>
  );
}

export default RegionDistricts;
