import * as types from './types';

const initState = {
    roadInfrastructure: {
        list:[],
        meta:{},
        pagination:{}
    },
    marketInfrastructure: {
        list: [],
        meta: {},
        pagination:{}
    },
    schoolInfrastructure: {
        list:[],
        meta:{},
        pagination:{}
    },
    assemblyInfrastructure: {
        list:[],
        meta:{},
        pagination:{}
    },
    healthInfrastructure: {
        list: [],
        meta: {},
        pagination:{}
    },
    other: {
        list: [],
        meta: {},
        pagination:{}
    },
    officeInfrastructure: {
        list: [],
        meta: {},
        pagination:{}
    },
};

export const infrastructure = (state = initState, action) => {
    switch (action.type) {
        case types.ROAD_INFRASTRUCTURE:
            return {
                ...state,
                roadInfrastructure: {
                    list: action.payload.results, 
                    meta: action.payload.meta,
                    pagination: { 
                        count: action.payload.count, 
                        totalPages: action.payload.pages,
                        limit: action.payload.limit,                        
                        next: action.payload.next, 
                        previous: action.payload.previous 
                    }
                }
            }

        case types.MARKET_INFRASTRUCTURE:
            return {
                ...state,
                marketInfrastructure: {
                    list: action.payload.results, 
                    meta: action.payload.meta,
                    pagination: { 
                        count: action.payload.count,
                        totalPages: action.payload.pages,
                        limit: action.payload.limit,
                        next: action.payload.next,
                        previous: action.payload.previous 
                    }
                }
            }

        case types.BASIC_SCHOOLS:
            return {
                ...state,
                schoolInfrastructure: {
                    list: action.payload.results, 
                    meta: action.payload.meta,
                    pagination: { 
                        count: action.payload.count,
                        totalPages: action.payload.pages,
                        limit: action.payload.limit,
                        next: action.payload.next,
                        previous: action.payload.previous 
                    }
                }
            }

        case types.HEALTH_FACILITY:
            return {
                ...state,
                healthInfrastructure: {
                    list: action.payload.results, 
                    meta: action.payload.meta,
                    pagination: { 
                        count: action.payload.count,
                        totalPages: action.payload.pages,
                        limit: action.payload.limit,
                        next: action.payload.next,
                        previous: action.payload.previous 
                    }
                }
            }

        case types.OTHER_INFRUSTRUCTURE:
            return {
                ...state,
                other: {
                    list: action.payload.results, 
                    meta: action.payload.meta,
                    pagination: { 
                        count: action.payload.count,
                        totalPages: action.payload.pages,
                        limit: action.payload.limit,
                        next: action.payload.next,
                        previous: action.payload.previous 
                    }
                }
            }

            case types.SET_INFRASTRUCTURE:
                const payload = action.payload;
              
                return {
                    ...state,
                    roadInfrastructure: {
                        ...state.roadInfrastructure,
                        list: payload.results?.roads,
                    },
                    marketInfrastructure: { 
                        ...state.marketInfrastructure,
                        list: payload.results?.markets,
                    },
                    schoolInfrastructure: { 
                        ...state.schoolInfrastructure,
                        list: payload.results?.schools,
                    },
                    assemblyInfrastructure: { 
                        ...state.assemblyInfrastructure,
                        list: payload.results?.assemblies,
                    },
                    healthInfrastructure: { 
                        ...state.healthInfrastructure,
                        list: payload.results?.healths,
                    },
                    officeInfrastructure: { 
                        ...state.officeInfrastructure,
                        list: payload.results?.others,
                    },
                };    

        default:
            return state;
    }
}
