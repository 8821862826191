import api from "../../services/config";
import * as types from './types';

export const getBasicEducation = (page=1, limit=10, search="") => async (dispatch) => {
    return new Promise((resolve, reject) => {
        let url = `/basic_educations/`;
        if (page) url += `?page=${page}`;
        if (limit) url += `&limit=${limit}`;
        if (search) url += `&search=${search}`;

        return api.get(url)
        .then((response) => {
            console.log('Response', response.data);

            dispatch({
                type: types.BASIC_EDUCATION,
                payload: response.data
            })

            resolve(response);
        })
        .catch((error) => {
            reject(error);
        })
    });
};

export const getHealthcare = (page=1, limit=10, search="") => async (dispatch) => {
    return new Promise((resolve, reject) => {
        let url = `/healthcares/`;
        if (page) url += `?page=${page}`;
        if (limit) url += `&limit=${limit}`;
        if (search) url += `&search=${search}`;

        return api.get(url)
        .then((response) => {
            console.log('Response', response.data);

            dispatch({
                type: types.HEALTHCARE,
                payload: response.data
            })

            resolve(response);
        })
        .catch((error) => {
            reject(error);
        })
    });
};

export const getElectricity = (page=1, limit=10, search="") => async (dispatch) => {
    return new Promise((resolve, reject) => {
        let url = `/electricity/`;
        if (page) url += `?page=${page}`;
        if (limit) url += `&limit=${limit}`;
        if (search) url += `&search=${search}`;

        return api.get(url)
        .then((response) => {
            console.log('Response', response.data);

            dispatch({
                type: types.ELECTRICITY,
                payload: response.data
            })

            resolve(response);
        })
        .catch((error) => {
            reject(error);
        })
    });
};

export const getWaterCoverage = (page=1, limit=10, search="") => async (dispatch) => {
    return new Promise((resolve, reject) => {
        let url = `/watercoverages/`;
        if (page) url += `?page=${page}`;
        if (limit) url += `&limit=${limit}`;
        if (search) url += `&search=${search}`;

        return api.get(url)
        .then((response) => {
            console.log('Response', response.data);

            dispatch({
                type: types.WATER_COVERAGE,
                payload: response.data
            })

            resolve(response);
        })
        .catch((error) => {
            reject(error);
        })
    });
};

export const getLandUseAndSpatialPlanning = (page=1, limit=10, search="") => async (dispatch) => {
    return new Promise((resolve, reject) => {
        let url = `/landuse/`;
        if (page) url += `?page=${page}`;
        if (limit) url += `&limit=${limit}`;
        if (search) url += `&search=${search}`;

        return api.get(url)
        .then((response) => {
            console.log('Response', response.data);

            dispatch({
                type: types.LAND_USE_AND_SPATIAL_PLANNING,
                payload: response.data
            })

            resolve(response);
        })
        .catch((error) => {
            reject(error);
        })
    });
};

export const getSecurity = (page=1, limit=10, search="") => async (dispatch) => {
    return new Promise((resolve, reject) => {
        let url = `/other_security/`;
        if (page) url += `?page=${page}`;
        if (limit) url += `&limit=${limit}`;
        if (search) url += `&search=${search}`;

        return api.get(url)
        .then((response) => {
            console.log('Security Response', response.data);

            dispatch({
                type: types.SECURITY,
                payload: response.data
            })

            resolve(response);
        })
        .catch((error) => {
            reject(error);
        })
    });
};

export const getPolice = (page=1, limit=10, search="") => async (dispatch) => {
    return new Promise((resolve, reject) => {
        let url = `/polices/`;
        if (page) url += `?page=${page}`;
        if (limit) url += `&limit=${limit}`;
        if (search) url += `&search=${search}`;

        return api.get(url)
        .then((response) => {
            console.log('Security Response', response.data);

            dispatch({
                type: types.POLICE,
                payload: response.data
            })

            resolve(response);
        })
        .catch((error) => {
            reject(error);
        })
    });
};

export const getFireService = (page=1, limit=10, search="") => async (dispatch) => {
    return new Promise((resolve, reject) => {
        let url = `/fire_services/`;
        if (page) url += `?page=${page}`;
        if (limit) url += `&limit=${limit}`;
        if (search) url += `&search=${search}`;

        return api.get(url)
        .then((response) => {
            console.log('Security Response', response.data);

            dispatch({
                type: types.FIRE_SERVICE,
                payload: response.data
            })

            resolve(response);
        })
        .catch((error) => {
            reject(error);
        })
    });
};

export const getOtherSecurity = (page=1, limit=10, search="") => async (dispatch) => {
    return new Promise((resolve, reject) => {
        let url = `/other_security/`;
        if (page) url += `?page=${page}`;
        if (limit) url += `&limit=${limit}`;
        if (search) url += `&search=${search}`;

        return api.get(url)
        .then((response) => {
            console.log('Security Response', response.data);

            dispatch({
                type: types.OTHER_SECURITY,
                payload: response.data
            })

            resolve(response);
        })
        .catch((error) => {
            reject(error);
        })
    });
};


export const getRoads = (page=1, limit=10, search="") => async (dispatch) => {
    return new Promise((resolve, reject) => {
        let url = `/roads/`;
        if (page) url += `?page=${page}`;
        if (limit) url += `&limit=${limit}`;
        if (search) url += `&search=${search}`;

        return api.get(url)
        .then((response) => {
            console.log('Response', response.data);

            dispatch({
                type: types.ROADS,
                payload: response.data
            })

            resolve(response);
        })
        .catch((error) => {
            reject(error);
        })
    });
};

export const getSocialServiceById = (id) => async (dispatch) => {
    return new Promise((resolve, reject) => {

        return api.get(`/all_profiles/districts/${id}/social_service/`)
        .then((response) => {
            dispatch({
                type: types.SET_SOCIAL_SERVICE,
                payload: response.data
            })
            resolve(response);
        })
        .catch((error) => {
            reject(error);
        })
    });
};
