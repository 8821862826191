  // eslint-disable-next-line react-hooks/exhaustive-deps
import React, { useState, useEffect } from 'react';
import Layout from "../component/Layout/Layout";
import { useNavigate } from "react-router-dom";
import ProfileTable from "../component/Tables/ProfileTable";
import utils from "../utils";

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { getDistrictsSubmitted, setDistrictInfo } from "../redux/profile/action";

const Districts = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { districts } = useSelector(state => state.profile);
  const pagination = districts.pagination;

  const [tableMeta, setTableMeta] = useState({
    currentPage: 1,
    totalPages: 1,
    total: 0,
    limit: 10    
  });
  const [loading, setloading] = useState(false);
  const [tableData, setTableData] = useState([]);
  
  const breadcrumbList = [
    {
      name: "Profile",
      link: "/profile"
    },
    {
      name: "Districts Submitted"
    },
  ];

  const handleViewAction = (info) => {
    const record = districts?.list.find(el => el.id === info?.name?.props['data-id']);
    // Set to redux store
    dispatch(setDistrictInfo(record))
    .then(() => {
      navigate(`/profile/districts/${record?.id}`);
    }, (err) => {
      console.error('Error:', err);
    })
  };  

  const fetchDistricts = (page, limit, cached=true, search="") => {
    setloading(true);
    dispatch(getDistrictsSubmitted(page, limit, cached, search))
    .then(() => {
      setloading(false);
    }, (err) => {
      setloading(false);
      console.error('Error fetching profiles:', err);
    })
  }; 
  
  const handleDistrictFetch = (type, value) => {
    switch (type) {
      case 'limit':
        setTableMeta((prev) => { return { ...prev, currentPage: 1, limit: value } });
        fetchDistricts(1, value, false);
      break;

      case 'currentPage':
        setTableMeta((prev) => { return {  ...prev, currentPage: value }});        
        fetchDistricts(value, tableMeta.limit, false);
      break;
    
      default:
        break;
    }
  };  

  useEffect(() => {
    if (utils.present(pagination)) {
      setTableMeta({ 
        ...tableMeta, 
        total: pagination.count,
        totalPages: pagination.totalPages,
        // limit: pagination.limit
      });
    }
  }, [pagination]);

  useEffect(() => {
    let tepmArray = districts?.list.map((el) => {
      return  {
        // id: el.id,
        name: <span data-id={el.id}>{el.district}</span>,
        action: "View District"
      };
    });

    // Set re-mapped profile items to table
    setTableData(tepmArray);
  },[districts]);

  useEffect(() => {
    dispatch({ 
      type: "SET_PROFILES", 
      payload: { pages: 1, limit: '10', next: null, previous: null, meta: {}, results: [] }
    });

    fetchDistricts();
  }, []);

  return (
    <Layout pageTitle="Districts Submitted" breadcrumb={breadcrumbList}>
      <ProfileTable
        title="District List"
        list={tableData}
        handleViewAction={handleViewAction}
        loading={loading}
        hasFilter
        hasPagination
        entity="Districts"
        meta={tableMeta}        
        showModal={false}
        handleSearch={(e) => fetchDistricts(1, 10, false, e)}
        fetchList={handleDistrictFetch}
      />
    </Layout>
  );
}

export default Districts;
