import React, { useState, useEffect, useCallback } from "react";
import ProfileTable from "../../component/Tables/ProfileTable";
import ProjectPopUp from "./ProjectPopUp";
import DetailsModal from "../DetailsModal";
import Widget from "../../component/DashbordWidget";
import utils from '../../utils'

import { getMarketInfrastructure } from "../../redux/infrastructure/action";
import { useDispatch, useSelector } from "react-redux";

const tableHeading =  {
  name: "District",
  market_type: "Type",
  project_name: "Project Name",
  location: "Location Of Project",
  project_commence: "Project Commencement",
  expected_completion: "Project Completion",
  construction_status: "Construction Status",
  completion_status: "Completion Status",
  contract_sum: "Project Cost"
};

const handleViewAction = (val) => {
  // Find district info from val passed
};

const MarketInfrastructure = () => {
  const dispatch = useDispatch();
  const { marketInfrastructure } = useSelector((state) => state.infrastructure);
  let { pagination } = marketInfrastructure;

  const [tableMeta, setTableMeta] = useState({
    currentPage: 1,
    totalPages: 1,
    total: 0,
    limit: 10    
  });  

  const [list, setlist] = useState([]);
  const [current, setcurrent] = useState(1);
  const [showModal, setshowModal] = useState(false);   
  const [profile, setprofile] = useState({});
  const [data, setdata] = useState([])
  const [loading, setloading] = useState(false)

  const SetProfile = (market) => {
    const newRoad = marketInfrastructure?.list?.find((el)=> el.id === market?.id)
    setlist([newRoad.picture_file_url]);
    setprofile(newRoad);
  };

  const handleShowImag = useCallback((val) => {
    setcurrent(val);
    setshowModal(true);
  }, []);    

  const dataItems = [
    {
      id: 1,
      number: marketInfrastructure?.meta?.Completed,
      link: "",
      name: "Completed Markets",
      bck: "#7A6EFE",
    },
    {
      id: 2,
      number: marketInfrastructure?.meta?.Ongoing,
      link: "",
      name: "Ongoing Markets",
      bck: "#FF5363",
    },
    {
      id: 3,
      number: marketInfrastructure?.meta?.Stalled,
      link: "",
      name: "Stalled Markets",
      bck: "#FFA901",
    },
    {
      id: 4,
      number: marketInfrastructure?.meta?.Highest_Cost_Project,
      link: "",
      name: "Highest Cost Of Project",
      bck: "#24A8FA",
    },
  ];
  
  const TableDetailsInfo = () => (
    <section className="text-lg">
      <div className="w-full flex items-center justify-start pb-1">
        <div className="w-1/2">District</div>
        <div className="text-gray-1">{profile.district}</div>
      </div>
      <div className="w-full flex items-center justify-start pb-1">
        <div className="w-1/2">Road Type</div>
        <div className="text-gray-1">{profile.market_type}</div>
      </div>
      <div className="w-full flex items-center justify-start pb-1">
        <div className="w-1/2">Ongoing Road Projects</div>
        <div className="text-gray-1">{profile.construction_status}</div>
      </div>
      <div className="w-full flex items-center justify-start pb-1">
        <div className="w-1/2">Status Of completion</div>
        <div className="text-gray-1">{profile.completion_status}</div>
      </div>
      <div className="w-full flex items-center justify-start pb-1">
        <div className="w-1/2">Expected Completion</div>
        <div className="text-gray-1">{profile.expected_completion}</div>
      </div>
      <div className="w-full flex items-center justify-start pb-1">
        <div className="w-1/2">Cost Of Project</div>
        <div className="text-gray-1">{profile.contract_sum}</div>
      </div>
      <div className="w-full flex items-center justify-start pb-1">
        <div className="w-1/2">Sources Of Funding</div>
        <div className="text-gray-1">{profile.source_of_funding}</div>
      </div>
      <div className="w-full pb-3">
        <div className="w-full text-end">
          <ProjectPopUp list={list} handleShowImag={handleShowImag} />
        </div>
      </div>       
    </section>
  );

  const fetchMarketInfra = (page, limit, search="") => {
    setloading(true);
    dispatch(getMarketInfrastructure(page, limit, search))
    .then(() => {
      setloading(false);
    }, () => {
      setloading(false);
    })
  };

  const handleStaffingFetch = (type, value) => {
    switch (type) {
      case 'limit':
        setTableMeta((prev) => { return { ...prev, currentPage: 1, limit: value } });
        fetchMarketInfra(1, value);
      break;

      case 'currentPage':
        setTableMeta((prev) => { return {  ...prev, currentPage: value }});        
        fetchMarketInfra(value, tableMeta.limit);
      break;
    
      default:
        break;
    }
  };  

  useEffect(() => {
    let tepmArray = marketInfrastructure?.list?.map((el) => {
      return  {
        id: el.id,
        name: el?.district,
        market_type: el?.market_type,
        project_name: el?.project_name,
        location: el?.location,
        project_commence: new Date(el?.project_commence)?.toLocaleDateString(),
        expected_completion: new Date(el?.expected_completion)?.toLocaleDateString(),
        construction_status: <div className="text-center text-white bg-green-600 rounded-full py-1 px-2">{el.construction_status || "N/A"}</div>,
        completion_status: <div className="text-center">{`${el.completion_status}%`}</div>,        
        contract_sum: utils.numberWithCommas(el?.contract_sum)
      };
    });

    setdata(tepmArray);

    // if revenue.pagination is available
    if (utils.present(pagination)) {
      setTableMeta({
        ...tableMeta,
        total: pagination?.count,
        totalPages: pagination.totalPages,
        // limit: pagination.limit
      });
    }

  },[marketInfrastructure]);

  useEffect(() => {
    fetchMarketInfra();
  }, []);

  return (
    <div>
      <div className="w-full flex flex-row">
        {dataItems.map((item) => (
          <Widget
            id={item.id}
            link={item.link}
            number={item.number}
            name={item.name}
            bck={item.bck}
            lastItem={dataItems.length}
          />
        ))}
      </div>

      <ProfileTable
        title="Market"
        tableHeading={tableHeading}
        list={data}
        handleViewAction={handleViewAction}
        SetProfile={SetProfile}
        hasFilter
        loading={loading}
        meta={tableMeta}
        hasPagination
        fetchList={handleStaffingFetch}
        handleSearch={(e) => fetchMarketInfra(1, 10, e)}
      >
        <TableDetailsInfo />
      </ProfileTable>

      {showModal && (
        <DetailsModal
          current={current}
          showModal={showModal}
          handleClose={() => setshowModal(false)}
          items={list.map((el, index) => {
            return { key: index + 1, src: el };
          })}
        />
      )}      
    </div>
  );
};

export default MarketInfrastructure;