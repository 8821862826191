  // eslint-disable-next-line react-hooks/exhaustive-deps
import React, { useState, useEffect } from 'react';
import Layout from "../component/Layout/Layout";
import { useNavigate } from "react-router-dom";
import ProfileTable from "../component/Tables/ProfileTable";

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { getRegions } from "../redux/profile/action";

const Regions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { regions } = useSelector(state => state.profile);

  const [loading, setloading] = useState(false);
  const [tableData, setTableData] = useState([]);
  
  const breadcrumbList = [
    {
      name: "Profiles",
      link: "/profile"
    },
    {
      name: "Regions"
    },
  ];

  const handleViewAction = (info) => {  
    navigate(`/profile/region/${info.id}`);
  };  

  const fetchRegions = (page, limit, cached=true) => {
    setloading(true);
    dispatch(getRegions(page, limit, cached))
    .then(() => {
      setloading(false);
    }, (err) => {
      setloading(false);
      console.error('Error fetching profiles:', err);
    })
  }; 

  useEffect(() => {
    let tepmArray = regions?.list.map((el, index) => {
      return  {
        id: el,
        name: <span data-id={index+1}>{el}</span>
      };
    });

    // Set re-mapped profile items to table
    setTableData(tepmArray);
  },[regions]);

  useEffect(() => {
    dispatch({ 
      type: "SET_PROFILES", 
      payload: { pages: 1, limit: '10', next: null, previous: null, meta: {}, results: [] }
    });

    fetchRegions();
  }, []);

  return (
    <Layout pageTitle="Regions" breadcrumb={breadcrumbList}>
      <ProfileTable
        title="Regions"
        list={tableData}
        handleViewAction={handleViewAction}
        loading={loading}
        hasFilter={false}
        entity="Regions"
        showModal={false}
      />
    </Layout>
  );
}

export default Regions;
