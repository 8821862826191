import React, {useState, useEffect} from 'react'
import ProfileTable from './Tables/ProfileTable';
import utils from '../utils'
import { useSelector } from 'react-redux';

const tableHeading = {
  population: "Population with access to Portable Water",
  service: "service provider",
  source: "water source",
  no_of_communities_without_portable: "Number of Communities  Without Portable Water",
  communities: "How Many Communities Without Portable Water",
};


const WaterCoverage = () => {
  const { waterCoverage } = useSelector((state) => state.socialServices);

  const [profile, setprofile] = useState({});
  const [data, setdata] = useState([])

  const SetProfile = (profile) => {
    setprofile(profile);
  };


  const TableDetailsInfo = () => (
    <section className="text-lg">
    <div className="w-full pb-3">
      <div className="">Population with access to Portable Water</div>
      <div className=" text-gray-1">{profile.population}</div>
    </div>
    <div className="w-full pb-3">
      <div className="">Service provider</div>
      <div className=" text-gray-1">{profile.service || "N/A"}</div>
    </div>
    <div className="w-full pb-3">
      <div className="">Water source</div>
      <div className=" text-gray-1">{profile.source || "N/A"}</div>
    </div>
    <div className="w-full pb-3">
      <div className="">How Many Communities Without Portable Water</div>
      <div className=" text-gray-1">{profile.communities}</div>
    </div>
   
  </section>
  );
  
  useEffect(() => {
    let tepmArray = waterCoverage?.list?.map((el) => {
      return  {
        population: utils.numberWithCommas(el.population_with_access_to_portable_water),
        service: el.service_provider,
        source: el.water_source,
        no_of_communities_without_portable: el?.communities_without_portable_water,
        communities: el.list_communities_without_portable_water,
      };
    });
    setdata(tepmArray);

  },[waterCoverage]); 



  return (
    <div>
        <ProfileTable
        title='Water Coverage'
        tableHeading={tableHeading}
        showDivider
        list={data}
        SetProfile={SetProfile}
      >
        <TableDetailsInfo/>
      </ProfileTable>
    </div>
  )
}

export default WaterCoverage