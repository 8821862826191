import api from "../../services/config";
import * as types from './types';

export const getParksAndGardens = (page=1, limit=10, search="") => async (dispatch) => {
    return new Promise((resolve, reject) => {
        let url = `/parks_n_gradens/`;
        if (page) url += `?page=${page}`
        if (limit) url += `&limit=${limit}`
        if (search) url += `&search=${search}`
        

        return api.get(url)
        .then((response) => {
            console.log('Response', response.data);

            dispatch({
                type: types.PARKS_AND_GARDENS,
                payload: response.data
            })

            resolve(response);
        })
        .catch((error) => {
            reject(error);
        })
    });
};

export const getGreeningProjects = (page=1, limit=10) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        let url = `/greenprojects/`;
        if (page) url += `?page=${page}`;
        if (limit) url += `&limit=${limit}`;

        return api.get(url)
        .then((response) => {
            console.log('Response', response.data);

            dispatch({
                type: types.GREENING_PROJECT,
                payload: response.data
            })

            resolve(response);
        })
        .catch((error) => {
            reject(error);
        })
    });
};

export const getPollution = (page=1, limit=10, search="") => async (dispatch) => {
    return new Promise((resolve, reject) => {
        let url = `/pollutions/`;
        if (page) url += `?page=${page}`;
        if (limit) url += `&limit=${limit}`;
        if (search) url += `&search=${search}`

        return api.get(url)
        .then((response) => {
            console.log('Response', response.data);

            dispatch({
                type: types.POLLUTION,
                payload: response.data
            })

            resolve(response);
        })
        .catch((error) => {
            reject(error);
        })
    });
};

export const getSolidWaste = (page=1, limit=10) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        let url = `/solid_waste/`;
        if (page) url += `?page=${page}`;
        if (limit) url += `&limit=${limit}`;

        return api.get(url)
        .then((response) => {
            console.log('Response', response.data);

            dispatch({
                type: types.SOLIDWASTE_PROJECT,
                payload: response.data
            })

            resolve(response);
        })
        .catch((error) => {
            reject(error);
        })
    });
};

export const getLiquidWaste = (page=1, limit=10) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        let url = `/liquid_waste/`;
        if (page) url += `?page=${page}`;
        if (limit) url += `&limit=${limit}`;

        return api.get(url)
        .then((response) => {
            console.log('Response', response.data);

            dispatch({
                type: types.LIQUIDWASTE_PROJECT,
                payload: response.data
            })

            resolve(response);
        })
        .catch((error) => {
            reject(error);
        })
    });
};

export const getEnvironmentById = (id) => async (dispatch) => {
    return new Promise((resolve, reject) => {

        return api.get(`/all_profiles/districts/${id}/environmental/`)
        .then((response) => {
            dispatch({
                type: types.SET_ENVIRONMENT_AND_SANITATION,
                payload: response.data
            })
            resolve(response);
        })
        .catch((error) => {
            reject(error);
        }) 
    });
};


