import React, { useState  } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import styles from "./Tabs.module.scss"

//Tabs
import Profile from "../component/ProfileTabs/Profile";
import DeliberativeFxn from "../component/ProfileTabs/Deliberative";
import AdministrativeTab from "../component/ProfileTabs/AdministrativeTab";
import EconomicDataTab from "../component/ProfileTabs/EconomicDataTab";
import Fiscal from "../component/ProfileTabs/Fiscal";
import SocialServices from "../component/ProfileTabs/SocialServices";
import Environment from "../component/ProfileTabs/Environment";
import PoliticalIssues from "../component/ProfileTabs/PoliticalIssues";
import SpecialInitiatives from "../component/ProfileTabs/SpecialInitiatives";
import Infrastructure from "../component/ProfileTabs/Infrastructure";
import OtherIssuesOfInterest from "../component/ProfileTabs/OtherIssuesOfInterest";

const TabsComponent = ({ id }) => {
  const [active, setactive] = useState(1);

  const tabList = [
    {
      id:1,
      name: "Profile",
      link: "/profile",
    },
    {
      id:2,
      name: "Deliberative Function",
      link: "/deliberative_function",
    },
    {
      id:3,
      name: "Administrative",
      link: "/administrative_tab",
    },
    {
      id:4,
      name: "Economic Data",
      link: "/economic_data_tab",
    },
    {
      id:5,
      name: "Fiscal",
      link: "/fiscal",
    },
    {
      id:6,
      name: "Social Services",
      link: "/social_services",
    },
    {
      id:7,
      name: "Environment and Sanitation",
      link: "/environment",
    },
    {
      id:8,
      name: "Political Issues",
      link: "/political_issues",
    },
    {
      id:9,
      name: "Special Initiatives",
      link: "/special_initiatives",
    },
    {
      id:10,
      name: "Infrastructure",
      link: "/infrastructure",
    },
    {
      id:11,
      name: "Other Issues Of Interest",
      link: "/other_issues_of_interest",
    },
  ];

  return (
    <div>
      <Nav className={`${styles.navparent} flex flex-nowrap mb-4 bg-gray-100 overflow-y-hidden tab`} justified tabs>
        {tabList.map((el) => (
          <NavItem>
            <NavLink 
              className={`${styles.link} ${active === el.id ? 'active bg-transparent' : ''} text-sm border-none uppercase`}
              onClick={() => { setactive(el.id) }}
            >
              {el.name}
          </NavLink>
          </NavItem>
        ))}
      </Nav>

      <TabContent activeTab={active}>
        {tabList.map((el) => (
          <TabPane tabId={el.id} >
            {el.id===1 && <Profile id={id} />}
            {el.id===2 && <DeliberativeFxn id={id} />}
            {el.id===3 && <AdministrativeTab id={id} />} 
            {el.id===4 && <EconomicDataTab id={id} />}
            {el.id===5 && <Fiscal id={id} />}
            {el.id===6 && <SocialServices id={id} />}
            {el.id===7 && <Environment id={id}/>}
            {el.id===8 && <PoliticalIssues id={id}/>}
            {el.id===9 && <SpecialInitiatives id={id}/>}
            {el.id===10 && <Infrastructure id={id}/>}
            {el.id===11 && <OtherIssuesOfInterest id={id}/>}
          </TabPane>
        ))}
      </TabContent>
    </div>
  );
};

export default TabsComponent;
