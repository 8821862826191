import * as types from "./types";

const initState = {
  parksAndGardens: {
    list: [],
    meta: {},
    pagination: {}
  },
  greeningProjects: {
    list: [],
    meta: {},
    pagination: {}
  },
  pollution: {
    list: [],
    meta: {},
    pagination: {}
  },
  solidWaste: {
    list: [],
    meta: {},
    pagination: {}
  },
  liquidWaste: {
    list: [],
    meta: {},
    pagination: {}
  },
  meta:{}
};

export const environmentAndSanitation = (state = initState, action) => {
  switch (action.type) {
    case types.PARKS_AND_GARDENS:
      return {
        ...state,
        parksAndGardens: {
          list: action.payload.results, 
          meta: action.payload.meta,
          pagination: { 
            count: action.payload.count, 
            limit: action.payload.limit, 
            totalPages: action.payload.pages, 
            next: action.payload.next, 
            previous: action.payload.previous 
          }
        }
      };

    case types.GREENING_PROJECT:
      return {
        ...state,
        greeningProjects: {
          list: action.payload.results, 
          meta: action.payload.meta,
          pagination: { 
            count: action.payload.count, 
            next: action.payload.next, 
            previous: action.payload.previous 
          }
        }
      };
    case types.SOLIDWASTE_PROJECT:
      return {
        ...state,
        solidWaste: {
          list: action.payload.results, 
          meta: action.payload.meta,
          pagination: { 
            count: action.payload.count, 
            next: action.payload.next, 
            previous: action.payload.previous 
          }
        }
      };
    case types.LIQUIDWASTE_PROJECT:
      return {
        ...state,
        liquidWaste: {
          list: action.payload.results, 
          meta: action.payload.meta,
          pagination: { 
            count: action.payload.count, 
            next: action.payload.next, 
            previous: action.payload.previous 
          }
        }
      };

    case types.POLLUTION:
      return {
        ...state,
        pollution: {
          list: action.payload.results, 
          meta: action.payload.meta,
          pagination: { 
            count: action.payload.count, 
            limit: action.payload.limit, 
            totalPages: action.payload.pages,            
            next: action.payload.next, 
            previous: action.payload.previous 
          }
        }
      };

      case types.SET_ENVIRONMENT_AND_SANITATION:
      const payload = action.payload;

      return {
        ...state,
        meta:payload.meta,
        parksAndGardens: {
          ...state.parksAndGardens,
          list: payload.results[0]?.parks
        },
        pollution: { 
          ...state.pollution,
          list: payload.results[0]?.pollution
        },
        solidWaste: { 
          ...state.solidWaste,
          list: payload.results[0]?.solid_waste
        },
        liquidWaste: { 
          ...state.liquidWaste,
          list: payload.results[0]?.liquid_waste
        },
      };

    default:
      return state;
  }
};
