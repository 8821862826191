import React, { useState, useEffect } from "react";
import  BuildingImplemented  from "../CapacityBuilding/BuildingImplemented";
import  BuildingPlans  from "../CapacityBuilding/BuildingPlans";
import utils from "../../utils";

// Components
import Card from "../Card";
import FancyTab from "../Tabs/FancyTab";
import TableFilter from "../Tables/TableFilter";
import CapacityModal from "../Profile/ProfileInfo";
import { getCapacityPlans, getCapacityImplemented } from "../../redux/administrative/action";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../Loading";

const CapacityBuilding = ({id}) => {
  const dispatch = useDispatch();
  const { capacityBuilding } = useSelector(state => state.administrative);
  
  const [showDetails, setshowDetails] = useState(false);
  const [info, setinfo] = useState({});
  const [plans, setplans] = useState([]);
  const [implemented, setimplemented] = useState([]);
  const [loading, setloading] = useState([]);
  
  const handleShowModal = (val) => {
    setinfo(val);
    setshowDetails(true);
  };

  const handleCloseDetails = () => {
    setinfo({});
    setshowDetails(false);
  };  

  useEffect(() => {
    if (utils.present(capacityBuilding)) {
      let tempPlans = capacityBuilding?.plans?.map((el) => {
        return  {
          date: `${new Date(el.start_date)?.toLocaleDateString()} - ${new Date(el.end_date)?.toLocaleDateString()}`,
          label: el.type_of_capacity_building,
          text:  el.description,
          view: 'View More',
          ...el
        };
      });    
      setplans(tempPlans);

      let tempImpl = capacityBuilding?.implemented?.map((el) => {
        return  {
          date: `${new Date(el.start_date_im)?.toLocaleDateString()} - ${new Date(el.end_date_im)?.toLocaleDateString()}`,
          label: el.type_of_capacity_building_im,
          text:  el.description_im,
          view: 'View More',
          ...el
        };
      });    
      setimplemented(tempImpl);
    }
  }, [capacityBuilding]);  


  const fetchCapacityPlans = (search="") => {
    setloading(true)
    dispatch(getCapacityPlans(search)).then(()=>{
      setloading(false) 
    })
  }

  const fetchCapacityImplemented = (search="") => {
    setloading(true)
    dispatch(getCapacityImplemented(search)).then(()=>{
      setloading(false) 
    })
  }

  useEffect(() => {
    fetchCapacityPlans()
    fetchCapacityImplemented()
  }, []);



  const CapacityDetails = () => (
    <section className="text-lg">
      <div className="w-full flex items-center justify-start pb-2">
        <div className="w-1/2">Type of Capacity Building</div>
        <div className="w-1/2 text-gray-1">{info.district}</div>
      </div>
      <div className="w-full flex items-center justify-start pb-2">
        <div className="w-1/2">Start Date</div>
        <div className="w-1/2 text-gray-1">{info.start_date || info.start_date_im}</div>
      </div>  
      <div className="w-full flex items-center justify-start pb-2">
        <div className="w-1/2">End Date</div>
        <div className="w-1/2 text-gray-1">{info.end_date || info.end_date_im}</div>
      </div>  
      <div className="w-full flex items-center justify-start pb-2">
        <div className="w-1/2">Service Provider</div>
        <div className="w-1/2 text-gray-1">{info.service_provider || info.service_provider_im}</div>
      </div>
      <div className="w-full flex items-center justify-start pb-2">
        <div className="w-1/2">Beneficiary</div>
        <div className="w-1/2 text-gray-1">{info.beneficiary || info.beneficiary_im}</div>
      </div>
      <div className="w-full flex items-center justify-start pb-2">
        <div className="w-1/2">Training Cost</div>
        <div className="w-1/2 text-gray-1">{utils.numberWithCommas(info.training_cost || info.training_cost_im)}</div>
      </div>

      <div className="w-full pb-1">
        <div className="w-1/2 pt-1">Description</div>
        <div className="text-gray-1">{info.description || info.description_im}</div>
      </div>
    </section> 
  );

  const List = [
    {
      id: 1,
      name: "Capacity Building Plans",
      content: <BuildingPlans
      plans={plans} 
      handleShowModal={handleShowModal} 
      handleSearch={(e) => fetchCapacityPlans(e)}
      showSearch={id ? false:true}
      loading={loading}
      />
    },
    {
      id: 2,
      name: "Capacity Building Implemented",
      content: <BuildingImplemented 
      implemented={implemented} 
      handleShowModal={handleShowModal} 
      handleSearch={(e) => fetchCapacityImplemented(e)}
      showSearch={id ? false:true}
      loading={loading}
      />
    }
  ];  

  return (
    <>
      <div className="bg-white rounded-lg px-10 py-4 mb-3">
        <FancyTab 
          List={List} 
          hasTitle
          title="Capacity Building"
        />
      </div>

      {showDetails && (
        <CapacityModal
          title="Capacity Building"
          showModal={info}
          handleClose={handleCloseDetails}
        >
          <CapacityDetails />
        </CapacityModal>
      )}      
    </>
  );
};

export default CapacityBuilding;
