import React from "react";
import { Link, useLocation } from "react-router-dom";

// Images
import Profile from "../../asset/images/MenuIcons/Profile.svg";
import Calendar from "../../asset/images/MenuIcons/calendar.svg";
import Profile2User from  "../../asset/images/MenuIcons/profile-2user.svg";
import Status from  "../../asset/images/MenuIcons/status-up.svg";
import Card from  "../../asset/images/MenuIcons/card-pos.svg";
import Wifi from  "../../asset/images/MenuIcons/Wifi.svg";
import Trash from  "../../asset/images/MenuIcons/trash.svg";
import Info from  "../../asset/images/MenuIcons/info-circle.svg";
import Sms from  "../../asset/images/MenuIcons/sms-star.svg";
import House from  "../../asset/images/MenuIcons/house-2.svg";
import Document from  "../../asset/images/MenuIcons/Document.svg";
import Logout from  "../../asset/images/MenuIcons/Logout.svg";

// Style
import styles from "./Sidebar.module.scss";

// Sidebar menus
const nav_list = [
  {
    name: "Profile",
    link: "/profile",
    icon: Profile
  },
  {
    name: "Deliberative Function",
    link: "/deliberative-function",
    icon: Calendar
  },
  {
    name: "Administrative",
    link: "/administrative",
    icon: Profile2User 
  },
  {
    name: "Economic Data",
    link: "/economic-data",
    icon: Status 
  },
  {
    name: "Fiscal",
    link: "/fiscal",
    icon: Card 
  },
  {
    name: "Social Services",
    link: "/social-services",
    icon: Wifi 
  },
  {
    name: "Environment and Sanitation",
    link: "/environment",
    icon: Trash 
  },
  {
    name: "Political Issues",
    link: "/political-issues",
    icon: Info
  },
  {
    name: "Special Initiatives",
    link: "/special-initiatives",
    icon: Sms
  },
  {
    name: "Infrastructure",
    link: "/infrastructure",
    icon: House
  },
  {
    name: "Other Issues Of Interest",
    link: "/other-issues",
    icon: Document
  }
];

function Sidebar({ handleLogout = () => {} }) {
  let location = useLocation();

  return (
    <nav className={`col-md-3 col-lg-2 px-0 d-md-block bg-light collapse bg-white flex-row shadow ${styles.sidebar}`}>
      <div className="position-sticky h-screen">
        <div className="pl-5 pt-10 pb-12">
          <h1 className="text-xl font-bold m-0"> REGIONAL DATA </h1>
        </div>

        <div className={`flex flex-col overflow-auto ${styles.menu}`} id="showOnHover">
          <ul className=" flex flex-col min-w-full list-none text-sm mb-4">
            {nav_list.map((el) => (
              <li className={`z-0 hover:bg-gray-100 border-l-4 ${location.pathname.includes(el.link) ? 'active-menu bg-gray-100 border-indigo-500' : 'border-transparent'}`}>
                <Link to={el.link} className="w-full flex items-center text-gray-1 py-3 pl-4 pr-2">
                  <img src={el.icon} className="w-5" alt="" /> {/* Use web icons or svgs here instead */}
                  <span className="pl-3">{el.name}</span>
                </Link>
              </li>
            ))}
          </ul>
        </div>

        <div className="z-10 position-absolute bottom-0 w-full shadow-lg bg-white" 
          onClick={handleLogout}
        >
          <div className="w-full flex items-center pl-5 py-2 text-danger-1 cursor-pointer">
            <img src={Logout} className="w-5" alt="" />
            <span className="pl-3 text-sm">Logout</span>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Sidebar;
