import Layout from "../component/Layout/Layout";
import SocialServicesTabItems from "../component/Social Services/SocialServicesTabItems";

function SocialServicePage() {
  return (
    <Layout pageTitle="Social Services">
     <SocialServicesTabItems/>
    </Layout>
  );
}

export default SocialServicePage;
