import React, {useState,useEffect} from 'react'
import ProfileTable from './Tables/ProfileTable'
import {useSelector} from 'react-redux'


const tableHeading = {
  chps: "Chps",
  center: "Health Centers",
  clinis: "Clinics",
  polyclinis: "Polyclinics",
  hospital: "Hospitals",
  birth: "Births",
  death:"Deaths",
  ambulance: "Ambulance"
}


const HealthCare = () => {
  const { healthcare } = useSelector((state) => state.socialServices);

  const [profile, setprofile] = useState({});
  const [data, setdata] = useState([])

  const SetProfile = (health) => {
    const updatedHealth = healthcare?.list?.find((el) => el.id === health?.id);
    console.log('updatedHealth:', updatedHealth);
    setprofile(updatedHealth);
  };


  const TableDetailsInfo = () => (
    <section className="text-lg">
    <div className="w-full flex items-center justify-between pb-3">
      <div className="w-1/2">Births</div>
      <div className="text-gray-1 text-end">{profile.births}</div>
    </div>
    <div className="w-full flex items-center justify-between pb-3">
      <div className="w-1/2">Deaths</div>
      <div className="text-gray-1 text-end">{profile.deaths}</div>
    </div>
    <div className="w-full flex items-center justify-between pb-3">
      <div className="w-1/2">Ambulance</div>
      <div className="text-gray-1 text-end">{parseInt(profile?.ambulance_private_count, 10) + parseInt(profile?.ambulance_public_count, 10)}</div>
    </div>

    <div className="w-full flex items-center justify-between pb-3">
      <div className="w-1/2">Number of Accident Calls</div>
      <div className="text-gray-1 text-end">{profile.no_of_accident_calls}</div>
    </div>
    <div className="w-full flex items-center justify-between pb-3">
      <div className="w-1/2">Number of Adolescent Mobidity</div>
      <div className="text-gray-1 text-end">{profile.no_of_adolescents_mobidity}</div>
    </div>
    <div className="w-full flex items-center justify-between pb-3">
      <div className="w-1/2">Number of Adult Mobidity</div>
      <div className="text-gray-1 text-end">{profile.no_of_adults_mobidity}</div>
    </div>
    <div className="w-full flex items-center justify-between pb-3">
      <div className="w-1/2">Number of Calls Received</div>
      <div className="text-gray-1 text-end">{profile.no_of_calls_received}</div>
    </div>
    <div className="w-full flex items-center justify-between pb-3">
      <div className="w-1/2">Number of Calls Responded To</div>
      <div className="text-gray-1 text-end">{profile.no_of_calls_responded_to}</div>
    </div>
    <div className="w-full flex items-center justify-between pb-3">
      <div className="w-1/2">Number of CHPS Facility</div>
      <div className="text-gray-1 text-end">{profile.no_of_chps_facility}</div>
    </div>
    <div className="w-full flex items-center justify-between pb-3">
      <div className="w-1/2">Number of Clinic Facility</div>
      <div className="text-gray-1 text-end">{profile.no_of_clinics_facility}</div>
    </div>
    <div className="w-full flex items-center justify-between pb-3">
      <div className="w-1/2">Number of Health Centers</div>
      <div className="text-gray-1 text-end">{profile.no_of_health_center}</div>
    </div>
    <div className="w-full flex items-center justify-between pb-3">
      <div className="w-1/2">Number of Home Calls</div>
      <div className="text-gray-1 text-end">{profile.no_of_home_calls}</div>
    </div>

    <div className="w-full pb-3">
      <div className="w-full pb-2">Most Prevalent Disease Report</div>
      <div className="text-gray-1">{profile.most_prevalent_disease_report}</div>
    </div>
  </section>
  );

  useEffect(() => {
    let tepmArray = healthcare?.list?.map((el) => {
      return {
        id: el.id,
        chps: el?.no_of_chps_facility,
        center: el?.no_of_health_center ,
        clinis: el?.no_of_clinics_facility,
        polyclinis: el?.no_of_polyclinics ,
        hospital: el?.no_of_hospitals_facility ,
        birth: el?.births ,
        death: el?.deaths ,
        ambulance: parseInt(el?.ambulance_private_count, 10) + parseInt(el?.ambulance_public_count, 10)
      };
    });

    setdata(tepmArray);

  }, [healthcare]);

  const List = [
    {
      id: 1,
      name: "Primary HealthCare",
      content: ""
    },
    {
      id: 2,
      name: "Ambulance",
      content: ""
    }
  ]; 

  return (
    <div>
      <div>
          <ProfileTable
          title='HealthCare'
          tableHeading={tableHeading}
          list={data}
          showDivider
          SetProfile={SetProfile}
        >
          <TableDetailsInfo/>
        </ProfileTable> 
      </div>


    </div>
  )
}

export default HealthCare