import React, { useState, useEffect } from "react";
import ProfileTable from "../Tables/ProfileTable";

//Redux
import { getPolice } from "src/redux/SocialServices/action";
import { useDispatch, useSelector } from "react-redux";
import utils from "../../utils";

const tableHeading = {
  name: "District",
  station: "No of stations",
  personnel: "no Of Personnel",
  crimes: "no. Of Reported crimes",
  robbery: "Armed Robbery",
  stealing: "Stealing",
  violence: "Domestic Violence",
};

const Police = () => {
  const dispatch = useDispatch();
  const { security } = useSelector((state) => state.socialServices);
  let { pagination } = security?.police;

  const [tableMeta, setTableMeta] = useState({
    currentPage: 1,
    totalPages: 1,
    total: 0,
    limit: 10,
  });

  const [profile, setprofile] = useState({});
  const [loading, setloading] = useState(false);
  const [data, setdata] = useState([]);

  const SetProfile = (Police) => {
    const newPolice = security.police?.list?.find((el) => el.id === Police?.id);

    setprofile(newPolice);
  };

  const TableDetailsInfo = () => (
    <section className="text-lg">
      <div className="w-full flex items-center justify-start pb-1">
        <div className="w-1/2">District</div>
        <div className="text-gray-1">{profile.district}</div>
      </div>
      <div className="w-full flex items-center justify-start pb-1">
        <div className="w-1/2">No of stations</div>
        <div className="text-gray-1">{profile.no_of_stations}</div>
      </div>
      <div className="w-full flex items-center justify-start pb-1">
        <div className="w-1/2">No of Personnel</div>
        <div className="text-gray-1">{profile.no_of_personnels}</div>
      </div>
      <div className="w-full flex items-center justify-start pb-1">
        <div className="w-1/2">No. of Reported Crimes</div>
        <div className="text-gray-1">{profile.no_of_reported_crimes}</div>
      </div>
      <div className="w-full flex items-center justify-start pb-1">
        <div className="w-1/2">Armed Robbery</div>
        <div className="text-gray-1">{profile.no_armed_robbery_cases}</div>
      </div>
      <div className="w-full flex items-center justify-start pb-1">
        <div className="w-1/2">Stealing</div>
        <div className="text-gray-1">{profile.no_of_stealing}</div>
      </div>
      <div className="w-full flex items-center justify-start pb-1">
        <div className="w-1/2">Domestic Violence</div>
        <div className="text-gray-1">{profile.no_of_domestic_violence}</div>
      </div>
    </section>
  );

  const fetchPolice = (page, limit, search = "") => {
    setloading(true);
    dispatch(getPolice(page, limit, search)).then(
      () => {
        setloading(false);
      },
      () => {
        setloading(false);
      }
    );
  };

  const handlePoliceFetch = (type, value) => {
    switch (type) {
      case 'limit':
        setTableMeta((prev) => { return { ...prev, currentPage: 1, limit: value } });
        fetchPolice(1, value);
      break;

      case 'currentPage':
        setTableMeta((prev) => { return {  ...prev, currentPage: value }});        
        fetchPolice(value, tableMeta.limit);
      break;
    
      default:
        break;
    }
  };

  useEffect(() => {
    let tepmArray = security.police?.list?.map((el) => {
      return {
        id: el.id,
        name: el.district,
        station: el.no_of_stations,
        personnel: el.no_of_personnels,
        crimes: el.no_of_reported_crimes,
        robbery: el.no_armed_robbery_cases,
        stealing: el.no_of_stealing,
        violence: el.no_of_domestic_violence,
      };
    });

    setdata(tepmArray);

    // if revenue.pagination is available
    if (utils.present(security.police?.pagination)) {
      setTableMeta({
        ...tableMeta,
        total: pagination?.count,
        totalPages: pagination.totalPages,
      });
    }
  }, [security]);

  useEffect(() => {
    fetchPolice();
  }, []);

  return (
    <div>
      <ProfileTable
        title="Police"
        showTitle={false}
        tableHeading={tableHeading}
        SetProfile={SetProfile}
        list={data}
        hasFilter
        hasPagination
        entity="List"
        loading={loading}
        meta={tableMeta}
        fetchList={handlePoliceFetch}
        handleSearch={(e) => fetchPolice(1, 10, e)}
      >
        <TableDetailsInfo />
      </ProfileTable>
    </div>
  );
};

export default Police;
