import * as types from './types';

const initState = {
    specialInitiatives: {
        list: [],
        tab_list: [],
        meta: {},
        pagination: {}
    },
    meta: {},
    details: {
        list: [],
        count: 1,
        pages: null,
        limit: 10
    },    
};

export const specialInitiatives = (state = initState, action) => {
    switch (action.type) {
        case types.SPECIAL_INITIATIVES:
            return {
                ...state,
                specialInitiatives: {
                    ...state.specialInitiatives,
                    list: action.payload.results,
                    meta: action.payload.meta,
                    pagination: { 
                      count: action.payload.count,
                      totalPages: action.payload.pages,
                      limit: action.payload.limit,
                      next: action.payload.next,
                      previous: action.payload.previous 
                    }
                }
            }
        
        case types.SET_SPECIAL_INITIATIVES:
            const payload = action.payload;
            const spec_list = {...payload.results};
        
            return {
                ...state,
                meta: payload?.meta,
                specialInitiatives: {
                    ...state.specialInitiatives,
                    tab_list: spec_list,
                }
            };

        case types.SPECIALINITIATIVE_BY_TYPE:
            return {
                ...state, 
                details: {
                    ...action.payload,
                    list: action.payload.results,
                }
            };

        default:
            return state;
    }
}
