export const BASIC_EDUCATION = "BASIC_EDUCATION";
export const HEALTHCARE = "HEALTHCARE";
export const ELECTRICITY = "ELECTRICITY";
export const WATER_COVERAGE = "WATER_COVERAGE";
export const LAND_USE_AND_SPATIAL_PLANNING = "LAND_USE_AND_SPATIAL_PLANNING";
export const SECURITY = "SECURITY";
export const ROADS = "ROADS";
export const POLICE = "POLICE";
export const FIRE_SERVICE = "FIRE_SERVICE";
export const OTHER_SECURITY = "OTHER_SECURITY";
export const SET_SOCIAL_SERVICE = "SET_SOCIAL_SERVICE";