import React, { useState, useEffect, useCallback } from "react";
import ProfileTable from "./Tables/ProfileTable";
import ProjectPopUp from "./Infrastructure/ProjectPopUp";
import DetailsModal from "./DetailsModal";

import utils from "../utils";

import { useSelector } from "react-redux";

const Health = () => {
  const { healthInfrastructure } = useSelector((state) => state.infrastructure);

  const [list, setlist] = useState([]);
  const [current, setcurrent] = useState(1);
  const [showModal, setshowModal] = useState(false);
  const [profile, setprofile] = useState({});
  const [data, setdata] = useState([]);

  const tableHeading = {
    name: "Project Name",
    health_type: "Type",
    location: "Location",
    construction_status: "Status of Completion",
    percentage: "Percentage of completion",
    start: "Project Commence",
    completion: "Expected Completion",
    cost: "Project Cost",
  };

  const SetProfile = (profile) => {
    const record = healthInfrastructure?.list?.find(
      (el) => el.id === profile.id
    );
    setlist([record.picture_file_url]);
    setprofile({...profile, record});
  };

  const handleShowImag = useCallback((val) => {
    setcurrent(val);
    setshowModal(true);
  }, []);

  const TableDetailsInfo = () => (
    <section className="text-lg">
      <div className="w-full flex items-start justify-start pb-3">
        <div className="w-1/2">Project Name</div>
        <div className="text-gray-1 w-1/2 text-end">{profile.name}</div>
      </div>
      <div className="w-full flex items-start justify-start pb-3">
        <div className="w-1/2">Health Type</div>
        <div className="text-gray-1 w-1/2 text-end">{profile.health_type}</div>
      </div>
      <div className="w-full flex items-start justify-start pb-3">
        <div className="w-1/2">Location</div>
        <div className="text-gray-1 w-1/2 text-end">{profile.location}</div>
      </div>
      <div className="w-full flex items-start justify-between pb-3">
        <div className="w-1/2">Construction Status</div>
        <div className="">{profile.construction_status}</div>
      </div>
      <div className="w-full flex items-start justify-between pb-3">
        <div className="w-1/2">Percentage of Completion</div>
        <div className="">{profile.percentage}</div>
      </div>
      <div className="w-full flex items-start justify-start pb-3">
        <div className="w-1/2">Project Commencement</div>
        <div className="text-gray-1 w-1/2 text-end">{profile.start}</div>
      </div>
      <div className="w-full flex items-start justify-start pb-3">
        <div className="w-1/2">Project Completion</div>
        <div className="text-gray-1 w-1/2 text-end">{profile.completion}</div>
      </div>
      <div className="w-full flex items-start justify-start pb-3">
        <div className="w-1/2">Project Cost</div>
        <div className="text-gray-1 w-1/2 text-end">{profile.cost}</div>
      </div>
      <div className="w-full pb-3">
        <div className="w-full text-end">
          <ProjectPopUp list={list} handleShowImag={handleShowImag} />
        </div>
      </div>
    </section>
  );

  useEffect(() => {
    let tepmArray = healthInfrastructure?.list?.map((el) => {
      return {
        id: el.id,
        name: el.project_name,
        health_type: el.health_type,
        location: el.location,
        construction_status: (
          <div className="text-center text-white bg-green-600 rounded-full py-1 px-2">
            {el.construction_status || "N/A"}
          </div>
        ),
        percentage: (
          <div className="text-center">{`${el.completion_status}%`}</div>
        ),
        start: new Date(el.project_commence)?.toLocaleDateString(),
        completion: new Date(el.expected_completion)?.toLocaleDateString(),
        cost: utils.numberWithCommas(el.contract_sum),
      };
    });
    setdata(tepmArray);
  }, [healthInfrastructure]);

  return (
    <div>
      <ProfileTable
        title="Health Infrastructure"
        tableHeading={tableHeading}
        list={data}
        showDivider
        SetProfile={SetProfile}
      >
        <TableDetailsInfo />
      </ProfileTable>

      {showModal && (
        <DetailsModal
          current={current}
          showModal={showModal}
          handleClose={() => setshowModal(false)}
          items={list.map((el, index) => {
            return { key: index + 1, src: el };
          })}
        />
      )}
    </div>
  );
};

export default Health;
