import React, { useEffect, useState } from "react";
import utils from "../../utils";

// Components
import ProfileTable from "../../component/Tables/ProfileTable";
import Widget from "../../component/DashbordWidget";
import DetailsPage from "./DetailsPage";
import Tooltip from './Tooltip';
import RegionWidget from "./RegionWidget";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { getRevenue, getRevenueChart } from "../../redux/fiscal/action";

const meta_colors = ["#7A6EFE", "#FF5363", "#FFA901"];

const Revenue = () => {
  const dispatch = useDispatch();
  const { revenue } = useSelector(state=>state.fiscal);
  let { pagination, chart } = revenue;

  const [tableMeta, setTableMeta] = useState({
    currentPage: 1,
    totalPages: 1,
    total: 0,
    limit: 10    
  });  
  const [info, setinfo] = useState({});
  const [viewDetails, setviewDetails] = useState(false);
  const [loading, setloading] = useState(false);
  const [data, setdata] = useState([]);
  const [total, settotal] = useState("");
  const [regions, setregions] = useState([]);

  const tableHeading =  {
    name: "District",
    revenue: "Projected Revenue",
    actual: "Actual Revenue",
    dacf_direct_transfers: "DACF Transfers",
    // dacf_rfg3: "DACF RFG3",
    donors: "Donors",
    gog: "GOG",
    igf: "IGF",
    // mp_share_of_dacf: "MP Share",
    // ngos: "NGOS",
    other_donations: "Other Donations",
    variance:"Revenue Variance",
  };

  const dataItems = [
    {
      id: 1,
      number: revenue?.meta?.total_revenue?.amount,
      link: "",
      name: revenue?.meta?.total_revenue?.name,
      bck: "#7A6EFE",
      handleClick: () => { 
        settotal(revenue?.meta?.total_revenue?.amount);
        setviewDetails(true);
      }
    },
    {
      id: 2,
      number: revenue?.meta?.highest?.amount ,
      link: "",
      name: revenue?.meta?.highest?.name,
      bck: "#FF5363",
      handleClick: ()=>{}
    },
    {
      id: 3,
      number: revenue?.meta?.lowest?.amount ,
      link: "",
      name: revenue?.meta?.lowest?.name,
      bck: "#FFA901",
      handleClick: ()=>{}
    },
    {
      id: 4,
      number: revenue?.meta?.average?.amount ,
      link: "",
      name: revenue?.meta?.average?.name,
      bck: "#24A8FA",
      handleClick: ()=>{}
    },
  ];

  const SetProfile = (Rev) => {
    const updatedRevenue = revenue?.list?.find((el)=> el.id === Rev?.id)
    setinfo(updatedRevenue);
  };

  const fetchRevenue = (page, limit, search="") => {
    setloading(true);
    dispatch(getRevenue(page, limit, search))
    .then(() => {
      setloading(false);
      dispatch(getRevenueChart())
    }, () => {
      setloading(false);
    })
  };  

  const handleRevenueFetch = (type, value) => {
    switch (type) {
      case 'limit':
        setTableMeta((prev) => { return { ...prev, currentPage: 1, limit: value } });
        fetchRevenue(1, value);
      break;

      case 'currentPage':
        setTableMeta((prev) => { return {  ...prev, currentPage: value }});        
        fetchRevenue(value, tableMeta.limit);
      break;
    
      default:
        break;
    }
  }; 

  // Page components
  const TableDetailsInfo = () => (
    <section className="text-lg">
      <div className="w-full flex items-center justify-start pb-3">
        <div className="w-1/2">Distrcit</div>
        <div className="text-gray-1">{info.district}</div>
      </div>
      <div className="w-full flex items-center justify-start pb-3">
        <div className="w-1/2">Projected Revenue</div>
        <div className="text-gray-1">{utils.numberWithCommas(info.projected_quarterly_revenue) || "N/A"}</div>
      </div>
      <div className="w-full flex items-center justify-start pb-3">
        <div className="w-1/2">Actual Revenue</div>
        <div className="text-gray-1">{utils.numberWithCommas(info.actual_revenue_received) || "N/A"}</div>
      </div>
      <div className="w-full flex items-center justify-start pb-3">
        <div className="w-1/2">Source of Revenue</div>
        <div className="text-gray-1">{info.support_provided_by_mmda || "N/A"}</div>
      </div>
      <div className="w-full flex items-center justify-start pb-3">
        <div className="w-1/2">Amount from Source of Revenue</div>
        <div className="text-gray-1">{info.type_of_support || "N/A"}</div>
      </div>
      <div className="w-full flex items-center justify-start pb-3">
        <div className="w-1/2">Revenue Variance</div>
        <div className="text-gray-1">{utils.numberWithCommas(info.revenue_variance) || "N/A"}</div>
      </div>
      <div className="w-full pb-1">
        <div className="w-1/2">Reason of Variance</div>
        <div className="text-gray-1">
          {info.reasons_for_variance || "N/A"}
        </div>
      </div>      
    </section>
  ); 

  const Details = () => (
    <section className="bg-white p-4 mb-4 rounded-lg">
      <div className="w-full flex items-center justify-center pt-2">
        <div className="w-1/2 sm:w-1/4">
          <Tooltip 
            data={regions} 
            active
          />
        </div>
      </div>

      <div className="pt-5">
        <div className="text-center pb-4">
          <div className="font-bold text-black text-3xl text-center mb-2">
            {total}
          </div>
          <p>Total Revenue/ Region</p>
        </div>

        <div className="w-full flex justify-center items-center">
          <div className="w-full sm:w-4/5 flex flex-wrap">
            {regions.map((el) => (
              <div className="w-full sm:w-1/3 flex justify-center items-start mb-4">
                <div className="w-10/12 flex items-start">
                  <RegionWidget
                    color={el.color}
                    name={el.name}
                    amount={el.amount}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>    
  );

  useEffect(() => {
    let tepmArray = revenue?.list?.map((el) => {
      return  {
        id: el.id,
        name: el.district,
        revenue: utils.numberWithCommas(el.projected_quarterly_revenue),
        actual: utils.numberWithCommas(el.actual_revenue_received),
        dacf_direct_transfers: utils.numberWithCommas(el?.dacf_direct_transfers),
        // dacf_rfg3: utils.numberWithCommas(el?.dacf_rfg3),
        donors: utils.numberWithCommas(el?.donors),
        gog: utils.numberWithCommas(el?.gog),
        igf: utils.numberWithCommas(el?.igf),
        // mp_share_of_dacf: utils.numberWithCommas(el?.mp_share_of_dacf),
        // ngos: utils.numberWithCommas(el?.ngos),
        other_donations: utils.numberWithCommas(el?.other_donations),
        variance: utils.numberWithCommas(el.revenue_variance),
      };
    });
    setdata(tepmArray);

    // if revenue.pagination is available
    if (utils.present(revenue?.pagination)) {
      setTableMeta({
        ...tableMeta,
        total: pagination?.count,
        totalPages: pagination.totalPages,
        // limit: pagination.limit
      });
    }
  },[revenue]);

  useEffect(() => {
    let tempRegions = chart.map((el) => {
      return {
        title: el?.region,
        name: el?.region,
        amount: el?.revenue,
        value: chart?.filter((i) => i.region === el.region)?.length,
        color: meta_colors[Math.floor(Math.random() * (2 - 0 + 1)) + 0]        
      }
    });

    setregions(tempRegions);
  }, [chart]);

  useEffect(() => {
    fetchRevenue()
  }, []);

  return (
    <div>
      {viewDetails ?
        <DetailsPage active setviewDetails={setviewDetails}>
          <Details />
        </DetailsPage>
      :
        <>
          <div className="flex flex-row overflow-y-hidden">
            {dataItems.map((item) => (
              <div className="w-1/2 sm:w-auto" style={{ minWidth: '18rem'}}>
                <Widget
                  id={item.id}
                  link={item.link}
                  number={item.number}
                  name={item.name}
                  bck={item.bck}
                  lastItem={dataItems.length}
                  widgetFont="text-2xl"
                  handleClick={item.handleClick}
                />
              </div>
            ))}
          </div>

          <ProfileTable
            title="Revenue"
            tableHeading={tableHeading}
            list={data}
            SetProfile={SetProfile}
            hasFilter
            showSort
            hasPagination
            entity="Revenues"
            loading={loading}
            meta={tableMeta}
            fetchList={handleRevenueFetch}
            handleSearch={(e) => fetchRevenue(1, 10, e)}
          >
            <TableDetailsInfo />
          </ProfileTable>
        </>
      }
    </div>
  );
};

export default Revenue;
