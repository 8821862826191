import React from "react";

import Police from "./Police"
import FireService from "./FireService";
import OtherSecurity from "./OtherSecurity";

import FancyTab from "./Tabs/FancyTab";

const Security = () => {

  const List = [
    {
      id: 1,
      name: "Police",
      content: <Police />
    },
    {
      id: 2,
      name: "Fire Service",
      content: <FireService />
    },
    {
      id: 3,
      name: "Other",
      content: <OtherSecurity />
    }
  ];

  return (
    <div className="shadow-sm rounded-lg bg-white pb-2 mb-2">
      <div className="px-6 pt-4">
        <FancyTab title="Security" hasTitle List={List} />
      </div>
    </div>
  );
};

export default Security;
