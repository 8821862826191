import React from "react";
import { Button } from "reactstrap";

// Components
import Search from "../Search/Search";

// Images
import sortImg from "../../asset/images/sort.svg"

const TableFilter = ({
  showFilter=()=>{},
  parentSearchStyle="",
  placeholder="Search",
  showExport=true,
  showSort=false,
  handleSearch = () => {}
}) => {
  return (
    <div className="w-full flex items-center justify-between">
      <Search 
        handleOnSearch={(e) => handleSearch(e)}     
        placeholder={placeholder}
        className="py-2"
        parentSearchStyle={parentSearchStyle}
      />



      <div className="flex items-center hidden">
        <div outline className="flex items-center justify-between border border-gray-300 bg-light rounded-lg px-2 py-2 ml-5 cursor-pointer" onClick={showFilter}>
          <span className="pl-2 pr-10">Filter</span>
          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
          </svg>
        </div>

        {showSort &&
          <div className="w-max hover:bg-gray-100 cursor-pointer flex items-center ml-5 px-2 py-1 rounded-lg text-base">
            <img src={sortImg} className="h-8" alt="" />
            <div className="pl-2">Sort</div>
          </div>
        }

        {showExport &&
          <Button color="primary" className="px-4 py-2 ml-5">
            Export
          </Button>
        }
      </div>
    </div>
  );
};

export default TableFilter;
