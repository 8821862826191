import React, { useEffect } from 'react';
import { getUpcomingMeetings, getMeetingsHeld } from '../redux/deliberative/action';

import { useDispatch } from 'react-redux';

// Components
import Layout from "../component/Layout/Layout";
import Delibrative from "../component/DeliberativeFunctions/Deliberative";

function DeliberativeFunction() {
  const dispatch = useDispatch();

  useEffect(() => {
    console.log('Fetching meetings...');
    dispatch(getUpcomingMeetings());
    dispatch(getMeetingsHeld());    
  });

  return (
    <Layout pageTitle="Deliberative Function">
      <Delibrative />
    </Layout>
  );
}

export default DeliberativeFunction;
