import React, { useState, useEffect } from "react";
import Widget from "../DashbordWidget";
import ProfileTable from "../Tables/ProfileTable";
import utils from '../../utils'

// Redux
import { getElectricity } from "../../redux/SocialServices/action";
import { useDispatch, useSelector } from "react-redux";




  const tableHeading =  {
    name: "District",
    number: "Number of Electricity Applications",
    processed: "Number of Applications Processed",
    issued: "Number of Applications Issued",
    communities: "Communities Without Electricity"
  };  
  



const Electricity = () => {
  const dispatch = useDispatch();
  const { electricity } = useSelector((state) => state.socialServices);
  let pagination = electricity.pagination;

  const [tableMeta, setTableMeta] = useState({
    currentPage: 1,
    totalPages: 1,
    total: 0,
    limit: 10    
  });  


  // const [profile, setprofile] = useState({});
  const [data, setdata] = useState([])
  const [loading, setloading] = useState(false)
  const [info, setinfo] = useState({})

  const SetProfile = (electricity) => {
    setinfo(electricity);
  };

  const dataItems = [
    {
      id: 1,
      number: utils.numberWithCommas(electricity?.meta?.No_of_applications),
      link: "",
      name: "No. Applications ",
      bck: "#7A6EFE",
    },
    {
      id: 2,
      number: utils.numberWithCommas(electricity?.meta?.No_of_applications_processed),
      link: "",
      name: "No. applications Processed",
      bck: "#FF5363",
    },
    {
      id: 3,
      number: utils.numberWithCommas(electricity?.meta?.No_of_applications_issued),
      link: "",
      name: "No. of Applications Issued",
      bck: "#FFA901",
    },
    {
      id: 4,
      number: utils.numberWithCommas(electricity?.meta?.No_of_communities_without_service),
      link: "",
      name: "Communities without Service",
      bck: "#24A8FA",
    },
  ];

  const TableDetailsInfo = () => (
    <section className="text-lg">
      <div className="w-full flex items-center justify-start pb-1">
        <div className="w-1/2">District</div>
        <div className="text-gray-1 w-1/2">{info.name}</div>
      </div>
      <div className="w-full flex items-center justify-start pb-1">
        <div className="w-1/2">Number of Electricity Applications</div>
        <div className="text-gray-1 w-1/2">{info.number}</div>
      </div>
      <div className="w-full flex items-center justify-start pb-1">
        <div className="w-1/2">Number of Applications Processed</div>
        <div className="text-gray-1 w-1/2">{info.processed}</div>
      </div>
      <div className="w-full flex items-center justify-start pb-3">
        <div className="w-1/2">Number of Applications Issued</div>
        <div className="text-gray-1 w-1/2">{info.issued}</div>
      </div>
      <div className="w-full  items-center justify-start pb-1">
        <div className="">Communities Without Electricity</div>
        <div className="text-gray-1">{info.communities}</div>
      </div>
    </section>
  );

  const fetchElectricity = (page, limit, search="") => {
    setloading(true);
    dispatch(getElectricity(page, limit, search))
    .then(() => {
      setloading(false);
    }, () => {
      setloading(false);
    })
  };

  const handleElectricityFetch = (type, value) => {
    switch (type) {
      case 'limit':
        setTableMeta((prev) => { return { ...prev, currentPage: 1, limit: value } });
        fetchElectricity(1, value);
      break;

      case 'currentPage':
        setTableMeta((prev) => { return {  ...prev, currentPage: value }});        
        fetchElectricity(value, tableMeta.limit);
      break;
    
      default:
        break;
    }
  };   

  useEffect(() => {
    let tepmArray = electricity?.list?.map((el) => {
      return  {
        name: el.district,
        number: el.number_of_applications,
        processed: el.number_of_applications_processed,
        issued: el.number_of_applications_issued,
        communities: el.communities_without_facilities,
      };
    });

    setdata(tepmArray);

    // if revenue.pagination is available
    if (utils.present(electricity?.pagination)) {
      setTableMeta({
        ...tableMeta,
        total: pagination?.count,
        totalPages: pagination.totalPages,
        // limit: pagination.limit
      });
    }
  },[electricity]); 

  useEffect(() => {
    fetchElectricity();
  }, []);

  return (
    <div>
      <div className="w-full flex flex-row">
        {dataItems.map((item) => (
          <div className="w-full sm:w-1/4">
            <Widget
              id={item.id}
              link={item.link}
              number={item.number}
              name={item.name}
              bck={item.bck}
              lastItem={dataItems.length}
            />
          </div>
        ))}
      </div>

      <ProfileTable
        title="Electricity"
        tableHeading={tableHeading}
        SetProfile={SetProfile}
        list={data}
        hasFilter
        hasPagination
        entity="List"
        loading={loading}
        meta={tableMeta}
        fetchList={handleElectricityFetch}
        handleSearch={(e) => fetchElectricity(1, 10, e)}
      >
        <TableDetailsInfo />
      </ProfileTable>
    </div>
  );
};

export default Electricity;