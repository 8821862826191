/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import ProfileTable from "../../component/Tables/ProfileTable";
import Widget from "../../component/DashbordWidget";
import utils from "../../utils";

// Redux
import { useSelector } from 'react-redux';

const Profile = ({
  extendLinks=true,
  loading=false,
  setloading=()=>{},
  fetchProfiles=()=>{}
}) => {
  const { profiles, districtInfo } = useSelector(state => state.profile);
  const meta = profiles?.meta;
  const pagination = profiles?.pagination;

  const [tableMeta, setTableMeta] = useState({
    currentPage: 1,
    totalPages: 1,
    total: 0,
    limit: 10    
  });
  const [profile, setprofile] = useState({});
  const [listProfiles, setlistProfiles] = useState([]);
  const [constituency, setconstituency] = useState([]);

  const tableHeading = {
    name: "MMDCE Name",
    region: "Region",
    district: "District",
    area: "Electoral Areas",
    station: "Polling Stations",
    population: "Population"
  };  

  const tableHeadForConst = {
    name: "Name of MP",
    constituency: "Constituency Name",
    population: "Voter Population"
  };  

  const dataItems = [
    {
      id: 1,
      number: meta.district_submitted ,
      link: "/profile/districts",
      name: "Districts Submitted",
      bck: "#7A6EFE"
    },
    {
      id: 2,
      number: meta.regions_submitted ,
      link: "/profile/regions",
      name: "Region Submitted",
      bck: "#FF5363"
    },
    {
      id: 3,
      number: utils.numberWithCommas(meta.sum_of_polling_stations),
      link: "",
      name: "Polling Station",
      bck: "#FFA901"
    },
    {
      id: 4,
      number: utils.numberWithCommas(meta.sum_of_electoral_areas),
      link: "",
      name: "Electoral Areas",
      bck: "#24A8FA"
    },
  ];

  const SetProfile = (profile) => {
    setprofile(profile);
  };  

  const TableDetailsInfo = () => (
    <section className="text-lg">
    <div className="w-full flex items-start justify-start pb-2">
      <div className="w-1/2">Officer Name</div>
      <div className="w-1/2 text-gray-1">{profile.name}</div>
    </div>
    <div className="w-full flex items-start justify-start pb-2">
      <div className="w-1/2">Region</div>
      <div className="w-1/2 text-gray-1">{profile.region}</div>
    </div>
    <div className="w-full flex items-start justify-start pb-2">
      <div className="w-1/2">District</div>
      <div className="w-1/2 text-gray-1">{profile.district}</div>
    </div>
    <div className="w-full flex items-start justify-start pb-2">
      <div className="w-1/2">Electoral Areas</div>
      <div className="w-1/2 text-gray-1">{profile.area}</div>
    </div>
    <div className="w-full flex items-start justify-start pb-2">
      <div className="w-1/2">Polling Stations</div>
      <div className="w-1/2 text-gray-1">{profile.station}</div>
    </div>
    <div className="w-full flex items-start justify-start pb-2">
      <div className="w-1/2">Population</div>
      <div className="w-1/2 text-gray-1">{profile.population}</div>
    </div>
  </section>
  );

  const handleProfileFetch = (type, value) => {
    switch (type) {
      case 'limit':
        setTableMeta((prev) => { return { ...prev, currentPage: 1, limit: value } });
        fetchProfiles(1, value);
      break;

      case 'currentPage':
        setTableMeta((prev) => { return {  ...prev, currentPage: value }});        
        fetchProfiles(value, tableMeta.limit);
      break;
    
      default:
        break;
    }    
  };
  
  useEffect(() => {
    if (utils.present(pagination)) {
      setTableMeta({ 
        ...tableMeta, 
        totalPages: pagination.totalPages,
        total: pagination.count,
        limit: pagination.limit
      });
    }
  }, [pagination]);
  
  useEffect(() => {
    let tepmArray = profiles?.list.map((el) => {
      return  {
        name: el.mmdce_submitting_information,
        region: el.region,
        district: el.district,
        area: utils.numberWithCommas(el.no_of_electoral_areas),
        station: utils.numberWithCommas(el.no_of_polling_stations),
        population: utils.numberWithCommas(el.district_population),
      };
    });

    // Set re-mapped profile items to table
    setlistProfiles(tepmArray);

    if (!extendLinks) {
      let tepmConsti = districtInfo?.constituencies?.map((el) => {
        return  {
          name: el.name_of_mp,
          constituency: el.constituency_name,
          population: utils.numberWithCommas(el.voter_population)
        };
      });

      setconstituency(tepmConsti);
    }
  },[profiles]);
  
  return (
    <>
      {extendLinks &&
        <div className="w-full flex flex-row">
          {dataItems.map((item) => (
            <div className="w-1/2 sm:w-1/4">
              <Widget 
                id={item.id} 
                link={extendLinks && item.link} 
                number={item.number} 
                name={item.name} 
                bck={item.bck} 
                lastItem={dataItems.length} 
              />
            </div>
          ))}
        </div>
      }

      <ProfileTable
        title="Profile Information"
        tableHeading={tableHeading}
        SetProfile={SetProfile}
        list={listProfiles}
        hasFilter={extendLinks}
        entity="Profiles"
        hasPagination={extendLinks}
        meta={tableMeta}
        loading={loading}
        fetchList={handleProfileFetch}
        addNumbering={false}
        handleSearch={(e) => fetchProfiles(1, 10, e)}
      >
        <TableDetailsInfo />
      </ProfileTable>

      {!extendLinks &&
        <ProfileTable
          title="Constituency"
          tableHeading={tableHeadForConst}
          list={constituency}
          hasFilter={extendLinks}
          loading={loading}
          showModal={false}
          entity="Constituency"
          hasPagination={false}
          addNumbering={false}
        >
        </ProfileTable>
      }
    </>
  );
}

export default Profile;
