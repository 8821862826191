import Layout from "../component/Layout/Layout";
import OtherIssues from "../component/ProfileTabs/OtherIssuesOfInterest";

function OtherIssuesPage() {
  return (
    <Layout pageTitle="Other Issues Of Interest">
      <OtherIssues />
    </Layout>
  );
}

export default OtherIssuesPage;
