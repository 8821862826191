import React, { useState, useEffect } from "react";
import utils from "../../utils";

// Components
import Widget from "../DashbordWidget";
import Itenerary from "../Iteneraries";
import TravelCard from "../TravelCard";
import Search from "../Search/Search";
import { useDispatch, useSelector } from "react-redux";
import { getIteneraries } from "../../redux/administrative/action";

import plane from "../../asset/images/ic24-plane.svg";

const Iteneraries = () => {
  const dispatch = useDispatch();
  const { iteneraries } = useSelector(state=>state.administrative);
  let pagination = iteneraries.pagination;

  const [current, setcurrent] = useState(1);
  const [data, setdata] = useState([]);

  const dataItems = [
    {
      id: 1,
      number: iteneraries?.meta?.travel_outside_district ,
      name: "Travel Outside District",
      bck: "#7A6EFE",
    },
    {
      id: 2,
      number: iteneraries?.meta?.travel_outside_region ,
      name: "Travel Outside Region",
      bck: "#FF5363",
    },
    {
      id: 3,
      number: iteneraries?.meta?.travel_outside_country ,
      name: "Travel Outside Country",
      bck: "#24A8FA",
    },
  ];
  
  const months = [
    {
      id: 1,
      name: "January",
    },
    {
      id: 2,
      name: "February",
    },
    {
      id: 3,
      name: "March",
    }
  ];

  useEffect(() => {
    if (utils.present(iteneraries)) {
      let tepmArray = iteneraries?.list?.map((el) => {
        return  {
          name: el.name_of_official,
          title: el.district,
          label: el.travel_outside,
          start: el.duration_start,
          end: el.duration_end,
          icon: plane,
        };
      });    
  
      setdata(tepmArray)
    }
  }, [iteneraries]);
  
  const fetchIteneraries = (search="") => {
    dispatch(getIteneraries(search))
  }
  
  useEffect(() => {
    fetchIteneraries()
  }, []);

  return (
    <div className="w-full ">
      <div className="grid grid-cols-12 gap-x-2">
        <div className=" col-span-8 ">
          <div className="w-full flex flex-row">
            {dataItems.map((item) => (
              <Widget
                id={item.id}
                number={item.number}
                name={item.name}
                bck={item.bck}
                lastItem={dataItems.length}
              />
            ))}
          </div>

          {/* Administrative Tabs */}

          <div className="bg-white px-4 pt-4 pb-2 flex items-start">
            <div className="flex items-center w-2/3 overflow-y-hidden pb-2" id="showOnHover">
              {months.map((el) => (
                <button
                  className={`${
                    current === el.id
                      ? "bg-black text-white"
                      : "bg-gray-200 text-gray-deep"
                  } 
                      border-none rounded px-4 py-2 mr-2
                    `}
                  onClick={() => setcurrent(el.id)}
                >
                  {el.name}
                </button>
              ))}
            </div>

            <div className="w-1/2">
              <Search
                placeholder="Search for district"
                className="w-full py-2 pl-4"
                parentSearchStyle="w-full flex justify-end"
                handleOnSearch={(e) => fetchIteneraries(e)}
              />
            </div>
          </div>

          {current === 1 && (
            <Itenerary showTitle={false} itemsStyling="w-full sm:w-1/3" itemsParent="p-3" dataItems={data} sub_title="text-sm" />
          )}
          {current === 2 && (
            <Itenerary showTitle={false} itemsStyling="w-full sm:w-1/3" itemsParent="p-3" dataItems={data.slice(0, 6)} sub_title="text-sm" />
          )}
          {current === 3 && (
            <Itenerary showTitle={false} itemsStyling="w-full sm:w-1/3" itemsParent="p-3" dataItems={data.slice(0, 2)} sub_title="text-sm" />
          )}
          {current === 4 && (
            <Itenerary showTitle={false} itemsStyling="w-full sm:w-1/3" itemsParent="p-3" dataItems={data.slice(0, 3)} sub_title="text-sm" />
          )}
        </div>

        <div className="col-span-4 pl-4">
          <div className="ml-3 pb-3 mb-4 rounded-lg bg-white">
            <div className="px-4 pt-4">
              <h1 className="text-2xl mb-0 font-semibold">Upcoming Travels</h1>
              <span className=" text-gray-400 text-sm">
                Don't miss scheduled events
              </span>
            </div>

            <div>
              <div className="w-full flex flex-col px-2">
                {data.map((el) => (
                  <TravelCard
                    name={el.name}
                    title={el.title}
                    label={el.label}
                    start={el.start}
                    end={el.end}
                    icon={el.icon}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Iteneraries;
