import { toast } from "react-toastify";
import axios from "axios";

export const isEmpty = (obj) => {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};

export const isEmptyString = (text) => {
  return text.length > 0 && !/[^\s]/.test(text);
};

export const present = (value) => {
  if (!value) {
    return false;
  }
  if (typeof value === "object") {
    if (Object.keys(value).length > 0) {
      return true;
    }
    return false;
  }
  if (Array.isArray(value) || typeof value === "string") {
    return value && value.length > 0;
  } else {
    return !!value;
  }
};

export const createQueryParams = (filters) => {
  var query = "";
  if (present(filters)) {
    Object.keys(filters).forEach(function (key) {
      if (typeof filters[key] === "undefined") {
        query = "";
      } else if (typeof filters[key] === "object") {
        filters[key].forEach((el) => {
          query = query + `&${key}[]=${el}`;
        });
      } else {
        query = query + `&${key}=${filters[key]}`;
      }
    });
  }

  return query;
};

export const numberWithCommas = (number) => {
  if (number != null) {
    let negNumber;

    if (parseFloat(number) < 0) {
      negNumber = -1 * number;
      number = negNumber;
    }

    const sanitized = parseFloat(number.toString().split(",").join(""));
    let fomattedNum = parseFloat(sanitized);
    
    const dp = number % 1;
    if (dp) fomattedNum = fomattedNum.toFixed(2);
    const numberString = fomattedNum.toString().replace(/^\d+/, (fomattedNum) => [...fomattedNum].map((digit, index, digits) => (!index || (digits.length - index) % 3 ? "" : ",") + digit).join(""));

    return negNumber ? `-${numberString}` : numberString;
  } 
  else return number;
};

export const formatAmount = (number) => {
  if (number) {
    const sanitized = parseFloat(number.toString().split(",").join(""));
    const fomattedNum = parseFloat(sanitized).toFixed(2);
    return fomattedNum;
  } else return number;
};

export const verifyFileSize = (file) => {
  console.log("Size in MB!:", file.size / 1024 / 1024);
  const isLt5M = file.size / 1024 / 1024 < 5;
  if (!isLt5M) {
    toast.error("Image must smaller than 5MB!");
  }

  return isLt5M;
};

export const getMessage = (payload) => {
  console.log("Foreground Message:", payload);
};

export const sanitizeTitle = (word) => {
  if (word) {
    if (word.toString().charAt(word.length - 1) === "?") {
      word = word.substring(0, word.length - 1);
    }
    let newWord = word.trim().split(" ").join("-");
    newWord = newWord.replace(":", "-");
    return newWord;
  }
  return "";
};

export const formatTitle = (string) => {
  if (string) {
    const sentence = string.replace("_", " ").toLowerCase().split(" ");
    for (let i = 0; i < sentence.length; i++) {
      sentence[i] = sentence[i][0]?.toUpperCase() + sentence[i].slice(1);
    }
    return sentence.join(" ");
  }
  return "";
};

export const geoFindMe = () => {
  if (!navigator.geolocation) {
    console.log("Geolocation is not supported by your browser");
    return;
  }

  function success(position) {
    let { latitude, longitude } = position.coords;
    console.log("Location Lat & Lng", latitude, longitude);
    return reverseGeocodingWithGoogle(latitude, longitude);
  }

  function error() {
    console.log("Unable to retrieve your location");
  }

  navigator.geolocation.getCurrentPosition(success, error);
};

export const reverseGeocodingWithGoogle = async (latitude, longitude) => {
  return new Promise((resolve, reject) => {
    let url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.NEXT_PUBLIC_GOOGLE_MAP_API_KEY}`;
    return axios
      .get(url)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        console.log("Error generating location info:", err);
        reject(err);
      });
  });
};

export const getPageQueryParams = () => {
  if (typeof window !== "undefined") {
    return window.location.search
      .replace("?", "")
      .split("&")
      .reduce(
        (r, e) => (
          (r[e.split("=")[0]] = decodeURIComponent(e.split("=")[1])), r
        ),
        {}
      );
  } else return {};
};

export const validateEmail = (email) => {
  if (email) {
    var re =
      /^\s*(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/;
    return re.test(email);
  } else {
    return false;
  }
};

export const extractLast = (word) => {
  if (word) {
    let arrLen = [];
    arrLen = word?.split("/");
    return arrLen[arrLen.length - 1];
  }
  return "N/A";
};

export const getMonth = (val) => {
  let month;

  switch (parseInt(val, 10)) {
    case 1:
      month = "Jan";
      break;
    case 2:
      month = "Feb";
      break;
    case 3:
      month = "Mar";
      break;
    case 4:
      month = "Apr";
      break;
    case 5:
      month = "May";
      break;
    case 6:
      month = "Jun";
      break;
    case 7:
      month = "Jul";
      break;
    case 8:
      month = "Aug";
      break;
    case 9:
      month = "Sep";
      break;
    case 10:
      month = "Oct";
      break;
    case 11:
      month = "Nov";
      break;
    case 12:
      month = "Dec";
      break;

    default:
      month = val || "N/A";
      break;
  }

  return month;
};

export const updateCount = (n = 100, speed = 5000) => {
  const target = +n.attributes["data-target"];
  const count = +n.innerText;
  const inc = target / speed;

  console.log("count:", count);

  if (count > 0) {
    if (count < target) {
      n.innerText = Math.ceil(count + inc);
      setTimeout(updateCount, 1);
    } else {
      n.innerText = target;
    }
  }
};

export const downloadBase64 = (
  data,
  strFileName,
  strMimeType
) => {
  //download.js v3.0, by dandavis; 2008-2014. [CCBY2] see http://danml.com/download.html for tests/usage
  // v1 landed a FF+Chrome compat way of downloading strings to local un-named files, upgraded to use a hidden frame and optional mime
  // v2 added named files via a[download], msSaveBlob, IE (10+) support, and window.URL support for larger+faster saves than dataURLs
  // v3 added dataURL and Blob Input, bind-toggle arity, and legacy dataURL fallback was improved with force-download mime and base64 support

  if (!data) return false;
  
  // data can be a string, Blob, File, or dataURL
  var self = window, // this script is only for browsers anyway...
    u = "application/octet-stream", // this default mime also triggers iframe downloads
    m = strMimeType || u,
    x = data,
    D = document,
    a = D.createElement("a"),
    z = function (a) {
      return String(a);
    },
    B = self.Blob || self.MozBlob || self.WebKitBlob || z,
    BB = self.MSBlobBuilder || self.WebKitBlobBuilder || self.BlobBuilder,
    fn = strFileName || "download",
    blob,
    b,
    ua,
    fr;

  //if(typeof B.bind === 'function' ){ B=B.bind(self); }

  if (String(this) === "true") {
    //reverse arguments, allowing download.bind(true, "text/xml", "export.xml") to act as a callback
    x = [x, m];
    m = x[0];
    x = x[1];
  }

  //go ahead and download dataURLs right away
  if (String(x).match(/^data\:[\w+\-]+\/[\w+\-]+[,;]/)) {
    return navigator.msSaveBlob // IE10 can't do a[download], only Blobs:
      ? navigator.msSaveBlob(d2b(x), fn)
      : saver(x); // everyone else can save dataURLs un-processed
  } //end if dataURL passed?

  try {
    blob = x instanceof B ? x : new B([x], { type: m });
  } catch (y) {
    if (BB) {
      b = new BB();
      b.append([x]);
      blob = b.getBlob(m); // the blob
    }
  }

  function d2b(u) {
    var p = u.split(/[:;,]/),
      t = p[1],
      dec = p[2] == "base64" ? atob : decodeURIComponent,
      bin = dec(p.pop()),
      mx = bin.length,
      i = 0,
      uia = new Uint8Array(mx);

    for (i; i < mx; ++i) uia[i] = bin.charCodeAt(i);

    return new B([uia], { type: t });
  }

  function saver(url, winMode) {
    if ("download" in a) {
      //html5 A[download]
      a.href = url;
      a.setAttribute("download", fn);
      a.innerHTML = "downloading...";
      D.body.appendChild(a);
      setTimeout(function () {
        a.click();
        D.body.removeChild(a);
        if (winMode === true) {
          setTimeout(function () {
            self.URL.revokeObjectURL(a.href);
          }, 250);
        }
      }, 66);
      return true;
    }

    //do iframe dataURL download (old ch+FF):
    var f = D.createElement("iframe");
    D.body.appendChild(f);
    if (!winMode) {
      // force a mime that will download:
      url = "data:" + url.replace(/^data:([\w\/\-\+]+)/, u);
    }

    f.src = url;
    setTimeout(function () {
      D.body.removeChild(f);
    }, 333);
  } //end saver

  if (navigator.msSaveBlob) {
    // IE10+ : (has Blob, but not a[download] or URL)
    return navigator.msSaveBlob(blob, fn);
  }

  if (self.URL) {
    // simple fast and modern way using Blob and URL:
    saver(self.URL.createObjectURL(blob), true);
  } else {
    // handle non-Blob()+non-URL browsers:
    if (typeof blob === "string" || blob.constructor === z) {
      try {
        return saver("data:" + m + ";base64," + self.btoa(blob));
      } catch (y) {
        return saver("data:" + m + "," + encodeURIComponent(blob));
      }
    }

    // Blob but not URL:
    fr = new FileReader();
    fr.onload = function (e) {
      saver(this.result);
    };
    fr.readAsDataURL(blob);
  }
  return true;
};

const UtilsExports = {
  isEmpty,
  present,
  isEmptyString,
  verifyFileSize,
  getMessage,
  createQueryParams,
  numberWithCommas,
  formatAmount,
  sanitizeTitle,
  formatTitle,
  geoFindMe,
  reverseGeocodingWithGoogle,
  getPageQueryParams,
  validateEmail,
  extractLast,
  getMonth,
  updateCount,
  downloadBase64,
};

export default UtilsExports;
