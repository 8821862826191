import React from 'react';

// Images
import More from "../../asset/images/more.svg";

const MeetingInfo = ({date, district, title, venue}) => {
    return(
        <div className="w-full">
            <div className="bg-white m-3 p-3 rounded-lg">
                <div className="flex items-start justify-between">
                    <div className="w-full flex items-center text-sm">
                        <p className="text-blue-600 pr-2">{date}</p>

                        <div className="w-1 h-1 bg-blue-500 rounded-full"></div>

                        <p className="text-blue-600 flex items-center justify-center capitalize pl-2">
                            <span className="">{district}</span>
                        </p>
                    </div>
                    <div className="text-base pt-2">
                        <img src={More} className="cursor-pointer" alt="" />
                    </div>
                </div>

                <div className="pt-3">
                    <p className="text-lg font-medium">{title}</p>
                    <p className="text-gray-500">{venue}</p>
                </div>
            </div>
        </div> 
    );
};

const UpComingMeetings = ({ 
    meetings = []
}) => {

    return (
        <div>
            {meetings.length > 0 ?
                meetings.map((item) => (
                    <MeetingInfo
                        date = {new Date(item.proposed_date)?.toDateString()}
                        district = {item.district}
                        title = {item.type_of_meeting}
                        venue = {item.venue}
                    />
                ))
            :
                <div className="w-full p-3 text-primary text-lg">No Upcoming Meetings</div>
            }
        </div>
    )
}

export default UpComingMeetings