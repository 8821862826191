import api from "../../services/config";
import * as types from './types';

export const getRoadInfrastructure = (page=1, limit=10, search="") => async (dispatch) => {
    return new Promise((resolve, reject) => {
        let url = `/road_infrastructures/`;
        if (page) url += `?page=${page}`;
        if (limit) url += `&limit=${limit}`;
        if (search) url += `&search=${search}`

        return api.get(url)
        .then((response) => {
            console.log('Response', response.data);

            dispatch({
                type: types.ROAD_INFRASTRUCTURE,
                payload: response.data
            })

            resolve(response);
        })
        .catch((error) => {
            reject(error);
        })
    });
};

export const getMarketInfrastructure = (page=1, limit=10, search="") => async (dispatch) => {
    let url = `/market_infrastructure/`;
    if (page) url += `?page=${page}`;
    if (limit) url += `&limit=${limit}`;
    if (search) url += `&search=${search}`

    return new Promise((resolve, reject) => {
        return api.get(url)
        .then((response) => {
            console.log('Response', response.data);

            dispatch({
                type: types.MARKET_INFRASTRUCTURE,
                payload: response.data 
            })

            resolve(response);
        })
        .catch((error) => {
            reject(error);
        })
    });
};

export const getSchoolsInfrastructure = (page=1, limit=10, search="") => async (dispatch) => {
    return new Promise((resolve, reject) => {
        let url = `/school_infrastructures/`;
        if (page) url += `?page=${page}`;
        if (limit) url += `&limit=${limit}`;
        if (search) url += `&search=${search}`

        return api.get(url)
        .then((response) => {
            console.log('Response', response.data);

            dispatch({
                type: types.BASIC_SCHOOLS,
                payload: response.data 
            })

            resolve(response);
        })
        .catch((error) => {
            reject(error);
        })
    });
};

export const getHealthInfrastructure = (page=1, limit=10, search="") => async (dispatch) => {
    return new Promise((resolve, reject) => {
        let url = `/health_infrastructures/`;
        if (page) url += `?page=${page}`;
        if (limit) url += `&limit=${limit}`;
        if (search) url += `&search=${search}`

        return api.get(url)
        .then((response) => {
            console.log('Response', response.data);

            dispatch({
                type: types.HEALTH_FACILITY,
                payload: response.data 
            })

            resolve(response);
        })
        .catch((error) => {
            reject(error);
        })
    });
};

export const getOthersInfrastructure = (page=1, limit=10, search="") => async (dispatch) => {
    return new Promise((resolve, reject) => {
        let url = `/other_infrastructure/`;
        if (page) url += `?page=${page}`;
        if (limit) url += `&limit=${limit}`;
        if (search) url += `&search=${search}`

        return api.get(url)
        .then((response) => {
            console.log('Response', response.data);

            dispatch({
                type: types.OTHER_INFRUSTRUCTURE,
                payload: response.data 
            })

            resolve(response);
        })
        .catch((error) => {
            reject(error);
        })
    });
};

export const getInfrastructureById = (id) => async (dispatch) => {
    return new Promise((resolve, reject) => {

        return api.get(`/all_profiles/districts/${id}/district_infrastructure/`)
        .then((response) => {
            dispatch({
                type: types.SET_INFRASTRUCTURE,
                payload: response.data
            })
            resolve(response);
        })
        .catch((error) => {
            reject(error);
        })
    });
};