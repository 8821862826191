import api from "../../services/config";
import * as types from './types';

export const getEconomicData = (id, page=1, limit=10, search="") => async (dispatch) => {
    return new Promise((resolve, reject) => {
        let url = '/economicdata/';
        if (id) url += `${id}/`;
        if (page) url += `?page=${page}`;
        if (limit) url += `&limit=${limit}`;  
        if (search) url += `&search=${search}`;    

        return api.get(url)
        .then((response) => {
            let data;

            if (id) {
                data = { 
                    count: response?.data?.length || 0,
                    limit: 10,
                    meta: null,
                    next: null,
                    pages: 1,
                    previous: null,              
                    results: Array.isArray(response?.data?.results) ? response?.data?.results : []
                }
            }
            else {
                data = response.data
            }

            dispatch({
                type: types.ECONOMIC_DATA,
                payload: data
            })

            resolve(response);
        })
        .catch((error) => {
            reject(error);
        })
    });
};

export const getDetails = (id, page=1, limit=10) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        let url = `/economicdata/${id}/`;
        if (page) url += `?page=${page}`;
        if (limit) url += `&limit=${limit}`;      

        return api.get(url)
        .then((response) => {
            dispatch({
                type: types.ECONOMIC_BY_TYPE,
                payload: response.data
            })

            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
};
