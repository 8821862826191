import api from "../../services/config";
import * as types from './types';

export const getSpecialInitiatives = (page=1, limit=10) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        let url = `/special_initiatives/`;
        if (page) url += `?page=${page}`;
        if (limit) url += `&limit=${limit}`;

        return api.get(url)
        .then((response) => {
            console.log('Response', response.data);

            dispatch({
                type: types.SPECIAL_INITIATIVES,
                payload: response.data
            })

            resolve(response);
        })
        .catch((error) => {
            reject(error);
        })
    });
};

export const getDetails = (id, page=1, limit=10) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        let url = `/special_initiatives/${id}/`;
        if (page) url += `?page=${page}`;
        if (limit) url += `&limit=${limit}`;      

        return api.get(url)
        .then((response) => {
            dispatch({
                type: types.SPECIALINITIATIVE_BY_TYPE,
                payload: response.data
            })

            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
};


export const getSpecialInitiativesById = (id) => async (dispatch) => {
    return new Promise((resolve, reject) => {

        return api.get(`/all_profiles/districts/${id}/special_initiatives/`)
        .then((response) => {
            dispatch({
                type: types.SET_SPECIAL_INITIATIVES,
                payload: response.data
            })
            resolve(response);
        })
        .catch((error) => {
            reject(error);
        })
    });
};