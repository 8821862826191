import React from "react";
// Images
import goBack from "../../asset/images/arrow-left.svg";

const DetailsPage = ({
  children,
  setviewDetails=()=>{}
}) => {
  return (
    <section>
      <div
        className="flex items-center pb-4 pt-2 cursor-pointer"
        onClick={() => setviewDetails(false)}
      >
        <img src={goBack} alt="" />
        <div className="pl-2">Go back</div>
      </div>

      {children}
    </section>
  );
};

export default DetailsPage;
