import React from "react";
import AuthProvider from "./services/auth/context";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

// -- Component Styles
import "./styles/app.scss";

// Vendor Styles
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-toastify/dist/ReactToastify.css";

// Pages
import Profile from './pages/Profile'
import DeliberativeFunction from './pages/DeliberativeFunction';
import Administrative from './pages/Administrative';
import EconomicData from './pages/EconomicData';
import Districts from './pages/Districts';
import Regions from './pages/Regions';
import RegionDistricts from './pages/RegionDistricts';
import ViewDistrict from './pages/ViewDistrict';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import Fiscal from './pages/Fiscal';
import FiscalDistrict from './pages/FiscalDistricts';
import FiscalDistrictsCash from './pages/FiscalDistrictsCash';
import FiscalDistrictsProcurement from './pages/FiscalDistrictsProcurement';
import FiscalDistrictsProjNContract from './pages/FiscalDistrictsProjNContract';
import SocialService from './pages/SocialService';
import Environment from './pages/Environment';
import PoliticalIssues from './pages/PoliticalIssues';
import SpecialInitiative from './pages/SpecialInitiatives';
import Infrastructure from './pages/Infrastructure';
import OtherIssues from './pages/OtherIssues';

const App = () => {
  return (
    <div className="App">
      <ToastContainer />
      <AuthProvider>
        <Router>
          <Routes>
            <Route exact path="/"                                 element={ <Login /> } />
            <Route exact path="/profile"                          element={<Profile />} /> 
            <Route exact path="/profile/districts"                element={<Districts />} /> 
            <Route exact path="/profile/districts/:id"            element={<ViewDistrict />} /> 
            <Route exact path="/profile/regions"                  element={<Regions />} /> 
            <Route exact path="/profile/region/:slug"             element={<RegionDistricts />} /> 
            <Route exact path="/deliberative-function"            element={<DeliberativeFunction />} />
            <Route exact path="/administrative"                   element={<Administrative />} /> 
            <Route exact path="/economic-data"                    element={<EconomicData />} /> 
            <Route exact path="/fiscal"                           element={<Fiscal />} /> 
            <Route exact path="/fiscal/districts-wto-reports"     element={<FiscalDistrict />} /> 
            <Route exact path="/fiscal/districts-wt-cash-mgmt"    element={<FiscalDistrictsCash />} /> 
            <Route exact path="/fiscal/districts-wt-procurement"  element={<FiscalDistrictsProcurement />} /> 
            <Route exact path="/fiscal/districts-wt-proj-and-contract"  element={<FiscalDistrictsProjNContract />} /> 
            <Route exact path="/social-services"                  element={<SocialService />} /> 
            <Route exact path="/environment"                      element={<Environment />} /> 
            <Route exact path="/political-issues"                 element={<PoliticalIssues />} /> 
            <Route exact path="/special-initiatives"              element={<SpecialInitiative />} /> 
            <Route exact path="/infrastructure"                   element={<Infrastructure />} /> 
            <Route exact path="/other-issues"                     element={<OtherIssues />} /> 
            <Route element={NotFound} />
          </Routes>
        </Router>
      </AuthProvider>
    </div>
  );
}

export default App;
