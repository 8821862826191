import React, { useState, useEffect } from "react";
import ProfileTable from "./Tables/ProfileTable";
import { useSelector } from "react-redux";

const tableHeading = {
  stations: "No. Of Stations",
  personnel: "No. Of Personnel",
  reported_case: "No. of Reported Cases",
  no_of_fire_tenders: "No. Of Fire Tenders",
  no_of_bushfires: "No. Of Bushfires",
  no_of_household_fires: "No. Of Household Fires",
};

const FireService = () => {
  const { security } = useSelector((state) => state.socialServices);
  const list = security.list_tabs;

  const [profile, setprofile] = useState({});
  const [data, setdata] = useState([]);

  const SetProfile = (profile) => {
    setprofile(profile);
  };

  const TableDetailsInfo = () => (
    <section className="text-lg">
      <div className="w-full flex items-start justify-start pb-3">
        <div className="w-1/2">Number Of Stations</div>
        <div className="text-gray-1 w-1/2 text-end">{profile.stations}</div>
      </div>
      <div className="w-full flex items-start justify-start pb-3">
        <div className="w-1/2">Number Of Personnel</div>
        <div className="text-gray-1 w-1/2 text-end">{profile.personnel}</div>
      </div>
      <div className="w-full flex items-start justify-start pb-3">
        <div className="w-1/2">Number Of Tenders</div>
        <div className="text-gray-1 w-1/2 text-end">{profile.no_of_fire_tenders}</div>
      </div>
      <div className="w-full flex items-start justify-start pb-3">
        <div className="w-1/2">Number Of Reported Cases</div>
        <div className="text-gray-1 w-1/2 text-end">{profile.reported_case}</div>
      </div>
      <div className="w-full flex items-start justify-start pb-3">
        <div className="w-1/2">Number Of Bushfires</div>
        <div className="text-gray-1 w-1/2 text-end">{profile.no_of_bushfires}</div>
      </div>
      <div className="w-full flex items-start justify-start pb-3">
        <div className="w-1/2">Number of Household</div>
        <div className="text-gray-1 w-1/2 text-end">{profile.no_of_household_fires}</div>
      </div>
    </section>
  );

  useEffect(() => {
    let tepmArray = list?.fire_service?.map((el) => {
      return {
        id: el.id,
        stations: el?.no_of_stations,
        personnel: el?.no_of_personnels,
        reported_case: el?.no_of_reported_cases,
        no_of_fire_tenders: el?.no_of_fire_tenders,
        no_of_bushfires: el?.no_of_bushfires,
        no_of_household_fires: el?.no_of_household_fires,
      };
    });
    setdata(tepmArray);
  }, [list]);

  return (
    <div>
      <ProfileTable
        tableHeading={tableHeading}
        list={data}
        SetProfile={SetProfile}
      >
        <TableDetailsInfo />
      </ProfileTable>
    </div>
  );
};

export default FireService;
