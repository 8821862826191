import React, { useState, useEffect } from "react";
import utils from "../../utils";

// Components
import ProfileTable from "../../component/Tables/ProfileTable";
import Widget from "../DashbordWidget";
import DetailsPage from "./DetailsPage";
import Tooltip from './Tooltip';
import RegionWidget from "./RegionWidget";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { getExpenditure, getExpenditureChart } from "../../redux/fiscal/action";

const meta_colors = ["#7A6EFE", "#FF5363", "#FFA901"];

const Expenditure = () => {
  const dispatch = useDispatch()
  const { expenditure } = useSelector(state=>state.fiscal);
  let { pagination, chart } = expenditure;

  const [tableMeta, setTableMeta] = useState({
    currentPage: 1,
    totalPages: 1,
    total: 0,
    limit: 10    
  });  
  const [info, setinfo] = useState({});
  const [viewDetails, setviewDetails] = useState(false);
  const [loading, setloading] = useState(false);
  const [data, setdata] = useState([]);
  const [total, settotal] = useState("");
  const [regions, setregions] = useState([]);  

  const tableHeading =  {
    name: "District",
    projected: "Projected Expenditure",
    actual: "Actual Expenditure",
    tracking: "Expenditure TRACKING",
    variance:"Expenditure Variance",
  };  

  const dataItems = [
    {
      id: 1,
      number: expenditure?.meta?.total_expenditure?.amount,
      link: "",
      name: expenditure?.meta?.total_expenditure?.name,
      bck: "#7A6EFE",
      handleClick: () => { 
        settotal(expenditure?.meta?.total_expenditure?.amount);
        setviewDetails(true);
      }        
    },
    {
      id: 2,
      number: expenditure?.meta?.highest?.amount ,
      link: "",
      name: expenditure?.meta?.highest?.name,
      bck: "#FF5363",
      handleClick: ()=>{}
    },
    {
      id: 3,
      number: expenditure?.meta?.lowest?.amount ,
      link: "",
      name: expenditure?.meta?.lowest?.name,
      bck: "#FFA901",
      handleClick: ()=>{}
    },
    {
      id: 4,
      number: expenditure?.meta?.average?.amount ,
      link: "",
      name: expenditure?.meta?.average?.name,
      bck: "#24A8FA",
      handleClick: ()=>{}
    },
  ];  

  const Setinfo = (info) => {
    const updatedExpend = expenditure?.list?.find((el)=> el.id === info?.id)
    setinfo(updatedExpend);
  };   

  const fetchExpenditure = (page, limit, search="") => {
    setloading(true);
    dispatch(getExpenditure(page, limit, search))
    .then(() => {
      setloading(false);
      dispatch(getExpenditureChart())
    }, () => {
      setloading(false);
    })
  };

  const handleExpenditureFetch = (type, value) => {
    switch (type) {
      case 'limit':
        setTableMeta((prev) => { return { ...prev, currentPage: 1, limit: value } });
        fetchExpenditure(1, value);
      break;

      case 'currentPage':
        setTableMeta((prev) => { return {  ...prev, currentPage: value }});        
        fetchExpenditure(value, tableMeta.limit);
      break;
    
      default:
        break;
    }
  };

  // Page components
  const TableDetailsInfo = () => (
    <section className="text-lg">
      <div className="w-full flex items-center justify-start pb-1">
        <div className="w-1/2">District</div>
        <div className="text-gray-1">{info.district}</div>
      </div>
      <div className="w-full flex items-center justify-start pb-1">
        <div className="w-1/2">Projected Expenditure</div>
        <div className="text-gray-1">{utils.numberWithCommas(info.projected_quarterly_expenditure)}</div>
      </div>
      <div className="w-full flex items-center justify-start pb-1">
        <div className="w-1/2">Actual Expenditure</div>
        <div className="text-gray-1">{utils.numberWithCommas(info.actual_expenditure)}</div>
      </div>
      <div className="w-full flex items-center justify-start pb-1">
        <div className="w-1/2">Expenditure Tracking</div>
        <div className="text-gray-1">{info.reasons_for_variance}</div>
      </div>
      <div className="w-full flex items-center justify-start pb-1">
        <div className="w-1/2">Expenditure Variance</div>
        <div className="text-gray-1">{utils.numberWithCommas(info.expenditure_variance)}</div>
      </div>
    </section>
  );

  const Details = () => (
    <section className="bg-white p-4 mb-4 rounded-lg">
      <div className="w-full flex items-center justify-center pt-2">
        <div className="w-1/2 sm:w-1/4">
          <Tooltip 
            data={regions} 
            active
          />
        </div>
      </div>

      <div className="pt-5">
        <div className="text-center pb-4">
          <div className="font-bold text-black text-3xl text-center mb-2">
            {total}
          </div>
          <p>Total Revenue/ Region</p>
        </div>

        <div className="w-full flex justify-center items-center">
          <div className="w-full sm:w-4/5 flex flex-wrap">
            {regions.map((el) => (
              <div className="w-full sm:w-1/3 flex justify-center items-start mb-4">
                <div className="w-10/12 flex items-start">
                  <RegionWidget
                    color={el.color}
                    name={el.name}
                    amount={el.amount}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>    
  );  

  useEffect(() => {
    let tepmArray = expenditure?.list?.map((el) => {
      return  {
        id: el.id,
        name: el.district,
        projected: utils.numberWithCommas(el.projected_quarterly_expenditure),
        actual: utils.numberWithCommas(el.actual_expenditure),
        tracking: el.reasons_for_variance,
        variance: utils.numberWithCommas(el.expenditure_variance)
      };
    });

    setdata(tepmArray);

    // if revenue.pagination is available
    if (utils.present(expenditure?.pagination)) {
      setTableMeta({
        ...tableMeta,
        total: pagination?.count,
        totalPages: pagination.totalPages,
        // limit: pagination.limit
      });
    }
  },[expenditure]); 

  useEffect(() => {
    const tempRegions = chart.map((el) => {
      return {
        title: el?.region,
        name: el?.region,
        amount: el?.revenue,
        value: chart?.filter((i) => i.region === el.region)?.length,
        color: meta_colors[Math.floor(Math.random() * (2 - 0 + 1)) + 0]        
      }
    });

    setregions(tempRegions);
  }, [chart]);  

  useEffect(()=>{
    fetchExpenditure();
  }, []);

  return (
    <div>
      {viewDetails ? (
        <DetailsPage setviewDetails={setviewDetails}>
          <Details />
        </DetailsPage>
      ) : (
        <div>
          <div className="flex flex-row overflow-y-hidden">
            {dataItems.map((item) => (
              <div className="w-1/2 sm:w-auto" style={{ minWidth: '18rem'}}>
                <Widget
                  id={item.id}
                  link={item.link}
                  number={item.number}
                  name={item.name}
                  bck={item.bck}
                  lastItem={dataItems.length}
                  widgetFont="text-2xl"
                  handleClick={item.handleClick}
                />
              </div>
            ))}
          </div>

          <ProfileTable
            title="Expenditure"
            tableHeading={tableHeading}
            SetProfile={Setinfo}
            list={data}
            hasFilter
            showSort
            hasPagination
            entity="Expenditure"
            loading={loading}
            meta={tableMeta}   
            fetchList={handleExpenditureFetch} 
            handleSearch={(e) => fetchExpenditure(1, 10, e)}        
          >
            <TableDetailsInfo />
          </ProfileTable>
        </div>
      )}
    </div>
  );
};

export default Expenditure;
