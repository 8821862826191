import * as types from './types';

const initState = {
    user: {},
    users: []
};

export const user = (state = initState, action) => {
    switch (action.type) {
        case types.SET_USER:
            const updatedUser = { ...state.user, ...action.payload }

            return {
                ...state,
                user: updatedUser
            }

            // Handle switch case for SET_USERS
            case types.SET_USERS:
                console.log('Users in payload',action.payload)
                const allUsers = [ ...state.users, ...action.payload]

                return {
                    ...state,   
                    users: allUsers
                }

        default:
            return state;
    }
}
