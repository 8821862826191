import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Button, Table } from "reactstrap";
import { Transition, CSSTransition, SwitchTransition, TransitionGroup } from "react-transition-group";
import "./ProfileTable.scss";

// Components
import TableFilter from "./TableFilter";
import FilterModal from "../FilterModal";
import TableDetails from "../Profile/ProfileInfo";
import TablePagination from "../Tables/Pagination";

// Images
import emptyState from "../../asset/images/empty_table.jpg";

// Constant variables
const duration = 300;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0.5,
};

const transitionStyles = {
  entering: { opacity: 0.5 },
  entered: { opacity: 1 },
  exiting: { opacity: 1 },
  exited: { opacity: 0.5 },
};

const ProfileTable = ({
  children,
  otherInfo,
  title = "",
  entity = "",
  placeholder = "Search",
  filterWidth = "w-full", // w-1/2
  tableHeading = {},
  meta = {},
  list = null,
  handleViewAction = () => {},
  SetProfile = () => {},
  fetchList = () => {},
  handleSearch = () => {},
  callToAction,
  showModal = true,
  showDivider = false,
  showSort = false,
  showTitle=true,
  hasFilter = false,
  addNumbering = false,
  hasPagination = false,
  loading = false
}) => {
  const location = useLocation();
  const [inProp, setInProp] = useState(false);
  const [showFilter, setshowFilter] = useState(false);
  const [showDetails, setshowDetails] = useState(false);

  const handleViewDetails = (info) => {
    SetProfile(info);

    if (showModal) setshowDetails(true);
    else handleViewAction(info);
  };

  const handleCloseModal = () => {
    setshowFilter(false);
  };

  const handleCloseDetails = () => {
    setshowDetails(false);
  };

  useEffect(() => {
    setInProp(true)

    return () => {
      setInProp(false)
    }
  }, [location]); 

  return (
    <section className="shadow-sm rounded-lg bg-white my-4">
      <Transition in={inProp} timeout={300}>
        {(state) => ( 
          <>
            <div
              className={`${title && 'w-full flex flex-row justify-between pb-4 px-6 py-4'} ${showDivider && "border-b border-gray-200"}`}
            >
              {/* <div className={`${callToAction && "w-full"} flex justify-between items-center`}> */}
              <div className={`${callToAction && "w-full"} ${showTitle ? "visible":"invisible"} flex justify-between items-center`}>
                <h1 className="text-2xl font-semibold mb-0">{title}</h1>
                {callToAction}
              </div>

              {hasFilter && (
                <div className="">
                  <TableFilter
                    showFilter={setshowFilter}
                    showExport
                    showSort={showSort}
                    parentSearchStyle={`${filterWidth} bg-light border border-gray-100 rounded-lg`}
                    placeholder={placeholder}
                    handleSearch={(e) => handleSearch(e)} 
                  />
                </div>
              )}
            </div>

            <div className="tables relative px-6 py-4 overflow-x-auto" style={{ ...defaultStyle, ...transitionStyles[state] }} >
              <Table hover>
                <thead>
                  <tr>
                    {addNumbering}
                    {Object.keys(tableHeading).map((oneKey, i) => {
                      return (
                        <th
                          className={`text-gray-1 ${tableHeading.style || ""}`}
                          key={i}
                        >
                          {tableHeading[oneKey] || "N/A"}
                        </th>
                      );
                    })}
                  </tr>
                </thead>

                <tbody className="border-t border-gray-100 transition-all ease-in duration-700">
                  {list &&
                    list?.length > 0 &&
                    list.map((el, index) => (
                      <tr
                        className="cursor-pointer w-100 capitalize"
                        onClick={() => { handleViewDetails(el) }}
                        key={index}
                      >
                        {Object.keys(el).map((oneKey, i) => {
                          return oneKey === "name" ? (
                            <td className="break-words" key={i}>
                              {addNumbering && (<span className="pr-1">{el["id"] || index + 1}. </span>)} 
                              <span>{el[oneKey] || "N/A"}</span>
                            </td>
                          ) : oneKey === "action" ? (
                            <td className="pr-0">
                              <div className="flex justify-end break-words">
                                <Button
                                  color="primary"
                                  className="text-white px-4 py-2"
                                  onClick={() => handleViewAction(el)}
                                  size="sm"
                                >
                                  {el[oneKey]}
                                </Button>
                              </div>
                            </td>
                          ) : (
                            <td className="break-words" hidden={oneKey === 'id'? true:false}>{(el[oneKey] || el[oneKey] === 0 ) ? el[oneKey] : "N/A"}</td>
                          );
                        })}
                      </tr>
                    ))
                  }
                </tbody>
              </Table>

              {loading ? 
                <div className="h-full w-full absolute inset-0 bg-primary bg-opacity-25 rounded-b-lg transition-all ease-in duration-700 flex justify-center items-center">
                  <svg className="animate-spin h-10 w-10 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>                 
                </div>
              :
                list && list?.length === 0 &&
                  <div className="w-full">
                    <img src={emptyState} className="mx-auto" alt="" />
                    <div className="text-center text-lg pb-4">No Data Available</div>
                  </div>
              }

              {hasPagination && 
                <TablePagination 
                  entity={entity} 
                  meta={meta} 
                  triggerRequest={fetchList}
                />
              }
            </div>

            {otherInfo && otherInfo}

            {showFilter && (
              <FilterModal showModal={showFilter} handleClose={handleCloseModal} />
            )}

            {showDetails && (
              <TableDetails
                title={title}
                showModal={showDetails}
                handleClose={handleCloseDetails}
              >
                {children}
              </TableDetails>
            )}
          </>
          )}
      </Transition>
    </section>
  );
};

export default ProfileTable;
