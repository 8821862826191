import React, { useState, useEffect } from "react";
import Itenerary from "../Iteneraries";
import CapacityBuilding from "../Administrative/CapacityBuilding";
import Complaint from "../Complaint";
import ProfileTable from "../../component/Tables/ProfileTable";
import utils from "../../utils";

import plane from "../../asset/images/ic24-plane.svg";

import { useDispatch, useSelector } from "react-redux";
import { getAdministrativeById } from "../../redux/administrative/action";

function AdministrativeTab({ id }) {
  const dispatch = useDispatch();
  const { staffingData, iteneraries } = useSelector(state => state.administrative);

  // const [tableMeta, setTableMeta] = useState({
  //   currentPage: 1,
  //   totalPages: 1,
  //   total: 0,
  //   limit: 10    
  // });
  const [admin, setAdmin] = useState({});
  const [current, setcurrent] = useState(1);
  const [listStaffing, setListStaffing] = useState([]);
  const [data, setdata] = useState([]);
  const [months, setmonths] = useState([]);
  
  const tableHeading = {
    gog_staff: "No of GOG Staff",
    igf_staff: "No Of IGF Staff",
    // areas_of_critical_staff_needs: "Areas Of Critical Staff Needs",
    // engineers: "Engineers",
    // environmental_and_health_sanitation_officers: "Sanitation Officers",
    total:  "Total Staff",
    areas: "Areas Of Critical Staff Needs",
  };
  
  const handleViewAction = (val) => {
    // Find district info from val passed
  };

  const SetProfile = (admin) => {
    setAdmin(admin);
  };

  const TableDetailsInfo = () => (
    <section className="text-lg">
      <div className="w-full flex items-start justify-start pb-3">
        <div className="w-1/2">No of GOG Staff </div>
        <div className="text-gray-1 w-1/2">{admin.gog_staff}</div>
      </div>
      <div className="w-full flex items-start justify-start pb-3">
        <div className="w-1/2">No Of IGF Staff</div>
        <div className="text-gray-1 w-1/2">{admin.igf_staff}</div>
      </div>
      <div className="w-full flex items-start justify-start pb-3">
        <div className="w-1/2">Total Staff</div>
        <div className="text-gray-1 w-1/2">{admin.total}</div> 
      </div>
      <div className="w-full pt-2 pb-3">
        <div className="">Management Information Systems Officers</div>
        <div className="text-gray-1">{admin.management_information_systems_officers || 'N/A'}</div> 
      </div>
      <div className="w-full pb-1">
        <div className="">Areas Of Critical Staff Needs</div>
        <div className="text-gray-1">{admin.areas || 'N/A'}</div>
      </div>
    </section>
  );

  useEffect(() => {
    let tepmArray = staffingData?.list?.map((el) => {
      return  {
        // name: el.district,
        gog_staff: el.no_of_gog_staff,
        igf_staff: el.no_of_igf_staff,
        // areas_of_critical_staff_needs: el.areas_of_critical_staff_needs,
        // environmental_and_health_sanitation_officers: el.environmental_and_health_sanitation_officers,
        // engineers: el.engineers,
        total: el.total,
        areas: el.areas_of_critical_staff_needs
      };
    });

    setListStaffing(tepmArray);

  
  },[staffingData]);  
  
  useEffect(() => {
    if (utils.present(iteneraries)) {
      let tepmArray = iteneraries?.list?.map((el) => {
        return  {
          name: el.name_of_official,
          title: el.district,
          label: el.travel_outside,
          start: el.duration_start,
          end: el.duration_end,
          icon: plane,
        };
      });
  
      setdata(tepmArray);
    };

    // Set months
    if (utils.present(iteneraries?.months)) {
      let tempMonths = Object.keys(iteneraries?.months).map((el, index) => {
        return {
          id: index+1,
          name: iteneraries?.months[el]
        }
      })

      setmonths(tempMonths);
    }
  }, [iteneraries]);  

  useEffect(() => {
    dispatch(getAdministrativeById(id))
  }, [id]);

  return (
    <div>
      <ProfileTable
        title="Staffing Information"
        tableHeading={tableHeading}
        list={listStaffing}
        showDivider
        handleViewAction={handleViewAction}
        SetProfile={SetProfile}
        hasFilter={false}
      >
        <TableDetailsInfo />
      </ProfileTable>

      <div className="bg-white rounded-lg mb-5">
        <div className="px-6 pt-4 pb-2">
          <h1 className="text-2xl font-semibold mb-0">Iteneraries</h1>
        </div>

        <hr />

        <div className="pt-2 pb-4">
          <div
            className="flex items-center w-2/3 overflow-y-hidden pb-2 px-6"
            id="showOnHover"
          >
            {months?.map((el, index) => (
              <button
                className={`${current === el.id ? "bg-black text-white" : "bg-gray-200 text-gray-deep"} border-none rounded px-4 py-2 mr-2`}
                onClick={() => setcurrent(el.id)}
                key={index}
              >
                {el?.name}
              </button>
            ))}
          </div>

          <Itenerary
            showTitle={false}
            itemsStyling="w-full sm:w-1/4"
            itemsParent="p-3"
            // dataItems={data?.filter(el => iteneraries?.months[`month_${current}`] === new Date(el.start).toLocaleString('default', { month: 'long' }) )}
            dataItems={data}
            sub_title="text-sm"
          />
        </div>
      </div>

      <div className="mb-5">
        <CapacityBuilding id={id}/>
      </div>

      <div>
        <Complaint />
      </div>
    </div>
  );
}

export default AdministrativeTab;
