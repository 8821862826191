import * as types from './types';

const initState = {
    otherissues: []
};

export const otherIssuesOfInterest = (state = initState, action) => {
    switch (action.type) {
        case types.OTHER_ISSUES_OF_INTEREST:
            console.log('other issues payload:', action.payload)
            return {
                ...state,
                otherissues: action.payload 
            }

        default:
            return state;
    }
}
