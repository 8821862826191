import React, { useState } from "react";

const Card = ({ date, label, text, view, item, setInfo=() => {} }) => {
  const [more, setmore] = useState(false);

  return (
    <div className="w-full sm:w-1/4 mb-4">
      <div 
        className="flex flex-col justify-between ml-2 mr-2 bg-white border h-full border-gray-300 rounded-lg mt-2 p-3"
        style={{ border: "0.990698px solid rgba(0, 13, 31, 0.05)" }}
      >
        <div className="h-40 relative overflow-hidden trucate">{/* ${more ? 'h-auto' : 'h-40'}  */}
          <div className="text-blue-600 text-sm mb-2">{date}</div>
          <p className="text-lg font-normal truncate mb-2">{label}</p>
          <div className="text-base text-gray-600 capitalize overflow-hidden">
            {text}
            {/* <span onClick={() => setmore(!more)} className="cursor-pointer bg-white absolute z-10 right-0 bottom-0">...<span className="text-primary">READ {more ? 'LESS' : 'MORE'}</span></span> */}
          </div>
        </div>

        <button
          className="w-full text-white bg-blue-500 px-2 py-2 text-center mt-2"
          style={{ borderRadius: "4px" }}
          onClick={()=> {setInfo(item)}}
        >
          {view}
        </button>
      </div>
    </div>
  );
};

export default Card;
