import React, { useEffect } from "react";

// Components
import ParksAndGardens from "../ParksAndGardens";
import Pollution from "../Pollution";
import SolidWaste from "../SolidWaste";
import LiquidWaste from "../LiquidWaste";

// Redux
import { useDispatch } from "react-redux";
import { getEnvironmentById } from "../../redux/EnvironmentAndSanitation/action";

const Environment = ({ id }) => {
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(getEnvironmentById(id));
  }, [id]);

  return (
    <div>
      <ParksAndGardens />
      <Pollution />
      <SolidWaste />
      <LiquidWaste />
    </div>
  );
};

export default Environment;
