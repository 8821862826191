import React, {useState, useEffect} from 'react'
import ProfileTable from './Tables/ProfileTable';
import { useSelector } from 'react-redux';


const tableHeading = {
  reported: "Reported Cases of Environmental Pollution",
  remedy: "Remedial Action Taken", 
};


 
const Pollution = () => {
  const { pollution } = useSelector((state) => state.environmentAndSanitation);

  const [profile, setprofile] = useState({});
  const [data, setdata] = useState([])

  const SetProfile = (profile) => {
    setprofile(profile);
  };


  const TableDetailsInfo = () => (
    <section className="text-lg">
    <div className="w-full flex items-start justify-start pb-3">
      <div className="w-1/2">Reported Cases of Environmental Pollution</div>
      <div className="text-gray-1 w-1/2">{profile.reported}</div>
    </div>
    <div className="w-full flex items-start justify-start pb-1">
      <div className="w-1/2">Remedial Action Taken</div>
      <div className="text-gray-1 w-1/2">{profile.remedy}</div>
    </div>
  </section>
  );

  useEffect(() => {
    let tepmArray = pollution?.list?.map((el) => {
      return  {
        id: el.id,
        reported: el.reported_environmental_pollution_cases,
        remedy: el.remedy
      };
    });
    setdata(tepmArray);

  },[pollution]);
  
  


  return (
    <div>
        <ProfileTable title='Pollution' tableHeading={tableHeading} showDivider list={data} SetProfile={SetProfile} >
          <TableDetailsInfo/>
        </ProfileTable>
    </div>
  )
}

export default Pollution