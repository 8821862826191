import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { PieChart } from 'react-minimal-pie-chart';
import RegionWidget from './RegionWidget';

function makeTooltipContent(entry) {
  return <div className="flex items-start rounded">
    <RegionWidget color={entry.color} name={entry.name} amount={entry.amount} />
  </div>;
};

function ToolTip(props) {
  const [hovered, setHovered] = useState(null);
  const [selected, setSelected] = useState(props.active);

  const data = props.data.map(({ title, ...entry }) => {
    return { ...entry, tooltip: title };
  });

  return (
    <div data-tip="" data-for="chart">
      <PieChart
        data={data}
        radius={PieChart.defaultProps.radius - 2}
        segmentsStyle={{ transition: 'stroke .3s', cursor: 'pointer' }}
        segmentsShift={(index) => (index === selected ? 2 : 0)}
        onClick={(event, index) => {
          setSelected(index === selected ? undefined : index);

          // Do any api calls here on pie-chart click
        }}
        onMouseOver={(_, index) => {
          setHovered(index);
        }}
        onMouseOut={() => {
          setHovered(null);
        }}
        animate
      />

      <ReactTooltip
        id="chart"
        type="light"
        effect="float"
        getContent={() => typeof hovered === 'number' ? makeTooltipContent(data[hovered]) : null}
      />
    </div>
  );
}

export default ToolTip;