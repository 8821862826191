import React, { useState, useEffect } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

// Components
// import FancyTab from "../Tabs/FancyTab";
import GeneralMeeting from "../GeneralMeeting";
import ModalInfo from "../Profile/ProfileInfo";
// import MyCalender from "../Calender/ToastUI";
import MyCalender from "../Calender/BigCalender";
// import MyCalender from "../Calender/ModernCalender";

// Constants
const navLinks = [
  {
    id: 1,
    name: "Schedule Meeting",
  },
  {
    id: 2,
    name: "Meeting Held",
  }
];

const ScheduledMeeting = ({
  upcomingMeetings=[],
  meetingsHeld=[]
}) => {
  const [active, setactive] = useState(1);
  const [show, setshow] = useState(false);
  const [details, setDetails] = useState();

  const SetInfo = (info) => {
    setshow(true);
    setDetails(info);
  };

  const MeetingDetails = () => (
    <section className="text-lg">
      <div className="w-full flex items-start justify-start pb-2">
        <div className="w-1/2">District</div>
        <div className="w-1/2 text-gray-1 capitalize">{details.district || details.assembly_id}</div>
      </div>
      <div className="w-full flex items-start justify-start pb-2">
        <div className="w-1/2">Type of Meeting</div>
        <div className="w-1/2 text-gray-1">{details.type_of_meeting}</div>
      </div>
      <div className="w-full flex items-start justify-start pb-2">
        <div className="w-1/2">Meeting Theme</div>
        <div className="w-1/2 text-gray-1">{details.meeting_theme_title}</div>
      </div>
      <div className="w-full flex items-start justify-start pb-2">
        <div className="w-1/2">Date</div>
        <div className="w-1/2 text-gray-1">{new Date(details.date).toDateString()}</div>
      </div>
      <div className="w-full flex items-start justify-start pb-2">
        <div className="w-1/2">Venue</div>
        <div className="w-1/2 text-gray-1">{details.venue}</div>
      </div>
      <div className="w-full">
        <div className="w-1/2">Agenda</div>
        <div className="text-gray-1">
          {details.agenda || 'N/A'}
        </div>
      </div>
    </section>  
  );

  // const MeetingReports = () => (
  //   <div className="w-full flex justify-center items-center flex-wrap"></div>
  // );

  // const List = [
  //   {
  //     id: 1,
  //     name: "Meeting Details",
  //     content: <MeetingDetails />
  //   },
  //   {
  //     id: 2,
  //     name: "Meeting Reports",
  //     content: <MeetingReports />
  //   }
  // ];

  useEffect(() => {
    console.log('meetingsHeld:', meetingsHeld);
  }, [meetingsHeld]);
  

  return (
    <div className="bg-white rounded-lg p-3">
      <div className="">
        <div className="flex justify-center">
          <Nav className="p-2 bg-gray-100 rounded-lg" pills>
            {navLinks.map((el) => (
              <NavItem>
                <NavLink 
                  className={`${active === el.id ? 'bg-white text-primary': 'text-black'}`} active={active === el.id} 
                  onClick={() => setactive(el.id)}
                >
                  {el.name}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
        </div>

        <TabContent className="mt-10" activeTab={active}>
          {navLinks.map((el) => (
            <TabPane tabId={el.id} >
              {el.id===1 && 
                <div className="w-full h-screen">
                  <MyCalender meetings={upcomingMeetings} />
                </div>
              }

              {el.id===2 && 
                <div className="w-full flex justify-start items-stretch flex-wrap">
                  {meetingsHeld.map(el => 
                    <GeneralMeeting 
                      title={el.type_of_meeting} 
                      sub_title={el.meeting_theme_title} 
                      date={new Date(el.date)?.toDateString()}
                      meetingInfo={el}
                      SetInfo={SetInfo}
                    />
                  )}
                </div>
              }
            </TabPane>
          ))}
        </TabContent>          
      </div>

      {show &&
        <ModalInfo 
          title="Deliberative Function"
          showModal={show}
          handleClose={() => setshow(false)}
        >
          {/* <FancyTab 
            List={List}
            hasTitle
            title=""
          /> */}

          <MeetingDetails />
        </ModalInfo>
      }
    </div>
  );
};

export default ScheduledMeeting;
