import React, {useState, useEffect} from 'react'
import Widget from '../DashbordWidget'
import ProfileTable from '../Tables/ProfileTable'
import { getStaffingData } from '../../redux/administrative/action';
import { useDispatch, useSelector } from 'react-redux';
import utils from "../../utils";

const StaffingComp = () => {
  const dispatch = useDispatch();
  const { staffingData } = useSelector(state => state.administrative);
  const meta = staffingData?.meta;
  const pagination = staffingData.pagination;

  const [tableMeta, setTableMeta] = useState({
    currentPage: 1,
    totalPages: 1,
    total: 0,
    limit: 10    
  });
  const [admin, setAdmin] = useState({});
  const [loading, setloading] = useState(false);
  const [listStaffing, setListStaffing] = useState([]);  

  const dataItems = [
    {
      id: 1,
      number: meta?.total_lgs_staff ,
      link: "",
      name: "Total GOG Staff",
      bck: "#7A6EFE"
    },
    {
      id: 2,
      number: meta?.total_igf_staff ,
      link: " ",
      name: "Total IGF Staff",
      bck: "#FF5363"
    },
    {
      id: 3,
      number: meta?.total_assembly_staff ,
      link: " ",
      name: "Total Assembly Staff",
      bck: "#FFA901"
    }
  ];
  
  const tableHeading =  {
    name:       "District",
    gog_staff:  "No of GOG Staff",
    igf_staff:  "No Of IGF Staff",
    total:      "Total Staff",
    areas:      "Areas Of Critical Staff Needs",
  };

  const handleViewAction = (val) => {
    // Find district info from val passed
  };  

  const SetInfo = (admin) => {
    setAdmin(admin);
  };

  const TableDetailsInfo = () => (
    <section className="text-lg">
      <div className="w-full flex items-start justify-start pb-1">
        <div className="w-1/2"> District </div>
        <div className="text-gray-1 pl-4 w-1/2">{admin.name}</div>
      </div>
      <div className="w-full flex items-start justify-start pb-1">
        <div className="w-1/2">No of GOG Staff </div>
        <div className="text-gray-1 pl-4 w-1/2">{admin.gog_staff}</div>
      </div>
      <div className="w-full flex items-start justify-start pb-1">
        <div className="w-1/2">No Of IGF Staff</div>
        <div className="text-gray-1 pl-4 w-1/2">{admin.igf_staff}</div>
      </div>
      <div className="w-full flex items-start justify-start pb-1">
        <div className="w-1/2">Total Staff</div>
        <div className="text-gray-1 pl-4 w-1/2">{admin.total}</div>
      </div>
      <div className="w-full pb-1">
        <div className="w-full">Areas Of Critical Staff Needs</div>
        <div className="text-gray-1">{admin.areas || 'N/A'}</div>
      </div>
  </section>
  );

  const fetchStaffing = (page, limit, search="") => {
    setloading(true);
    dispatch(getStaffingData(page, limit, search))
    .then(() => {
      setloading(false);
    }, () => {
      setloading(false);
    })
  };

  const handleStaffingFetch = (type, value) => {
    switch (type) {
      case 'limit':
        setTableMeta((prev) => { return { ...prev, currentPage: 1, limit: value } });
        fetchStaffing(1, value);
      break;

      case 'currentPage':
        setTableMeta((prev) => { return {  ...prev, currentPage: value }});        
        fetchStaffing(value, tableMeta.limit);
      break;
    
      default:
        break;
    }
  };
  
  useEffect(() => {
    if (utils.present(pagination)) {
      setTableMeta({ 
        ...tableMeta, 
        total: pagination.count,
        totalPages: pagination.totalPages,
        // limit: pagination.limit
      });
    }
  }, [pagination]);  

  useEffect(() => {
    let tepmArray = staffingData?.list?.map((el) => {
      return  {
        name: el.district,
        gog_staff: el.no_of_gog_staff,
        igf_staff: el.no_of_igf_staff,
        total: el.total,
        areas: el.areas_of_critical_staff_needs
      };
    });

    setListStaffing(tepmArray);
  },[staffingData]);  

  useEffect(() => {
    fetchStaffing()
  }, []);

  return (
    <div>
      <div className="flex flex-row" style={{width:'75%'}}>
        {dataItems.map((item) => (
          <div className="w-1/2 sm:w-1/3">
            <Widget id={item.id} link={item.link} number={item.number} name={item.name} bck={item.bck} lastItem={dataItems.length} />
          </div>
        ))}
      </div>

      <ProfileTable
        title="Staffing Data"
        tableHeading={tableHeading} 
        list={listStaffing}
        handleViewAction={handleViewAction}
        SetProfile={SetInfo}
        hasFilter
        hasPagination
        meta={tableMeta}
        entity="Staffing Data"
        showSort
        loading={loading}
        fetchList={handleStaffingFetch}
        handleSearch={(e) => fetchStaffing(1, 10, e)}
      >
        <TableDetailsInfo />
      </ProfileTable>
    </div>
  )
};

export default StaffingComp;
