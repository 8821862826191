import React, { useState, useEffect } from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import utils from "../../utils";

const TablePagination = ({
  entity="Items",
  meta={},
  triggerRequest = () => {}
}) => {
  // Table pagination
  const [pagination, setpagination] = useState({
    currentPage: 1,
    totalPages: 1,
    total: 0,
    limit: 10
  });

  const handlePagination = (type, value) => {
    switch (type) {
      case 'currentPage':
        triggerRequest(type, value)
        break;

      case 'limit':
        triggerRequest(type, value)
        break;
    
      default:
        break;
    }

    console.log('Current pagination state:', pagination);
    console.log('Value:', value, 'Type:', type);

    setpagination((prev) => {
      return { ...prev, [type]: value }
    })    
  }

  useEffect(() => {
    if (utils.present(meta)) {
      setpagination(meta)
    }
  }, [meta]);

  return (
    <div className="flex justify-end items-center font-medium">
      <div>{entity} Per Page</div>

      <div className="px-3">
        <select className="bg-transparent" name="per_page" id="select_per_page"
          value={meta?.limit}
          onChange={(e) => { 
            handlePagination('limit', e.target.value) 
          }}
        >
          {['10', '20', '50', '100'].map((el) => (
            <option value={el} key={el}>{el}</option>
          ))}
        </select>
      </div>

      <Pagination>
        <PaginationItem>
          <PaginationLink first className="w-8 bg-transparent p-0"
            disabled={pagination.currentPage === 1}
            onClick={() => handlePagination('currentPage', pagination.currentPage - 1)}
          >
            <div className={`flex items-center justify-center w-8 h-8 rounded-full ${pagination.currentPage > 1 ? 'bg-green-1 text-white' : 'bg-gray-100 text-black'}`}>
              <svg width="12" height="12" viewBox="0 0 5 8" className="fill-current" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.52382 7.39355C3.73078 7.60051 4.06634 7.60051 4.2733 7.39355C4.48027 7.18658 4.48027 6.85102 4.2733 6.64406L3.52382 7.39355ZM4.2733 1.03392C4.48027 0.826958 4.48027 0.491401 4.2733 0.284435C4.06634 0.0774699 3.73078 0.0774699 3.52382 0.284435L4.2733 1.03392ZM0.756224 3.87646L0.38148 4.25121L0.756224 3.87646ZM0.756224 3.80152L0.38148 3.42677L0.756224 3.80152ZM4.2733 6.64406L1.13097 3.50172L0.38148 4.25121L3.52382 7.39355L4.2733 6.64406ZM1.13097 4.17626L4.2733 1.03392L3.52382 0.284435L0.38148 3.42677L1.13097 4.17626ZM1.13097 3.50172C1.31724 3.68799 1.31724 3.98999 1.13097 4.17626L0.38148 3.42677C0.153819 3.65443 0.153817 4.02355 0.38148 4.25121L1.13097 3.50172Z" />
              </svg>
            </div>
          </PaginationLink>
        </PaginationItem>

        <div className="font-medium">
          {pagination.currentPage}
          {" "}-{" "}
          {pagination.totalPages}
          {" "}of{" "}
          {pagination.total}
        </div>

        <PaginationItem>
          <PaginationLink className="w-8 bg-transparent p-0 disabled" last
            disabled={pagination.currentPage >= pagination.totalPages}
            onClick={() => handlePagination('currentPage', pagination.currentPage + 1)}
          >
            <div className={`flex items-center justify-center w-8 h-8 rounded-full ${pagination.currentPage < pagination.totalPages ? 'bg-green-1' : 'bg-gray-100'}`}>
              <svg width="12" height="12" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.10156 7.0188L4.2439 3.87646C4.2646 3.85577 4.2646 3.82221 4.2439 3.80152L1.10156 0.65918" 
                  stroke={`${pagination.currentPage < pagination.totalPages ? '#FFFFFF' : '#000000'}`} 
                  strokeWidth="1.05994" 
                  strokeLinecap="round" 
                />
              </svg>
            </div>
          </PaginationLink>
        </PaginationItem>
      </Pagination>
    </div>
  );
};

export default TablePagination;
