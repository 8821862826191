import React from "react";
import { UncontrolledCarousel, Modal, ModalBody } from "reactstrap";

const DetailsModal = ({ showModal, items = [], handleClose = () => {} }) => {
  return (
    <Modal
      className="rounded-lg"
      isOpen={showModal}
      toggle={!showModal}
      centered
      scrollable={true}
      size="sm"
      fullscreen="true"
    >
      <ModalBody className="relative bg-transparent modal-body p-0">
        <div className="absolute right-0 top-0 z-10">
          <button
            className="flex items-center justify-center bg-white border border-gray-100 w-5 h-5 rounded-full p-1"
            onClick={() => handleClose()}
          >
            <svg width="8" height="8" className="fill-current text-gray-100" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.17035 7.58035C0.980351 7.58035 0.790352 7.51035 0.640352 7.36035C0.350352 7.07035 0.350352 6.59035 0.640352 6.30035L6.30035 0.640352C6.59035 0.350352 7.07035 0.350352 7.36035 0.640352C7.65035 0.930352 7.65035 1.41035 7.36035 1.70035L1.70035 7.36035C1.56035 7.51035 1.36035 7.58035 1.17035 7.58035Z" fill="#535353"/>
              <path d="M6.83035 7.58035C6.64035 7.58035 6.45035 7.51035 6.30035 7.36035L0.640352 1.70035C0.350352 1.41035 0.350352 0.930352 0.640352 0.640352C0.930352 0.350352 1.41035 0.350352 1.70035 0.640352L7.36035 6.30035C7.65035 6.59035 7.65035 7.07035 7.36035 7.36035C7.21035 7.51035 7.02035 7.58035 6.83035 7.58035Z" fill="#535353" />
            </svg>
          </button>
        </div>

      < UncontrolledCarousel items={items} classNamew="w-full bg-light" />
      </ModalBody>
    </Modal>
  );
};

export default DetailsModal;
