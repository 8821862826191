import React, { useEffect, useState } from 'react'
import Layout from "../component/Layout/Layout";
import ProfileTable from "../component/Tables/ProfileTable";
import utils from "../utils";

import { useDispatch, useSelector } from "react-redux";
import { getAuditInfraction } from "../redux/fiscal/action";

const DistrictsWithProcurment = () => {
  const dispatch = useDispatch();
  const { infraction } = useSelector(state => state.fiscal);
  let { pagination } = infraction;

  const [tableMeta, setTableMeta] = useState({
    currentPage: 1,
    totalPages: 1,
    total: 0,
    limit: 10    
  });
  const [info, setinfo] = useState({});
  const [loading, setloading] = useState(false);
  const [data, setdata] = useState([]);

  const tableHeading =  {
    district: "District",
    date_of_entry: "Date of Entry",
    date_of_exit: "Date Of Exit",
    infraction_cashmgmt_amount: "Intraction Cash Mgmt. Amount",
    infraction_procument_amount: "Intraction Procurment Amount",
    infraction_project_contract_mgmt_amount: "Infraction Proj. Contract Amount",
    infraction_store_amount: "Infraction Store Amount",
    audit_committee_meeting: "Audit Committee Meeting",
    // actions: "Action"
  };
  
  const breadcrumbList = [
    {
      name: "Fiscal",
      link: "/fiscal"
    },
    {
      name: "Audit",
      link: "/fiscal?active=3"
    },
    {
      name: "Districts With Procurement",
      link: "/fiscal/districts-wt-procurement"
    }
  ];

  const SetRecordInfo = (info) => {
    const record = infraction?.list.find(el => el.id === info?.id);
    setinfo(record || info);
  };  

  const handleViewAction = (val) => {
    console.log('View Action val:', val);
  };  

  const fetchInfraction = async (page, limit) => {
    setloading(true);
    dispatch(getAuditInfraction('districts-wt-procurement', page, limit))
    .then(() => {
      setloading(false);
    }, () => {
      setloading(false);
    })
  };  

  const handleAuditFetch = (type, value) => {
    switch (type) {
      case 'limit':
        setTableMeta((prev) => { return { ...prev, currentPage: 1, limit: value } });
        fetchInfraction(1, value);
      break;

      case 'currentPage':
        setTableMeta((prev) => { return {  ...prev, currentPage: value }});        
        fetchInfraction(value, tableMeta.limit);
      break;
    
      default:
        break;
    }
  };  

  useEffect(() => {
    let tepmArray = infraction?.list?.map((el) => {
      return  {
        district: el?.district,
        date_of_entry: new Date(el.date_of_entry)?.toLocaleDateString(),
        date_of_exit: new Date(el.date_of_exit)?.toLocaleDateString(),
        infraction_cashmgmt_amount: utils.numberWithCommas(el?.infraction_cashmgmt_amount),
        infraction_procument_amount: utils.numberWithCommas(el?.infraction_procument_amount),
        infraction_project_contract_mgmt_amount: utils.numberWithCommas(el?.infraction_project_contract_mgmt_amount),
        infraction_store_amount: utils.numberWithCommas(el?.infraction_store_amount),
        audit_committee_meeting: el?.audit_committee_meeting,
        // actions: 
        // <div data-id={el.id} className="hover:underline">
        //   <span className="text-primary hover:underline" onClick={()=> { 
        //     utils.downloadBase64(el.audit_report_file_url, el.audit_report_file_name, "application/png")
        //   }}>View</span>
        // </div>
      };
    });
    setdata(tepmArray);

    // if revenue.pagination is available
    if (utils.present(pagination)) {
      setTableMeta({
        ...tableMeta,
        total: pagination?.count,
        totalPages: pagination.totalPages,
      });
    }
  },[infraction]);  

  useEffect(() => {
    fetchInfraction();
  }, []);

  return (
    <Layout pageTitle="Districts With Procurement" breadcrumb={breadcrumbList}>
      <ProfileTable
        title="Districts With Procurement"
        placeholder="Search for District"
        tableHeading={tableHeading}
        SetProfile={SetRecordInfo}
        meta={tableMeta}
        list={data}
        entity="Fiscal"
        handleViewAction={handleViewAction}
        hasFilter
        showSort
        hasPagination
        loading={loading}
        fetchList={handleAuditFetch}
      />
    </Layout>
  );
}

export default DistrictsWithProcurment;