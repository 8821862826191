import React, { useEffect } from "react";
import utils from "../../utils";

import Widget from "../DashbordWidget";
import FancyTab from "../Tabs/FancyTab";

import { getSecurity } from "../../redux/SocialServices/action";
import { useDispatch, useSelector } from "react-redux";
import Police from "./Police";
import FireService from "./FireService";
import Other from "./Other";

const Security = () => {
  const dispatch = useDispatch();
  const { security } = useSelector(state => state.socialServices);
  const { meta } = security;

  const dataItems = [
    {
      id: 1,
      number: utils.numberWithCommas(meta?.No_of_stations),
      link: "",
      name: "No of stations",
      bck: "#7A6EFE",
    },
    {
      id: 2,
      number: utils.numberWithCommas(meta?.No_of_personnels),
      link: "",
      name: "No. Of Personnel",
      bck: "#FF5363",
    },
    {
      id: 3,
      number: utils.numberWithCommas(meta?.No_of_reported_crimes),
      link: "",
      name: "No. Of Reported crimes",
      bck: "#FFA901",
    },
  ];

  useEffect(() => {
    dispatch(getSecurity());
  }, []);

  const List = [
    {
      id: 1,
      name: "Police",
      content: <Police />,
    },
    {
      id: 2,
      name: "Fire Service",
      content: <FireService />,
    },
    {
      id: 3,
      name: "Other",
      content: <Other />,
    },
  ];

  return (
    <div>
      <div className="w-full flex flex-row">
        {dataItems.map((item) => (
          <div className="w-full sm:w-1/4">
            <Widget
              id={item.id}
              link={item.link}
              number={item.number}
              name={item.name}
              bck={item.bck}
              lastItem={dataItems.length}
            />
          </div>
        ))}
      </div>

      <div className="shadow-sm rounded-lg  bg-white pb-2 mb-2">
        <div className="px-6 pt-4">
          <FancyTab List={List} />
        </div>
      </div>
    </div>
  );
};

export default Security;
