import React, { useState, useEffect } from "react";
import ProfileTable from "./Tables/ProfileTable";
import { useSelector } from "react-redux";

const tableHeading = {
  no_of_household_toilet: "No Of Household Toilets",
  no_of_public_toilet: "No Of Public Toilets",
  number_of_septic_trucks: "No Of Septic Trucks",
  no_of_odf_communities: "No Of ODF Community",
};

const LiquidWaste = () => {
  const { liquidWaste } = useSelector(
    (state) => state.environmentAndSanitation
  );

  const [profile, setprofile] = useState({});
  const [data, setdata] = useState([]);

  const SetProfile = (profile) => {
    setprofile(profile);
  };

  const TableDetailsInfo = () => (
    <section className="text-lg">
      <div className="w-full flex items-start justify-start pb-3">
        <div className="w-1/2">No Of Household Toilets</div>
        <div className="text-gray-1 w-1/2">
          {profile.no_of_household_toilet}
        </div>
      </div>
      <div className="w-full flex items-start justify-start pb-1">
        <div className="w-1/2">No Of Public Toilets</div>
        <div className="text-gray-1 w-1/2">{profile.no_of_public_toilet}</div>
      </div>
      <div className="w-full flex items-start justify-start pb-1">
        <div className="w-1/2">No Of Septic Trucks</div>
        <div className="text-gray-1 w-1/2">
          {profile.number_of_septic_trucks}
        </div>
      </div>
      <div className="w-full flex items-start justify-start pb-1">
        <div className="w-1/2">No Of ODF Community</div>
        <div className="text-gray-1 w-1/2">{profile.no_of_odf_communities}</div>
      </div>
    </section>
  );

  useEffect(() => {
    let tepmArray = liquidWaste?.list?.map((el) => {
      return {
        id: el.id,
        no_of_household_toilet: el?.no_of_household_toilet,
        no_of_public_toilet: el?.no_of_public_toilet,
        number_of_septic_trucks: el?.number_of_septic_trucks,
        no_of_odf_communities: el?.no_of_odf_communities,
      };
    });
    setdata(tepmArray);
  }, [liquidWaste]);

  return (
    <div>
      <ProfileTable
        title="Solid Waste"
        tableHeading={tableHeading}
        showDivider
        list={data}
        SetProfile={SetProfile}
      >
        <TableDetailsInfo />
      </ProfileTable>
    </div>
  );
};

export default LiquidWaste;
