import React, { useState, useEffect, useCallback } from "react";
import ProfileTable from "../../component/Tables/ProfileTable";
import ProjectPopUp from "./ProjectPopUp";
import DetailsModal from "../DetailsModal";
import Widget from "../../component/DashbordWidget";
import utils from '../../utils'

import { useDispatch, useSelector } from "react-redux";
import { getOthersInfrastructure } from "../../redux/infrastructure/action";

const tableHeading =  {
  name: "District",
  name_of_project: "Name of Project",
  number_of_facilities: "No Of Facilities",
  remarks: "Remarks",
};

const handleViewAction = (val) => {
  // Find district info from val passed
};

const RoadInfrastructure = () => {
  const dispatch = useDispatch();
  const { other } = useSelector((state) => state.infrastructure);
  let { pagination } = other;

  const [tableMeta, setTableMeta] = useState({
    currentPage: 1,
    totalPages: 1,
    total: 0,
    limit: 10    
  });  

  const [list, setlist] = useState([]);
  const [current, setcurrent] = useState(1);
  const [showModal, setshowModal] = useState(false);   
  const [profile, setprofile] = useState({});
  const [data, setdata] = useState([])
  const [loading, setloading] = useState(false)

  const SetProfile = (road) => {
    const newRoad = other?.list?.find((el)=> el.id === road?.id)
    setlist([newRoad.picture_file_url]);
    setprofile(newRoad);
  };

  const handleShowImag = useCallback((val) => {
    setcurrent(val);
    setshowModal(true);
  }, []);    


  const dataItems = [
    {
      id: 1,
      number: other?.meta?.Completed,
      link: "",
      name: "Completed Projects",
      bck: "#7A6EFE",
    },
    {
      id: 2,
      number: other?.meta?.Ongoing,
      link: "",
      name: "Ongoing Projects",
      bck: "#FF5363",
    },
    {
      id: 3,
      number: other?.meta?.Stalled,
      link: "",
      name: "Stalled Projects",
      bck: "#FFA901",
    },
    {
      id: 4,
      number: other?.meta?.Highest_Cost_Project,
      link: "",
      name: "Highest Cost Of Project",
      bck: "#24A8FA",
    },
  ];
  
  const TableDetailsInfo = () => (
    <section className="text-lg">
      <div className="w-full flex items-center justify-start pb-3">
        <div className="w-1/2">District</div>
        <div className="text-gray-1">{profile.district}</div>
      </div>
      <div className="w-full flex items-center justify-start pb-3">
        <div className="w-1/2">Name of Project</div>
        <div className="text-gray-1">{profile.name_of_project}</div>
      </div>
      <div className="w-full flex items-center justify-start pb-3">
        <div className="w-1/2">Number of Facilities</div>
        <div className="text-gray-1">{profile.number_of_facilities}</div>
      </div>
      <div className="w-full pb-3">
        <div className="">Construction Status</div>
        <div className="text-gray-1  capitalize">{profile.construction_status || "N/A"}</div>
      </div>      
      <div className="w-full pb-3">
        <div className="w-1/2">Remarks</div>
        <div className="text-gray-1">{profile.Remarks}</div>
      </div>
      <div className="w-full pb-3">
        <div className="w-full text-end">
          <ProjectPopUp list={list} handleShowImag={handleShowImag} />
        </div>
      </div>     
    </section>
  );

  const fetchOtherInfra = (page, limit, search="") => {
    setloading(true);
    dispatch(getOthersInfrastructure(page, limit, search))
    .then(() => {
      setloading(false);
    }, () => {
      setloading(false);
    })
  };

  const handleOthersFetch = (type, value) => {
    switch (type) {
      case 'limit':
        setTableMeta((prev) => { return { ...prev, currentPage: 1, limit: value } });
        fetchOtherInfra(1, value);
      break;

      case 'currentPage':
        setTableMeta((prev) => { return {  ...prev, currentPage: value }});        
        fetchOtherInfra(value, tableMeta.limit);
      break;
    
      default:
        break;
    }
  };  

  useEffect(() => {
    let tepmArray = other?.list?.map((el) => {
      return  {
        id: el.id,
        name: el?.district,
        name_of_project: el?.name_of_other,
        number_of_facilities: el?.number_of_facilities,
        remarks: el?.Remarks      
      };
    });

    setdata(tepmArray);

    // if revenue.pagination is available
    if (utils.present(other?.pagination)) {
      setTableMeta({
        ...tableMeta,
        total: pagination?.count,
        totalPages: pagination.totalPages,
      });
    }
  },[other]);

  useEffect(() => {
    fetchOtherInfra();
  }, []);



  return (
    <div>
      <div className="w-full flex flex-row">
        {dataItems.map((item) => (
          <Widget
            id={item.id}
            link={item.link}
            number={item.number}
            name={item.name}
            bck={item.bck}
            lastItem={dataItems.length}
          />
        ))}
      </div>

      <ProfileTable
        title="Other Infrastructure"
        tableHeading={tableHeading}
        list={data}
        handleViewAction={handleViewAction}
        SetProfile={SetProfile}
        hasFilter
        loading={loading}
        meta={tableMeta}
        hasPagination
        fetchList={handleOthersFetch}
        handleSearch={(e) => fetchOtherInfra(1, 10, e)}
      >
        <TableDetailsInfo />
      </ProfileTable>

      {showModal && (
        <DetailsModal
          current={current}
          showModal={showModal}
          handleClose={() => setshowModal(false)}
          items={list.map((el, index) => {
            return { key: index + 1, src: el };
          })}
        />
      )}      
    </div>
  );
};

export default RoadInfrastructure;