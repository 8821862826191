import React, { useEffect, useState } from 'react'
import TravelCard from './TravelCard';
import NoData from "./NoData";

const Iteneraries = ({
    title="Iteneraries",
    itemsStyling="w-full sm:w-1/4",
    itemsParent="px-4 pb-4",
    showTitle=true,
    dataItems=[],
    sub_title="text-base"
}) => {
    const [data, setdata] = useState([]);

    useEffect(() => {
        setdata(dataItems);
    }, [dataItems]);

    
  return (
    <div className="bg-white">
        {showTitle &&
            <>
                <div className="px-6 pb-2">
                    <h1 className=" text-2xl font-semibold py-3 mb-0">{title}</h1>
                </div>

                <hr/>
            </>
        }

        <div className={`flex items-center justify-start flex-wrap ${itemsParent}`}>
            {data.length > 0 ?
                data.map((el) => (
                    <div className={`${itemsStyling}`}>
                        <TravelCard 
                            name={el?.name} 
                            title={el?.title}
                            label={el?.label} 
                            start={el?.start} 
                            end={el?.end}
                            sub_title={sub_title}
                            icon={el.icon}
                        />
                    </div>
                ))
            : 
                <NoData entity="Itenararies" />
            }
        </div>

    </div>
  )
}

export default Iteneraries


