import FiscalTabItems from "../component/Fiscal/FiscalTabItems";
import Layout from "../component/Layout/Layout";

const FiscalPage = () => {
  return (
    <Layout pageTitle="Fiscal">
      <FiscalTabItems/>
    </Layout>
  );
}

export default FiscalPage;
