import React, { useState, useEffect } from "react";
import Widget from "../DashbordWidget";
import ProfileTable from "../Tables/ProfileTable";
import utils from '../../utils'

import { getPollution } from "../../redux/EnvironmentAndSanitation/action";
import { useDispatch, useSelector } from "react-redux";

const Pollution = () => {
  const dispatch = useDispatch();
  const { pollution } = useSelector((state) => state.environmentAndSanitation);
  let pagination = pollution.pagination;

  const [tableMeta, setTableMeta] = useState({
    currentPage: 1,
    totalPages: 1,
    total: 0,
    limit: 10    
  });  

  const [profile, setprofile] = useState({});
  const [data, setdata] = useState([])
  const [loading, setloading] = useState(false);

  const tableHeading = {
    name: "District",
    reported: "Reported Cases of Environmental Pollution",
    remedy: "Remedial Action Taken",    
  };  

  const SetProfile = (noise) => {
    const newPollution = pollution?.list?.find((el)=> el.id === noise?.id)
    setprofile(newPollution);
  };

  const dataItems = [
    {
      id: 1,
      number: pollution?.meta?.noise_polution,
      link: "",
      name: "Noise Pollution",
      bck: "#7A6EFE"
    },
    {
      id: 2,
      number: pollution?.meta?.air_pollution,
      link: "",
      name: "Air Pollution",
      bck: "#FF5363"
    },
    {
      id: 3,
      number: pollution?.meta?.water_pollution,
      link: "",
      name: "Water Pollution",
      bck: "#FFA901"
    },
    // {
    //   id: 4,
    //   number: pollution?.meta?.districts_with_industrial_waste,
    //   link: "",
    //   name: "Districts with Industrial Waste",
    //   bck: "#24A8FA"
    // },
  ];

  const TableDetailsInfo = () => (
    <section className="text-lg">
      <div className="w-full flex items-center justify-start pb-1">
        <div className="w-1/2">District</div>
        <div className="text-gray-1">{profile.district}</div>
      </div>
      <div className="w-full flex items-center justify-start pb-1">
        <div className="w-1/2">Reported Cases of Environmental Pollution</div>
        <div className="text-gray-1">{profile.reported_environmental_pollution_cases}</div>
      </div>
      <div className="w-full flex items-center justify-start pb-1">
        <div className="w-1/2">Remedial Action Taken</div>
        <div className="text-gray-1">{profile.remedy}</div>
      </div>
      <div className="w-full flex items-center justify-start pb-1">
        <div className="w-1/2">Number Of Complaints</div>
        <div className="text-gray-1">{profile.no_of_complaints}</div>
      </div>
    </section>
  );

  const fetchPollution = (page, limit, search="") => {
    setloading(true);
    dispatch(getPollution(page, limit, search))
    .then(() => {
      setloading(false);
    }, () => {
      setloading(false);
    })
  };

  const handlePollutionFetch = (type, value) => {
    switch (type) {
      case 'limit':
        setTableMeta((prev) => { return { ...prev, currentPage: 1, limit: value } });
        fetchPollution(1, value);
      break;

      case 'currentPage':
        setTableMeta((prev) => { return {  ...prev, currentPage: value }});        
        fetchPollution(value, tableMeta.limit);
      break;
    
      default:
        break;
    }
  };  

  useEffect(() => {
    let tepmArray = pollution?.list?.map((el) => {
      return  {
        id: el.id,
        name: el.district,
        reported: el.reported_environmental_pollution_cases,
        remedy: el.remedy
      };
    });
    setdata(tepmArray);

    // if revenue.pagination is available
    if (utils.present(pollution?.pagination)) {
      setTableMeta({
        ...tableMeta,
        total: pagination?.count,
        totalPages: pagination.totalPages,
        // limit: pagination.limit
      });
    }
  },[pollution]);

  useEffect(() => {
    fetchPollution();
  }, []);

  return (
    <div>
      <div className="w-full flex flex-row">
        {dataItems.map((item) => (
          <Widget
            id={item.id}
            link={item.link}
            number={item.number}
            name={item.name}
            bck={item.bck}
            lastItem={dataItems.length}
          />
        ))}
      </div>

      <ProfileTable
        title="Pollution"
        tableHeading={tableHeading}
        SetProfile={SetProfile}
        list={data}
        hasFilter
        hasPagination
        entity="List"
        loading={loading}
        meta={tableMeta}
        fetchList={handlePollutionFetch}
        handleSearch={(e) => fetchPollution(1, 10, e)} 
      >
        <TableDetailsInfo />
      </ProfileTable>
    </div>
  );
};

export default Pollution;