import React from "react";

const RegionWidget = ({ color, name, amount}) => {
  return (
    <>
      <div
        className="rounded-full mt-1 mr-3"
        style={{ width: "10px", height: "10px", background: color }}
      />
      <div>
        <h3 className="text-sm text-gray-1">{name}</h3>
        <p className="font-bold text-black text-xl">{amount}</p>
      </div>
    </>
  );
};

export default RegionWidget;
