import React from 'react'

const NotFound = () => {
  return (
    <div className="flex justify-center items-center bg-white w-full h-full">
        <div className="flex justify-center items-center">
            <div className="text-6xl p-5 font-bold border border-r border-gray-2000 text-primary">
                404
            </div>
            <div>
                <div className="text-6xl p-5 font-bold text-black">Page Not Found</div>
                <p className="text-lg text-gray-400">Please check the URL in the address bar and try again</p>
            </div>
        </div>
    </div>
  )
}

export default NotFound