import React, {useState, useEffect} from 'react'
import ProfileTable from './Tables/ProfileTable';
import { useSelector } from 'react-redux';

const tableHeading =  {
  number: "Number of Electricity Applications",
  processed: "Number of Applications Processed",
  issued: "Number of Applications Issued",
  electricity:"Number of Communities Without Electricity",
  communities: "Communities Without Electricity"
};




const Electricity = () => {
  const { electricity } = useSelector((state) => state.socialServices);

  const [profile, setprofile] = useState({});
  const [data, setdata] =useState([])

  const SetProfile = (profile) => {
    setprofile(profile);
  };


  const TableDetailsInfo = () => (
    <section className="text-lg">
    <div className="w-full flex items-center justify-start pb-3">
      <div className="w-1/2">Number of Electricity Applications</div>
      <div className="text-gray-1 w-1/2">{profile.number}</div>
    </div>
    <div className="w-full flex items-center justify-start pb-3">
      <div className="w-1/2">Number of Applications Processed</div>
      <div className="text-gray-1 w-1/2">{profile.processed}</div>
    </div>
    <div className="w-full flex items-center justify-start pb-3">
      <div className="w-1/2">Number of Applications Issued</div>
      <div className="text-gray-1 w-1/2">{profile.issued}</div>
    </div>
    <div className="w-full pb-3">
      <div className="">Communities Without Electricity</div>
      <div className="text-gray-1 text-base">
          <ul className="list-disc">  
            {profile?.communities_without_facilities?.split(", ")?.map((el, index) => (
              <li key={index}>{el}</li>
            ))}
          </ul>
        </div>      
      <div className="text-gray-1 pt-2">{profile.communities}</div>
    </div>
  
  </section>
  );
  
  useEffect(() => {
    let tepmArray = electricity?.list?.map((el) => {
      return  {
        number: el.number_of_applications,
        processed: el.number_of_applications_processed,
        issued: el.number_of_applications_issued,
        electricity:el.no_communities_without_facilities,
        communities: el.communities_without_facilities,
      };
    });

    setdata(tepmArray);

  },[electricity]); 

    



  return (
    <div>
        <ProfileTable
        title='Electricity'
        tableHeading={tableHeading}
        showDivider
        list={data}
        SetProfile={SetProfile}
      >
        <TableDetailsInfo/>
      </ProfileTable>
    </div>
  )
}

export default Electricity