import React, { useEffect } from 'react'
import BasicEducation from '../BasicEducation'
import Electricity from '../Electricity'
import HealthCare from '../HealthCare'
import LandSpatial from '../LandSpatial'
import Security from '../Security'
import WaterCoverage from '../WaterCoverage'

// Redux
import { useDispatch } from "react-redux";
import { getSocialServiceById } from "../../redux/SocialServices/action";

const SocialServices = ({ id }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSocialServiceById(id));
  }, [id]);
  

  return (
    <div>
      <BasicEducation/>

      <HealthCare/>

      <Electricity/>

      <WaterCoverage/>

      <LandSpatial/>

      <Security />
    </div>
  );
};

export default SocialServices