import React, { useState, useEffect, useCallback } from "react";
import ProfileTable from "../Tables/ProfileTable";
import ProjectPopUp from "./ProjectPopUp";
import DetailsModal from "../DetailsModal";
import Widget from "../../component/DashbordWidget";
import utils from "../../utils";

// import DetailsModal from '../DetailsModal';
import { useSelector, useDispatch } from "react-redux";
import { getHealthInfrastructure } from "../../redux/infrastructure/action";

const HealthInfracture = () => {
  const dispatch = useDispatch();
  const { healthInfrastructure } = useSelector((state) => state.infrastructure);
  let pagination = healthInfrastructure.pagination;

  const [tableMeta, setTableMeta] = useState({
    currentPage: 1,
    totalPages: 1,
    total: 0,
    limit: 10,
  });
  const [list, setlist] = useState([]);
  const [current, setcurrent] = useState(1);
  const [showModal, setshowModal] = useState(false); 
  const [profile, setprofile] = useState({});
  const [loading, setloading] = useState(false);
  const [data, setdata] = useState([]);

  const tableHeading = {
    name: "District",
    project_name: "Project Name",
    health_type: "Type",
    location: "Location",
    construction_status: "Status of Completion",
    percentage: "Percentage of completion",
    start: "Project Commence",
    completion: "Expected Completion",
    cost: "Project Cost",
  };

  const SetProfile = (profile) => {
    const record = healthInfrastructure?.list?.find((el)=> el.id === profile?.id)
    setlist([record.picture_file_url]);
    setprofile(profile);
  };

  const handleShowImag = useCallback((val) => {
    setcurrent(val);
    setshowModal(true);
  }, []);

  const dataItems = [
    {
      id: 1,
      number: healthInfrastructure?.meta?.Completed,
      link: "",
      name: "Completed Health Facilities",
      bck: "#7A6EFE",
    },
    {
      id: 2,
      number: healthInfrastructure?.meta?.Ongoing,
      link: "",
      name: "Ongoing Projects",
      bck: "#FF5363",
    },
    {
      id: 3,
      number: healthInfrastructure?.meta?.Stalled,
      link: "",
      name: "Stalled Projects",
      bck: "#FFA901",
    },
    {
      id: 4,
      number: utils.numberWithCommas(healthInfrastructure?.meta?.Highest_Cost_Project),
      link: "",
      name: "Highest Cost Of Project",
      bck: "#24A8FA",
    },
  ];  

  const TableDetailsInfo = () => (
    <section className="text-lg">
      <div className="w-full flex items-start justify-start pb-3">
        <div className="w-1/2">Project Name</div>
        <div className="text-gray-1 w-1/2 text-end">{profile.project_name}</div>
      </div>
      <div className="w-full flex items-start justify-start pb-3">
        <div className="w-1/2">Health Type</div>
        <div className="text-gray-1 w-1/2 text-end">{profile.health_type}</div>
      </div>
      <div className="w-full flex items-start justify-start pb-3">
        <div className="w-1/2">Location</div>
        <div className="text-gray-1 w-1/2 text-end">{profile.location}</div>
      </div>
      <div className="w-full flex items-start justify-between pb-3">
        <div className="w-1/2">Construction Status</div>
        <div className="">{profile.construction_status}</div>
      </div>
      <div className="w-full flex items-start justify-between pb-3">
        <div className="w-1/2">Percentage of Completion</div>
        <div className="">{profile.percentage}</div>
      </div>
      <div className="w-full flex items-start justify-start pb-3">
        <div className="w-1/2">Project Commencement</div>
        <div className="text-gray-1 w-1/2 text-end">{profile.start}</div>
      </div>
      <div className="w-full flex items-start justify-start pb-3">
        <div className="w-1/2">Project Completion</div>
        <div className="text-gray-1 w-1/2 text-end">{profile.completion}</div>
      </div>
      <div className="w-full flex items-start justify-start pb-3">
        <div className="w-1/2">Project Cost</div>
        <div className="text-gray-1 w-1/2 text-end">{profile.cost}</div>
      </div>
      <div className="w-full pb-3">
        <div className="w-full text-end">
          <ProjectPopUp list={list} handleShowImag={handleShowImag} />
        </div>
      </div>       
    </section>
  );

  const fetchHealthInfra = (page, limit, search="") => {
    setloading(true);
    dispatch(getHealthInfrastructure(page, limit, search)).then(
      () => {
        setloading(false);
      },
      () => {
        setloading(false);
      }
    );
  };

  const handleStaffingFetch = (type, value) => {
    switch (type) {
      case "limit":
        setTableMeta((prev) => {
          return { ...prev, currentPage: 1, limit: value };
        });
        fetchHealthInfra(1, value);
        break;

      case "currentPage":
        setTableMeta((prev) => {
          return { ...prev, currentPage: value };
        });
        fetchHealthInfra(value, tableMeta.limit);
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    let tepmArray = healthInfrastructure?.list?.map((el) => {
      return {
        id: el.id,
        name: el.district,
        project_name: el.project_name,
        health_type: el.health_type,
        location: el.location,
        construction_status: (
          <div className="text-center text-white bg-green-600 rounded-full py-1 px-2">
            {el.construction_status || "N/A"}
          </div>
        ),
        percentage: (
          <div className="text-center">{`${el.completion_status}%`}</div>
        ),
        start: new Date(el.project_commence)?.toLocaleDateString(),
        completion: new Date(el.expected_completion)?.toLocaleDateString(),
        cost: utils.numberWithCommas(el.contract_sum),
      };
    });
    setdata(tepmArray);
  }, [healthInfrastructure]);

  useEffect(() => {
    // if revenue.pagination is available
    if (utils.present(pagination)) {
      setTableMeta({
        ...tableMeta,
        total: pagination?.count,
        totalPages: pagination.totalPages,
      });
    }
  }, [pagination]);

  useEffect(() => {
    fetchHealthInfra()
  }, []);
  

  return (
    <div>
      <div className="w-full flex flex-row overflow-y-hidden">
        {dataItems.map((item) => (
          <div className="w-1/2 sm:w-1/4">
            <Widget
              id={item.id}
              link={item.link}
              number={item.number}
              name={item.name}
              bck={item.bck}
              lastItem={dataItems.length}
            />
          </div>
        ))}
      </div>

      <ProfileTable
        title="Health Infrastructure"
        tableHeading={tableHeading}
        list={data}
        showDivider
        SetProfile={SetProfile}
        hasFilter
        loading={loading}
        meta={tableMeta}
        hasPagination
        fetchList={handleStaffingFetch}
        handleSearch={(e) => fetchHealthInfra(1, 10, e)} 
      >
        <TableDetailsInfo />
      </ProfileTable>

        {showModal && 
          <DetailsModal 
            current={current}
            showModal={showModal}
            handleClose={() => setshowModal(false)}
            items={list.map((el, index) => { return  { key: index+1, src: el }})}
          />
        }
    </div>
  );
};

export default HealthInfracture;
