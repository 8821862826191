import React from 'react'
import { Button } from "reactstrap";

const GeneralMeeting = ({ 
    title="", 
    sub_title="", 
    date="",
    SetInfo=()=>{},
    meetingInfo={}
}) => {

  return (
    <div className="w-full sm:w-1/3 p-2 hover:scale-105 transform transition">
      <div className="flex flex-column justify-between h-full bg-blue-100 border-2 border-blue-500 rounded-lg p-2" >
        <div>
          <h2 className='text-lg'>{title}</h2>
          <h3 className="text-primary text-sm">{sub_title}</h3>
        </div>  

        <div className="flex justify-between items-center pt-4">
          <span className="text-sm">{date}</span>
          <Button color="primary" onClick={()=> SetInfo(meetingInfo)}>View</Button>
        </div>
      </div>
    </div>
  )
}

export default GeneralMeeting;