import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Layout from "../component/Layout/Layout";
import Tabs from "../tabs/Tabs";

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { getDistrictById } from "../redux/profile/action";

const Districts = () => {
  const dispatch = useDispatch();
  let { id } = useParams();

  const { districtInfo } = useSelector(state => state.profile);
  const [loading, setloading] = useState(false);
  const [active, setactive] = useState(1);

  const breadcrumbList = [
    {
      name: "Profile",
      link: "/profile"
    },
    {
      name: "Districts Submitted",
      link: "/profile/districts"
    },
    {
      name: districtInfo?.district,
      link: `/profile/districts/${districtInfo?.id}`
    },
  ];  

  const fetchDistrictInfo = (page, limit) => {
    setloading(true);
    dispatch(getDistrictById(id))
    .then((response) => {
      setloading(false);
    }, (err) => {
      setloading(false);
      console.error('Error fetching profiles:', err);
    })
  };  

  useEffect(() => {
    fetchDistrictInfo()
    window.scrollTo(0,0);
  }, []);

  return (
    <Layout 
      pageTitle={districtInfo?.district ? <span className="animate-fade">{`${districtInfo?.district} District`}</span> : <div className="animate-pulse bg-white px-40 py-4 rounded-sm" />}
      breadcrumb={breadcrumbList}
    >
      <div className="pb-10">
        <Tabs id={id} active={active} setactive={setactive} />
      </div> 
    </Layout>
  );
}

export default Districts;
