import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import "./ModalInfo.scss";

const ProfileInfo = ({
  children,
  title="",
  actionName="Export",
  showModal=false,
  showFooter=false,
  scrollable=true,
  handleClose=()=>{}
}) => {
  return (

    <Modal 
      className="rounded-lg" 
      isOpen={showModal} 
      centered
      scrollable={scrollable}
    >
      <ModalHeader 
        className="px-4"
        close={
        <button className="flex items-center justify-center border border-gray-100 w-5 h-5 rounded-full p-1" 
          onClick={() => handleClose()}
        >
          {/* × */}

          <svg width="8" height="8" className="fill-current text-gray-100" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.17035 7.58035C0.980351 7.58035 0.790352 7.51035 0.640352 7.36035C0.350352 7.07035 0.350352 6.59035 0.640352 6.30035L6.30035 0.640352C6.59035 0.350352 7.07035 0.350352 7.36035 0.640352C7.65035 0.930352 7.65035 1.41035 7.36035 1.70035L1.70035 7.36035C1.56035 7.51035 1.36035 7.58035 1.17035 7.58035Z" fill="#535353"/>
            <path d="M6.83035 7.58035C6.64035 7.58035 6.45035 7.51035 6.30035 7.36035L0.640352 1.70035C0.350352 1.41035 0.350352 0.930352 0.640352 0.640352C0.930352 0.350352 1.41035 0.350352 1.70035 0.640352L7.36035 6.30035C7.65035 6.59035 7.65035 7.07035 7.36035 7.36035C7.21035 7.51035 7.02035 7.58035 6.83035 7.58035Z" fill="#535353"/>
          </svg>

        </button>
        }
      >
        {title}
      </ModalHeader>
      <ModalBody
        className="px-4 modal-body"
      >
        {children}
      </ModalBody>

      {showFooter &&
        <ModalFooter className="px-4">
          <div className="w-full flex justify-center items-center py-2">
            <div className="w-1/2 pr-2">
              <Button
                onClick={() => handleClose()}
                className="w-full hover:bg-white"
                outline
              >
                Cancel
            </Button>            
            </div>

            <div className="w-1/2 pl-2">
              <Button color="primary" className="w-full" 
                onClick={() => handleClose()}
              >
                {actionName}
              </Button>
            </div>
          </div>
        </ModalFooter>
      }
    </Modal>
  );
};

export default ProfileInfo;
