import React from "react";

// Images
import emptyState from "../asset/images/empty_table.jpg";

const NoData = ({ entity="Data"}) => {
  return (
    <div className="w-full">
      <img src={emptyState} className="mx-auto" alt="" />
      <div className="text-center text-lg pt-2 pb-4">No {entity} Available</div>
    </div>
  );
};

export default NoData;
