import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import styles from "../../tabs/Tabs.module.scss";
import utils from "../../utils";

//Tabs
import Audit from "./Audit";
import Expenditure from "./Expenditure";
import Revenue from "./Revenue";

const FiscalTabItems = () => {
  const navigate = useNavigate();

  const [active, setactive] = useState(1);

  const tabList = [
    {
      id: 1,
      name: "Revenue",
      link: "/revenue",
    },
    {
      id: 2,
      name: "Expenditure",
      link: "",
    },
    {
      id: 3,
      name: "Audit",
      link: "",
    },
  ];

  useEffect(() => {
    if (utils.getPageQueryParams()['active']) {
      setactive(parseInt(utils.getPageQueryParams()['active']));
      navigate('/fiscal/');
    }
  }, []);
  

  return (
    <div>
      <Nav
        className={`${styles.navparent} flex flex-nowrap mb-4 bg-gray-100 overflow-y-hidden tab`}
        tabs
      >
        {tabList.map((el) => (
          <NavItem>
            <NavLink
              className={`${styles.link} ${active === el.id ? "active bg-transparent" : ""} text-sm border-none uppercase`}
              onClick={() => { setactive(el.id) }}
            >
              {el.name}
            </NavLink>
          </NavItem>
        ))}
      </Nav>

      <TabContent activeTab={active}>
        {tabList.map((el) => (
          <TabPane tabId={el.id}>
            {el.id === 1 && <Revenue />}
            {el.id === 2 && <Expenditure />}
            {el.id === 3 && <Audit />}
          </TabPane>
        ))}
      </TabContent>
    </div>
  );
};

export default FiscalTabItems;
