import React, {useState, useEffect} from 'react'
import ProfileTable from './Tables/ProfileTable';
import { useSelector } from 'react-redux';
import utils from "../utils";

const tableHeading = {
  applied: "Permits Applied",
  issued: "Permits Issued",
  revenue: "Revenue",
};

const LandSpatial = () => {
  const { landUseAndSpatialPlanning } = useSelector((state) => state.socialServices);
  
  const [profile, setprofile] = useState({});
  const [data, setdata] = useState([])

  const SetProfile = (profile) => {
    setprofile(profile);
  };


  const TableDetailsInfo = () => (
    <section className="text-lg">
    <div className="w-full flex items-center justify-start pb-1">
      <div className="w-1/2">Number Of Building or Construction Permits Applied</div>
      <div className="text-gray-1 text-end w-1/2">{profile.applied}</div>
    </div>
    <div className="w-full flex items-center justify-start pb-1">
      <div className="w-1/2">Number Of Building Permits Issued</div>
      <div className="text-gray-1 text-end w-1/2">{profile.issued}</div>
    </div>
    <div className="w-full flex items-center justify-start pb-1">
      <div className="w-1/2">Revenue for New Buildings and Structures</div>
      <div className="text-gray-1 text-end w-1/2">{profile.revenue}</div>
    </div>    
    
  </section>
  );

  useEffect(() => {
    let tepmArray = landUseAndSpatialPlanning?.list?.map((el) => {
      return  {
        applied: el.building_permits_applied,
        issued: el.building_permits_issued,
        revenue: utils.numberWithCommas(el.building_permits_revenue)
      };
    });

    setdata(tepmArray);

  },[landUseAndSpatialPlanning]); 


  return (
    <div>
        <ProfileTable
        title='Land And Spatial Planning'
        tableHeading={tableHeading}
        showDivider
        list={data}
        SetProfile={SetProfile}
      >
        <TableDetailsInfo/>
      </ProfileTable>
    </div>
  )
}

export default LandSpatial