import { initializeApp } from 'firebase/app';
import { getAuth } from  "firebase/auth";
import {getFirestore} from 'firebase/firestore'
import { getStorage } from "firebase/storage";


const app = initializeApp({
    apiKey: "AIzaSyBIjB4yzhdUuYusUN1qhxoXc3s4TBtI42Q",
    authDomain: "mmda-admin.firebaseapp.com",
    projectId: "mmda-admin",
    storageBucket: "mmda-admin.appspot.com",
    messagingSenderId: "455710866786",
    appId: "1:455710866786:web:9700ef394441f2f83a003b"
})


export const auth = getAuth(app)

export const db = getFirestore(app)

export const storage = getStorage(app);

export default app

