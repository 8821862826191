import React, { memo } from "react";
import { Link } from "react-router-dom";
// import AnimatedNumber from "react-animated-number";

const DashbordWidget = ({
  id,
  link,
  number,
  name,
  bck,
  lastItem,
  widgetFont = "text-3xl",
  scaleOnHover = "hover:scale-105 transform transition",
  handleClick = () => {},
}) => {
  // const Widget = ({ lastItem, bck, number, name }) => (
  //   <div className={`h-full px-2 py-4 rounded-lg ${id < lastItem ? "mr-5" : ""}`} style={{ background: bck }}>
  //     <p className=" font-bold text-white text-3xl text-center mb-2">
  //       {" "}
  //       {number || number >= 0 ?
  //         <span className="break-words animate-fade">
  //           {number}
  //         </span>
  //       :
  //         <div class="flex justify-center animate-pulse space-x-4">
  //           <div class="py-1">
  //             <div class="h-6 w-10 bg-slate-200 rounded" />
  //           </div>
  //         </div>
  //       }
  //       {" "}
  //     </p>
  //     <p className=" text-white text-center">{name} </p>
  //   </div>
  // );

  return (
    <div className={`w-full h-full py-2 ${id === 1 ? "" : ""} transition-all duration-500 ease-in-out ${scaleOnHover}`}
      onClick={() => { handleClick()}}
    >
      {link ? (
        <Link to={link}>
          <div
            className={`h-full px-3 py-4 rounded-lg ${id < lastItem ? "mr-5" : ""}`}
            style={{ background: bck }}
          >
            <p
              className={`${widgetFont} font-bold text-white text-center mb-2`}
            >
              {" "}
              {number || number >= 0 ? (
                <span className="break-words animate-fade">{number}</span>
              ) : (
                <div class="flex justify-center animate-pulse space-x-4">
                  <div class="py-1">
                    <div class="h-6 w-10 bg-slate-200 rounded" />
                  </div>
                </div>
              )}{" "}
            </p>
            <p className="break-words text-white text-center">{name} </p>
          </div>
        </Link>
      ) : (
        <div
          className={`h-full px-3 py-4 rounded-lg h-full cursor-pointer ${
            id < lastItem ? "mr-5" : ""
          }`}
          style={{ background: bck }}
        >
          <p
            className={`${widgetFont} font-bold text-white text-center mb-2`}
          >
            {" "}
            {number || number >= 0 ? (
              <span className="break-words animate-fade">{number}</span>
            ) : (
              <div class="flex justify-center animate-pulse space-x-4">
                <div class="py-1">
                  <div class="h-6 w-10 bg-slate-200 rounded" />
                </div>
              </div>
            )}{" "}
          </p>
          <p className="break-words text-white text-center">{name} </p>
        </div>
      )}
    </div>
  );
};

export default memo(DashbordWidget);
