import React, { useState, useEffect } from "react";
import ProfileTable from "./Tables/ProfileTable";
import FileName from "../asset/images/file.svg";
import utils from "../utils";

// Redux
import { useSelector } from "react-redux";

const Audit = () => {
  const { audit } = useSelector(state=>state.fiscal);
  const [profile, setprofile] = useState({});
  const [data, setdata] = useState([]);

  const tableHeading = {
    name: "Quarterly Audit Report",
    region: "Date of Entry Conference",
    area: "Date Of Exit Conference",
    district: "Any Major Infraction",
    station: "Actions",
  }; 

  const SetProfile = (info) => {
    const record = audit.list.find(el => el.id === info?.actions?.props['data-id']);
    setprofile(record || info);
  };  

  const TableDetailsInfo = () => (
    <section className="text-lg">
      <div className="w-full flex items-center justify-start pb-3">
        <div className="w-1/2">Quarterly Audit Report</div>
        <div className="text-gray-1 w-1/2 text-end capitalize">{profile.audit_report_file_name || 'N/A'}</div>
      </div>
      <div className="w-full flex items-center justify-start pb-3">
        <div className="w-1/2">Date of Entry Conference</div>
        <div className="text-gray-1 w-1/2 text-end">{new Date(profile.date_of_entry)?.toDateString('Dd MM, YYYY')}</div>
      </div>
      <div className="w-full flex items-center justify-start pb-3">
        <div className="w-1/2">Date Of Exit Conference</div>
        <div className="text-gray-1 w-1/2 text-end">{new Date(profile.date_of_exit)?.toDateString('Dd MM, YYYY')}</div>
      </div>
      <div className="w-full flex items-center justify-start pb-3">
        <div className="w-1/2">Any Major Infraction</div>
        <div className="text-gray-1 w-1/2 text-end">{profile.audit_committee_meeting}</div>
      </div>
      <div className="w-full flex items-center justify-start pb-3">
        <div className="w-1/2">Actions</div>
        <div className="w-1/2 hover:underline">
          {profile.audit_report_binary ?
            // <a className="text-primary text-base hover:underline" href={profile.audit_report_binary} target="_blank" rel="noopener noreferrer" download>Download</a>
            <div className="w-full text-primary text-end cursor-pointer hover:underline" 
              onClick={()=> { 
                utils.downloadBase64(profile.audit_report_binary, profile.audit_report_file_name, "application/pdf")
              }}
            >
              Download
            </div>    
          :
           <div className="w-full text-end">N/A</div>
          }
        </div>  
              
        {/* <div className="text-gray-1 w-1/2 text-end">
          <div data-id={profile.id} className="hover:underline">
            <a className="text-primary hover:underline" href={profile.audit_report_binary} target="_blank" rel="noopener noreferrer" download>View</a>
          </div>          
        </div> */}
      </div>
    </section>
  );

  useEffect(() => {
    let tepmArray = audit?.list?.map((el) => {
      return  {
        name: 
        <div className="flex items-center overflow-hidden"> 
          {el.audit_report_file_name && <img src={FileName} width={20} alt={el.audit_report_file_name} />}
          <span className="pl-2">{el.audit_report_file_name || 'N/A'}</span>
        </div>,
        entry: new Date(el.date_of_entry)?.toDateString('Dd MM, YYYY'),
        exit: new Date(el.date_of_exit)?.toDateString('Dd MM, YYYY'),
        infraction: el.audit_committee_meeting,
        actions:
        <div data-id={el.id} className="hover:underline">
          <span className="text-primary hover:underline">
            View
          </span>
        </div>        
      };
    });

    setdata(tepmArray);
  },[audit]);    

  return (
    <div>
      <ProfileTable
        title="Audit"
        tableHeading={tableHeading}
        list={data}
        SetProfile={SetProfile}
        icon="fileimg"
      >
        <TableDetailsInfo />
      </ProfileTable>
    </div>
  );
};

export default Audit;
