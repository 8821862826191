import React, { useState, useEffect, useCallback } from 'react'
import ProfileTable from './Tables/ProfileTable';
import ProjectPopUp from "./Infrastructure/ProjectPopUp";
import DetailsModal from "./DetailsModal";

import { useSelector } from 'react-redux';

const Others = () => {
  const { officeInfrastructure } = useSelector((state) => state.infrastructure);
  
  const [list, setlist] = useState([]);
  const [current, setcurrent] = useState(1);
  const [showModal, setshowModal] = useState(false);  
  const [profile, setprofile] = useState({});
  const [data, setdata] = useState([]);

  const tableHeading =  {
    name_of_other: "Name of Infrastructure",
    number_of_facilities: "Number of Facilities",
    remarks: "Remarks"
  };

  const SetProfile = (profile) => {
    const record = officeInfrastructure?.list?.find((el) => el.id === profile.id);
    setlist([record.picture_file_url]);      
    setprofile({...profile, ...record});
  };

  const handleShowImag = useCallback((val) => {
    setcurrent(val);
    setshowModal(true);
  }, []);  

  const TableDetailsInfo = () => (
    <section className="text-lg">
    <div className="w-full pb-3">
      <div className="">Name of project</div>
      <div className="text-gray-1 capitalize">{profile.name_of_other || "N/A"}</div>
    </div>
    <div className="w-full pb-3">
      <div className="">Number of facilities</div>
      <div className="text-gray-1  capitalize">{profile.number_of_facilities || "N/A"}</div>
    </div>
    <div className="w-full pb-3">
      <div className="">Construction Status</div>
      <div className="text-gray-1  capitalize">{profile.construction_status || "N/A"}</div>
    </div>
    <div className="w-full pb-3">
      <div className="">Remarks</div>
      <div className="text-gray-1  capitalize">{profile.remarks || "N/A"}</div>
    </div>
    <div className="w-full pb-3">
      <div className="w-full">
        <ProjectPopUp list={list} handleShowImag={handleShowImag} />
      </div>
    </div>    
  </section>
  );
  
  useEffect(() => {
    let tepmArray = officeInfrastructure?.list?.map((el) => {
      return  {
        id: el.id,
        name_of_other: el.name_of_other,
        number_of_facilities: el.number_of_facilities,
        remarks: el.Remarks
      };
    });
    setdata(tepmArray);

  },[officeInfrastructure]);

  return (
    <div>
        <ProfileTable
          title="Other Infrastructure" 
          tableHeading={tableHeading} 
          list={data}
          showDivider 
          SetProfile={SetProfile}
        >
          <TableDetailsInfo />
        </ProfileTable>

        {showModal && (
        <DetailsModal
          current={current}
          showModal={showModal}
          handleClose={() => setshowModal(false)}
          items={list.map((el, index) => {
            return {
              key: index + 1,
              src: el,
            };
          })}
        />
      )}        
    </div>
  );
}

export default Others;