import api from "../../services/config";
import * as types from './types';

export const getUpcomingMeetings = (id) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        let url = '/upcomingmeetings/';
        if (id) {
            // url += id;
            url = `/all_profiles/districts/${id}/deliberative/`
        }

        return api.get(url)
        .then((response) => {
            console.log('Response', response.data);

            dispatch({
                type: types.SET_UPCOMING_MEETINGS,
                payload: response.data
            })

            resolve(response);
        })
        .catch((error) => {
            reject(error);
        })
    });
};

export const getMeetingsHeld = (id) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        let url = '/meetingsheld/';
        if (id) {
            // url += id;
            url = `/all_profiles/districts/${id}/deliberative/`
        }

        return api.get(url)
        .then((response) => {
            console.log('Response', response.data.results);

            dispatch({
                type: types.SET_MEETINGS_HELD,
                payload: response.data.results
            })

            resolve(response);
        })
        .catch((error) => {
            reject(error);
        })
    });
};

export const getDeliberativeById = (id) => async (dispatch) => {
    return new Promise((resolve, reject) => {

        return api.get(`/all_profiles/districts/${id}/deliberative/`)
        .then((response) => {
            console.log('Response', response.data);

            dispatch({
                type: types.SET_DELIBERATIVE,
                payload: response.data
            })

            resolve(response);
        })
        .catch((error) => {
            reject(error);
        })
    });
};