import EnvironmentTabItems from "../component/Environment And Sanitation/EnvironmentTabItems";
import Layout from "../component/Layout/Layout";


function EnvironmentPage() {
  return (
    <Layout pageTitle="Environment And Sanitation">
     <EnvironmentTabItems/>
    </Layout>
  );
}

export default EnvironmentPage;
