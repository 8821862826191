import React, { useState, useEffect } from "react";
import Widget from "../DashbordWidget";
import ProfileTable from "../Tables/ProfileTable";

// Redux
import { getBasicEducation } from "../../redux/SocialServices/action";
import { useDispatch, useSelector } from "react-redux";
import utils from "../../utils";

const tableHeading = {
  name: "District",
  education: "Type",
  enrollment: "Enrollment status",
  dropouts: "No. of Dropouts",
  pass: "Pass rate",
  teachers: "No. of Teachers",
  schools: "No. of Schools",
  deplorable: "No. of Deplorable Schools",
  pupil: "Pupil Teacher Ratio",
  why: "Why Dropouts",
  key: "Key Challenges"
};

const BasicEducation = () => {
  const dispatch = useDispatch();
  const { basicEducation } = useSelector((state) => state.socialServices);
  let pagination = basicEducation.pagination;

  const [tableMeta, setTableMeta] = useState({
    currentPage: 1,
    totalPages: 1,
    total: 0,
    limit: 10,
  });

  const [profile, setprofile] = useState({});
  const [loading, setloading] = useState(false);
  const [data, setdata] = useState([]);

  const SetProfile = (Education) => {
    const updatedBasicEdu = basicEducation?.list?.find(
      (el) => el.id === Education?.id
    );
    console.log("Basic Education data", updatedBasicEdu); 
    setprofile(updatedBasicEdu);
  };

  const dataItems = [
    {
      id: 1,
      number: basicEducation?.meta?.total_preschools,
      link: "",
      name: "Pre-School",
      bck: "#7A6EFE",
    },
    {
      id: 2,
      number: basicEducation?.meta?.total_dropouts,
      link: "",
      name: "Dropouts",
      bck: "#FF5363",
    },
    {
      id: 3,
      number: basicEducation?.meta?.total_teachers,
      link: "",
      name: "Teachers",
      bck: "#FFA901",
    },
  ];

  const TableDetailsInfo = () => (
    <section className="text-lg">
      <div className="w-full flex items-start justify-start pb-1">
        <div className="w-1/2">District</div>
        <div className="text-gray-1 w-1/2">{profile.district}</div>
      </div>
      <div className="w-full flex items-start justify-start pb-1">
        <div className="w-1/2">Education type</div>
        <div className="text-gray-1 w-1/2">{profile.type_of_education}</div>
      </div>
      <div className="w-full flex items-start justify-start pb-1">
        <div className="w-1/2">Enrollment Status</div>
        <div className="text-gray-1 w-1/2">{profile.enrollment}</div>
      </div>
      <div className="w-full flex items-start justify-start pb-1">
        <div className="w-1/2">No. of Dropouts</div>
        <div className="text-gray-1 w-1/2">{profile.dropouts}</div>
      </div>
      <div className="w-full flex items-start justify-start pb-1">
        <div className="w-1/2">Pass Rate</div>
        <div className="text-gray-1 w-1/2">{profile.pass_rate}</div>
      </div>
      <div className="w-full flex items-start justify-start pb-1">
        <div className="w-1/2">Absence</div>
        <div className="text-gray-1 w-1/2">{profile.absence}</div>
      </div>
      <div className="w-full flex items-start justify-start pb-1">
        <div className="w-1/2">No. Of Teachers</div>
        <div className="text-gray-1 w-1/2">{profile.no_of_teachers}</div>
      </div>
      <div className="w-full flex items-start justify-start pb-1">
        <div className="w-1/2">Schools Under Trees</div>
        <div className="text-gray-1 w-1/2">
          {profile.schools_under_trees_or_without_classroom}
        </div>
      </div>
      <div className="w-full flex items-start justify-start pb-1">
        <div className="w-1/2">School Feeeding</div>
        <div className="text-gray-1 w-1/2">{profile.school_feeding}</div>
      </div>
    </section>
  );

  const fetchBasicEducation = (page, limit, search="") => {
    setloading(true);
    dispatch(getBasicEducation(page, limit, search))
    .then(() => {
      setloading(false);
    },() => {
      setloading(false);
    });
  };  

  const handleBasicEducationFetch = (type, value) => {
    switch (type) {
      case 'limit':
        setTableMeta((prev) => { return { ...prev, currentPage: 1, limit: value } });
        fetchBasicEducation(1, value);
      break;

      case 'currentPage':
        setTableMeta((prev) => { return {  ...prev, currentPage: value }});        
        fetchBasicEducation(value, tableMeta.limit);
      break;
    
      default:
        break;
    }
  };

  useEffect(() => {
    let tepmArray = basicEducation?.list?.map((el) => {
      return {
        id: el.id,
        name: el.district,
        education: el.type_of_education,
        enrollment: utils.numberWithCommas(el.enrollment),
        dropouts: el.dropouts,
        pass: el.pass_rate,
        teachers: utils.numberWithCommas(el.no_of_teachers),
        schools: el.no_of_schools,
        deplorable: el.n_deplorable_schools,
        pupil: el.pupil_teacher_ratio,
        why: el.why_dropouts,
        key: el.key_challenges
      };
    });

    setdata(tepmArray);

    // if revenue.pagination is available
    if (utils.present(basicEducation?.pagination)) {
      setTableMeta({
        ...tableMeta,
        total: pagination?.count,
        totalPages: pagination.totalPages,
        // limit: pagination.limit
      });
    }
  }, [basicEducation]);

  useEffect(() => {
    fetchBasicEducation()
  }, []);

  return (
    <div>
      <div className="w-full flex flex-row">
        {dataItems.map((item) => (
          <div className="w-full sm:w-1/4">
            <Widget
              id={item.id}
              link={item.link}
              number={item.number}
              name={item.name}
              bck={item.bck}
              lastItem={dataItems.length}
            />
          </div>
        ))}
      </div>

      <ProfileTable
        title="Basic Education"
        tableHeading={tableHeading}
        SetProfile={SetProfile}
        list={data}
        hasFilter
        hasPagination
        entity="List"
        loading={loading}
        meta={tableMeta}
        fetchList={handleBasicEducationFetch}
        handleSearch={(e) => fetchBasicEducation(1, 10, e)}
      >
        <TableDetailsInfo />
      </ProfileTable>
    </div>
  );
};

export default BasicEducation;
