import React, { useState, useEffect } from 'react';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';

// Components
import ScheduledMeeting from "./ScheduledMeeting";
import UpcomingMetings from "./UpComingMeetings";
import Widget from "../DashbordWidget";
import { useSelector } from 'react-redux';

function DeliberativeFunction() {
  const { upcomingMeetings, meetingsHeld, meta } = useSelector(state=>state.deliberative)

  const today = new Date();
  const [selected, setSelected] = useState(today);
  const [dataItems, setDataItems] = useState([]); 

  useEffect(() => {
    const tmpArr = [];

    Object.keys(meta).forEach((oneKey, i) => {
      tmpArr.push({
        id: i+1,
        number: meta[oneKey],
        name: oneKey,
        bck: i === 0 ? "#FF5363" : i === 1 ? '#24A8FA' : '#7A6EFE'
      })
    })

    setDataItems(tmpArr);
  }, [meta]);

  return (
      <div className="w-full">
        <div className="grid grid-cols-12 gap-x-2">
          <div className=" col-span-8 ">
            <div className="w-full flex flex-row">
              {dataItems.map((item) => (
                <div className="w-1/2 sm:w-1/3">
                  <Widget id={item.id} number={item.number} name={item.name} bck={item.bck} lastItem={dataItems.length} />
                </div>
              ))}
            </div>

            <ScheduledMeeting 
              upcomingMeetings={upcomingMeetings}
              meetingsHeld={meetingsHeld}
            />
          </div>

          <div className="col-span-4 pl-4 rounded-lg">
            <div className="pb-3 mb-4 -mt-4 rounded-lg">
              <DayPicker
                mode="single"
                required
                selected={selected}
                onSelect={setSelected}
                footer={<div></div>}
              />

              <div className="px-4 pt-4">
                <h1 className="text-2xl mb-0 font-semibold">Upcoming Meetings</h1>
                <span className=" text-gray-400 text-sm">
                  Don't miss scheduled events
                </span>
              </div>

              <div>
                <UpcomingMetings meetings={upcomingMeetings} />
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}

export default DeliberativeFunction;
