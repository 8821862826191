import React, { useState, useEffect } from "react";
import Widget from "../DashbordWidget";
import ProfileTable from "../Tables/ProfileTable";
import utils from '../../utils'

import { getWaterCoverage } from "../../redux/SocialServices/action";
import { useDispatch, useSelector } from "react-redux";



const tableHeading =  {
  name: "District",
  population: "Population with access to Portable Water",
  service: "service provider",
  source: "water source",
};    
  


const WaterCoverage = () => {
  const dispatch = useDispatch();
  const { waterCoverage } = useSelector((state) => state.socialServices);
  let pagination = waterCoverage.pagination

  const [tableMeta, setTableMeta] = useState({
    currentPage: 1,
    totalPages: 1,
    total: 0,
    limit: 10    
  });  

  const [profile, setprofile] = useState({});
  const [data, setdata] = useState([])
  const [loading, setloading] = useState(false)

  const SetProfile = (waterCoverage) => {
    setprofile(waterCoverage);
  };

  const dataItems = [
    {
      id: 1,
      number: utils.numberWithCommas(waterCoverage?.meta?.Access_to_potable_water),
      link: "",
      name: "Access to Portable Water",
      bck: "#7A6EFE",
    },
    {
      id: 2,
      number: utils.numberWithCommas(waterCoverage?.meta?.Pipeborne),
      link: "",
      name: "Pipeborne",
      bck: "#FF5363",
    },
    {
      id: 3,
      number: utils.numberWithCommas(waterCoverage?.meta?.Boreholes),
      link: "",
      name: "Boreholes",
      bck: "#FFA901",
    },
    {
      id: 4,
      number: utils.numberWithCommas(waterCoverage?.meta?.Stream_or_river),
      link: "",
      name: "Stream or River",
      bck: "#24A8FA",
    },
  ];

  const TableDetailsInfo = () => (
    <section className="text-lg">
      <div className="w-full flex items-start justify-start pb-2">
        <div className="w-1/2">District</div>
        <div className="w-1/2 text-gray-1">{profile.name}</div>
      </div>
      <div className="w-full flex items-start justify-start pb-2">
        <div className="w-1/2">Population with access to Portable Water</div>
        <div className="w-1/2 text-gray-1">{profile.population}</div>
      </div>
      <div className="w-full items-start justify-start pb-2">
        <div className="">Water source</div>
        <div className=" text-gray-1">{profile.source}</div>
      </div>
      <div className="w-full flex items-start justify-start pb-2">
        <div className="w-1/2">Service provider</div>
        <div className="w-1/2 text-gray-1">{profile.service}</div>
      </div>
      <div className="w-full items-start justify-start pb-2">
        <div className="">How Many Communities Without Portable Water</div>
        <div className=" text-gray-1">{profile.communities}</div>
      </div>
    </section>
  );

  const fetchWaterCoverage = (page, limit, search="") => {
    setloading(true);
    dispatch(getWaterCoverage(page, limit, search))
    .then(() => {
      setloading(false);
    }, () => {
      setloading(false);
    })
  };  

  const handleWaterCoverageFetch = (type, value) => {
    switch (type) {
      case 'limit':
        setTableMeta((prev) => { return { ...prev, currentPage: 1, limit: value } });
        fetchWaterCoverage(1, value);
      break;

      case 'currentPage':
        setTableMeta((prev) => { return {  ...prev, currentPage: value }});        
        fetchWaterCoverage(value, tableMeta.limit);
      break;
    
      default:
        break;
    }
  };    

  useEffect(() => {
    let tepmArray = waterCoverage?.list?.map((el) => {
      return  {
        name: el.district,
        population: utils.numberWithCommas(el.population_with_access_to_portable_water),
        service: el.service_provider,
        source: el.water_source,
      };
    });
    setdata(tepmArray);

    // if revenue.pagination is available
    if (utils.present(waterCoverage?.pagination)) {
      setTableMeta({
        ...tableMeta,
        total: pagination?.count,
        totalPages: pagination.count % tableMeta.limit !== pagination?.count ? pagination?.count % tableMeta.limit || 1 : 1
      });
    }
  },[waterCoverage]); 

  useEffect(() => {
    fetchWaterCoverage();
  }, []);


  return (
    <div>
      <div className="w-full flex flex-row">
        {dataItems.map((item) => (
          <div className="w-full sm:w-1/4">
            <Widget
              id={item.id}
              link={item.link}
              number={item.number}
              name={item.name}
              bck={item.bck}
              lastItem={dataItems.length}
            />
          </div>
        ))}
      </div>

      <ProfileTable
        title="Water Coverage"
        tableHeading={tableHeading}
        SetProfile={SetProfile}
        list={data}
        hasFilter
        hasPagination
        entity="List"
        loading={loading}
        meta={tableMeta}
        fetchList={handleWaterCoverageFetch}
        handleSearch={(e) => fetchWaterCoverage(1, 10, e)}
      >
        <TableDetailsInfo />
      </ProfileTable>
    </div>
  );
};

export default WaterCoverage;