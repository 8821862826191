import React, { useState, useEffect, useCallback } from 'react'
import Layout from "../component/Layout/Layout";

import { tableHeading as helperheading, SetTableBody} from "../component/SpecialInitiative/helper";
import Widget from '../../src/component/DashbordWidget'
import ProfileTable from "../component/Tables/ProfileTable";
import utils from '../utils'

import { useDispatch, useSelector } from 'react-redux';
import { getSpecialInitiatives, getDetails } from '../redux/SpecialInitiatives/action';

// Components
import DetailsPage from "../component/Fiscal/DetailsPage";

const meta_colors = ["#7A6EFE", "#FF5363", "#FFA901"];

const SpecialInitiatives = () => {
  const dispatch = useDispatch()
  const { specialInitiatives, details } = useSelector(state=>state.specialInitiatives);
  let { pagination, meta } = specialInitiatives;

  const [tableMeta, setTableMeta] = useState({});  
  const [profile, setprofile] = useState({});
  const [data, setdata] = useState([]);
  const [detailsList, setdetailsList] = useState([]);
  const [loading, setloading] = useState(false);
  const [viewDetails, setviewDetails] = useState(false);
  const [dataItems, setdataItems] = useState([]);
  const [metaInfo, setmetaInfo] = useState({});

  const tableHeading = {
    name: "Flagship Programmes",
    completion: "Status of Completion",
    feedback: "Feedback on the Performance of the Flagship Project",
    // link: "Project Link"
  };  

  const SetProfile = (specialInitiatives) => {
    setprofile(specialInitiatives);
  };

  const TableDetailsInfo = () => (
    <section className="text-lg">
      <div className="w-full flex items-center justify-between pb-3">
        <div className="w-1/2">Flagship Programmes</div>
        <div className="text-gray-1 text-end">{profile.flagship || "N/A"}</div>
      </div>
      <div className="w-full flex items-center justify-between pb-3">
        <div className="w-1/2">Status of Completion</div>
        <div className="text-gray-1 text-end">{profile.completion || "N/A"}</div>
      </div>
      <div className="w-full pb-3">
        <div className="">Feedback on the Performance of the Flagship Project</div>
        <div className="text-gray-1">{profile.feedback || "N/A"}</div>
      </div>
    </section>
  );

  const fetchSpecialInitiative = (page, limit) => {
    setloading(true);
    dispatch(getSpecialInitiatives(page, limit))
    .then(() => {
      setloading(false);
    }, () => {
      setloading(false);
    })
  };

  const handleGetDetails = async (name, page, limit) => {
    setloading(true);

    dispatch(getDetails(name, page, limit))
    .then((response) => {
      setloading(false);

      setTableMeta((prev) => {
        return { 
          ...prev, 
          currentPage: page, 
          limit: parseInt(response?.limit),
          totalPages: response?.pages,
          total: response?.count
        };
      });

      setviewDetails(true);
    })    
  };

  const handleSpecialInitFetch = (type, value) => {
    switch (type) {
      case 'limit':
        if (viewDetails) {
          handleGetDetails(metaInfo.name, 1, value);
        } else {
          setTableMeta((prev) => {
            return { ...prev, currentPage: 1, limit: value,  };
          });
          fetchSpecialInitiative(1, value);
        }        
      break;

      case 'currentPage':
        if (viewDetails) {
          handleGetDetails(metaInfo.name, value, tableMeta.limit);
        } else {
          setTableMeta((prev) => {
            return { ...prev, currentPage: value };
          });
          fetchSpecialInitiative(value, tableMeta.limit);
        }        
      break;
    
      default:
        break;
    }
  };   

  const handleClick = useCallback((item) => {
    setmetaInfo(item);
    handleGetDetails(item.name, 1, 10);
  }, []);  

  useEffect(() => {
    let tepmArray = specialInitiatives?.list?.map((el) => {
      return  {
        // name: el.district,
        flagship: el.program,
        completion: el.status,
        feedback: el.feedback,
        // link: <a className="underline text-blue-500" href={el.url} target="_blank" rel="noreferrer">View</a>
      };
    });

    setdata(tepmArray);

    // if revenue.pagination is available
    if (utils.present(specialInitiatives?.pagination)) {
      setTableMeta({
        ...tableMeta,
        total: pagination?.count,
        totalPages: pagination.totalPages,
        // limit: pagination.limit
      });
    }
  },[specialInitiatives]); 

  useEffect(() => {
    if (utils.present(meta)) {
      const tempDataItems = Object.keys(meta).map((oneKey, i) => {
        return {
          id: i+1,
          number: meta[oneKey],
          link: "",
          name: oneKey,
          bck: meta_colors[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
        }
      });
      setdataItems(tempDataItems);
    }
  }, [meta]);   

  useEffect(() => {
    let tepmArray = details?.list?.map((el) => {
      return {
        id: el.id,
        name: el.district,
        name_of_factory: el?.name_of_factory,
        no_of_factories: el?.no_of_factories,
        location_of_factory: el?.location_of_factory,
        status: (
          <div className="text-center text-white bg-green-600 rounded-full p-1">
            {el.status_of_factory || "N/A"}
          </div>
        ),
        expected_no_of_jobs: <div className="text-center">{el?.expected_no_of_jobs}</div>,
        nature_of_business: el?.nature_of_business,
        feedback_on_performance: el?.feedback_on_performance,
      }
    }); 

    setdetailsList(tepmArray);
  }, [details]);  

  useEffect(() => {
    if (!viewDetails) {
      setTableMeta({
        currentPage: 1,
        totalPages: 1,
        total: 0,
        limit: 10,
      });
      
      fetchSpecialInitiative();
    }    
  }, [viewDetails]);

  return (
    <Layout pageTitle="Special Initiatives">
      {viewDetails ?
        <DetailsPage 
          active={4}
          setviewDetails={setviewDetails} 
        >
          <ProfileTable
            title={metaInfo.name}
            tableHeading={helperheading(metaInfo.name)}
            list={SetTableBody(metaInfo.name, details?.list)}
            hasFilter={false}
            entity={metaInfo.name}
            meta={tableMeta}
            loading={loading}
            fetchList={handleSpecialInitFetch}
            hasPagination
            showModal={false}
          />          
        </DetailsPage>
      :
        <>
          <div className="flex flex-row overflow-y-hidden h-36">
            {dataItems?.length > 0 ?
              dataItems.map((item) => (
                <div className="w-1/2 sm:w-auto" style={{ minWidth: "15rem"}}>
                  <Widget 
                    id={item.id} 
                    link={item.link} 
                    number={item.number} 
                    name={item.name} 
                    bck={item.bck} 
                    lastItem={dataItems.length} 
                    handleClick={() => handleClick(item)}
                  />
                </div>
              ))
              :
              new Array(5).fill()?.map(() => (
                <div class="w-1/4 flex justify-center animate-pulse mr-5">
                  <div class="h-full w-full bg-slate-200 rounded-lg" />
                </div>
              ))        
            }
          </div>

          <ProfileTable
            title="Special Initiatives"
            tableHeading={tableHeading}
            SetProfile={SetProfile}
            list={data}
            hasFilter={false}
            hasPagination
            entity='Initiatives'
            loading={loading}
            meta={tableMeta}
            fetchList={handleSpecialInitFetch}
          >
            <TableDetailsInfo />
          </ProfileTable>
        </>
      }
    </Layout>
  );
}

export default SpecialInitiatives;
