import * as types from "./types";

const initState = {
  meta: {},
  revenue: {
    list: [],
    meta: {},
    pagination: {},
    chart: []  
  },
  expenditure: {
    list: [],
    meta: {},
    pagination: {},
    chart: []  
  },
  audit: {
    list: [],
    meta: {},
    pagination: {},  
  },
  infraction: {
    list: [],
    pagination: {}
  }
};

export const fiscal = (state = initState, action) => {
  switch (action.type) {
    case types.REVENUE:

      return {
        ...state,
        revenue: {
          ...state.revenue,
          list: action.payload.results, 
          meta: action.payload.meta,
          pagination: { 
            count: action.payload.count,
            totalPages: action.payload.pages,
            limit: action.payload.limit,
            next: action.payload.next, 
            previous: action.payload.previous 
          }
        }
      };

    case types.EXPENDITURE:
      return {
        ...state,
        expenditure: { 
          ...state.expenditure,
          list: action.payload.results, 
          meta: action.payload.meta,
          pagination: { 
            count: action.payload.count,
            totalPages: action.payload.pages,
            limit: action.payload.limit,
            next: action.payload.next, 
            previous: action.payload.previous 
          }
        }
      };

    case types.AUDIT:
      return {
        ...state,
        audit: { 
          ...state.audit,
          list: action.payload.results, 
          meta: action.payload.meta,
          pagination: { 
            count: action.payload.count,
            totalPages: action.payload.pages,
            limit: action.payload.limit,
            next: action.payload.next, 
            previous: action.payload.previous 
          }
        }
      };

    case types.SET_FISCAL:
      const payload = action.payload;

      return {
        ...state,
        meta: payload.meta,
        revenue: {
          ...state.revenue,
          list: payload.results[0]?.revenue,
        },
        expenditure: { 
          ...state.expenditure,
          list: payload.results[0]?.expenditure,
        },
        audit: {
          ...state.audit,
          list: payload.results[0]?.audit,
        }
      };
    
    case types.SET_REVENUE_CHARTS:
      return {
        ...state,
        revenue: {
          ...state.revenue,
          chart: action.payload
        }
      }

    case types.SET_EXPENDITURE_CHARTS:
      return {
        ...state,
        expenditure: {
          ...state.expenditure,
          chart: action.payload
        }
      }

    case types.SET_AUDIT_INFRACTION: 
      return {
        ...state,
        infraction: {
          list: action.payload.results,
          pagination: { 
            count: action.payload.count,
            totalPages: action.payload.pages,
            limit: action.payload.limit
          }
        }
      }

    default:
      return state;
  }
};
