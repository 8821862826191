import React from "react";
import {
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";

const FilterModal = ({ showModal = false, handleClose = () => {} }) => {
  const regions = [
    {
      id: 1,
      name: "Greater Accra",
    },
    {
      id: 2,
      name: "Central",
    },
    {
      id: 3,
      name: "Volta",
    },
    {
      id: 4,
      name: "Eastern",
    },
  ];

  return (
    <Modal className="rounded-lg" isOpen={showModal} centered>
      <ModalHeader close={null}>Filter</ModalHeader>
      <ModalBody className="bg-gray-100">
        <Form>
          <FormGroup>
            <Label for="regionSelect" className="font-medium">
              Select Region
            </Label>
            <Input id="regionSelect" name="select" type="select">
              {regions.map((el) => (
                <option key={el.id}>{el.name}</option>
              ))}
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="districtSelect" className="font-medium">
              Select District
            </Label>
            <Input id="districtSelect" name="select" type="select">
              {regions.map((el) => (
                <option key={el.id}>{el.name}</option>
              ))}
            </Input>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="plain" onClick={handleClose} className="hover:bg-white" outline>
          Cancel
        </Button>{" "}
        <Button color="primary" onClick={handleClose}>
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default FilterModal;
