import InfrastructureTabItems from "../component/Infrastructure/InfrastructureTabItems";
import Layout from "../component/Layout/Layout";


function InfrastructurePage() {
  return (
    <Layout pageTitle="Infrastructure">
      <InfrastructureTabItems/>
    </Layout>
  );
}

export default InfrastructurePage;
