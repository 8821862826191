import React, {useState, useEffect} from 'react'
import ProfileTable from '../Tables/ProfileTable'

//Redux
import { getOtherSecurity } from 'src/redux/SocialServices/action';
import { useDispatch, useSelector } from 'react-redux';
import utils from '../../utils'

const tableHeading = {
    name: "District",
    service: "Name of Security Service",
    station: "no. of Stations",
    personnel: "no of Personnel",
    crime: "Reported crimes"
};

const Other = () => {
  const dispatch = useDispatch();
  const { security } = useSelector((state) => state.socialServices);
  let { pagination } = security?.other;

  const [tableMeta, setTableMeta] = useState({
    currentPage: 1,
    totalPages: 1,
    total: 0,
    limit: 10,
  });

  const [profile, setprofile] = useState({});
  const [loading, setloading] = useState(false);
  const [data, setdata] = useState([]);

  const SetProfile = (Others) => {
    const updatedOthers = security.other?.list?.find((el) => el.id === Others?.id);

    setprofile(updatedOthers);
  };

  const TableDetailsInfo = () => (
    <section className="text-lg">
      <div className="w-full flex items-center justify-start pb-1">
        <div className="w-1/2">District</div>
        <div className="text-gray-1">{profile.district}</div>
      </div>
      <div className="w-full flex items-center justify-start pb-1">
        <div className="w-1/2">Name of Security Service</div>
        <div className="text-gray-1">{profile.name_of_other}</div>
      </div>
      <div className="w-full flex items-center justify-start pb-1">
        <div className="w-1/2">No. of Stations</div>
        <div className="text-gray-1">{profile.no_of_stations}</div>
      </div>
      <div className="w-full flex items-center justify-start pb-1">
        <div className="w-1/2">No of Personnel</div>
        <div className="text-gray-1">{profile.no_of_personnels}</div>
      </div>
      <div className="w-full flex items-center justify-start pb-1">
        <div className="w-1/2">Reported crimes</div>
        <div className="text-gray-1">{profile.no_of_reported_crimes}</div>
      </div>
    </section>
  );

  const fetchOthers = (page, limit, search = "") => {
    setloading(true);
    dispatch(getOtherSecurity(page, limit, search)).then(
      () => {
        setloading(false);
      },
      () => {
        setloading(false);
      }
    );
  };

  const handleOthersFetch = (type, value) => {
    switch (type) {
      case 'limit':
        setTableMeta((prev) => { return { ...prev, currentPage: 1, limit: value } });
        fetchOthers(1, value);
      break;

      case 'currentPage':
        setTableMeta((prev) => { return {  ...prev, currentPage: value }});        
        fetchOthers(value, tableMeta.limit);
      break;
    
      default:
        break;
    }
  };

  useEffect(() => {
    let tepmArray = security?.other?.list?.map((el) => {
      return {
        id: el.id,
        name: el.district,
        service: el.name_of_other,
        station:el.no_of_stations,
        personnel: el.no_of_personnels,
        crime:el.no_of_reported_crimes
      };
    });

    setdata(tepmArray);

    // if revenue.pagination is available
    if (utils.present(security.other?.pagination)) {
      setTableMeta({
        ...tableMeta,
        total: pagination?.count,
        totalPages: pagination.totalPages,
      });
    }
  }, [security]);

  useEffect(() => {
    fetchOthers();
  }, []);

  return (
    <div>
        <ProfileTable
            title="Other Security"
            showTitle={false}
            tableHeading={tableHeading}
            SetProfile={SetProfile}
            list={data}
            hasPagination
            hasFilter
            entity="List"
            loading={loading}
            meta={tableMeta}
            fetchList={handleOthersFetch}
            handleSearch={(e) => fetchOthers(1, 10, e)}
        >
            <TableDetailsInfo />
      </ProfileTable>
    </div>
  )
}

export default Other