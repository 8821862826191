import React from "react";

const TravelCard = ({ name, label, start, end, icon="", title, sub_title="text-bas" }) => {
  return (
    <div className="w-full">
      <div className="bg-gray-2 rounded-lg p-2 my-2 mx-2">
        <div className="text-blue-500 text-sm mb-2 ">{name}</div>

        {title &&
          <h2 className="pb-2 text-lg">{title}</h2>
        }

        <p className="flex items-center font-semibold mb-4">
          {icon && 
            <div className="flex items-center justify-center w-6 h-6 rounded-full bg-white hover:scale-110 transform transition mr-2">
              <img src={icon} alt="" />
            </div>
          }
          <span className={sub_title}>{label}</span>
        </p>

        <div className=" text-sm rounded-lg px-2 bg-white mb-2 py-1 items-center">
          <div className="flex justify-between items-center mb-2">
            <b>Start:</b>
            {new Date(start || 'N/A')?.toDateString()}
          </div>
          <div className="flex justify-between items-center mb-2">
            <b>End:</b>
            {new Date(end || 'N/A')?.toDateString()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TravelCard;
