import api from "../../services/config";
import * as types from './types';

export const getStaffingData = (page=1, limit=10, search=" ") => async (dispatch) => {
    return new Promise((resolve, reject) => {
        let url = `/staffingdata/`;
        if (page) url += `?page=${page}`
        if (limit) url += `&limit=${limit}`
        if (search) url += `&search=${search}`;

        return api.get(url)
        .then((response) => {
            console.log('Response', response.data);

            dispatch({
                type: types.STAFFING_DATA,
                payload: response.data
            });

            resolve(response);
        })
        .catch((error) => {
            reject(error);
        })
    });
};

export const getIteneraries = (search="") => async (dispatch) => {
    return new Promise((resolve, reject) => {
        let url = `/itineraries/?limit=1000`;
        if (search) url += `&search=${search}`;
        return api.get(url)
        .then((response) => {
            console.log('Response', response.data);

            dispatch({
                type: types.ITENERARIES,
                payload: response.data
            })

            resolve(response);
        })
        .catch((error) => {
            reject(error);
        })
    });
};

export const getCapacityPlans = (search="") => async (dispatch) => {
    return new Promise((resolve, reject) => {
        let url = '/capacityplans/?limit=400';
        if (search) url += `&search=${search}`
        return api.get(url)
        .then((response) => {
            console.log('Response', response.data.results);

            dispatch({
                type: types.CAPACITY_PLANS,
                payload: response.data.results
            })

            resolve(response);
        })
        .catch((error) => {
            reject(error);
        })
    });
};

export const getCapacityImplemented = (search="") => async (dispatch) => {
    return new Promise((resolve, reject) => {
        let url = '/capacity_implemented/?limit=400';
        if (search) url += `?search=${search}`
        return api.get(url)
        .then((response) => {
            console.log('Response', response.data.results);

            dispatch({
                type: types.CAPACITY_IMPLEMENTED,
                payload: response.data.results
            })

            resolve(response);
        })
        .catch((error) => {
            reject(error);
        })
    });
};

export const getComplaints = () => async (dispatch) => {
    return new Promise((resolve, reject) => {
        return api.get('/complaints/')
        .then((response) => {
            console.log('Response', response.data.results);

            dispatch({
                type: types.COMPLAINTS,
                payload: response.data.results
            })

            resolve(response);
        })
        .catch((error) => {
            reject(error);
        })
    });
};

export const getAdministrativeById = (id) => async (dispatch) => {
    return new Promise((resolve, reject) => {

        return api.get(`/all_profiles/districts/${id}/administrative/`)
        .then((response) => {
            dispatch({
                type: types.SET_ADMINISTRATIVE,
                payload: response.data
            })
            resolve(response);
        })
        .catch((error) => {
            reject(error);
        })
    });
};

