import React, { useEffect } from "react";

import Widget from "../../component/DashbordWidget";
import Audit from "../Audit";
import Revenue from "../Revenue";
import Expenditure from "../Expenditure";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { getFiscalById } from "../../redux/fiscal/action";

const Fiscal = ({ id }) => {
  const dispatch = useDispatch();
  const { meta } = useSelector(state=>state.fiscal);

  const dataItems = [
    {
      id: 1,
      number: meta?.Projected_Revenue,
      link: "",
      name: "Projected Revenue",
      bck: "#7A6EFE",
    },
    {
      id: 2,
      number: meta?.Actual_Revenue,
      link: "",
      name: "Actual Revenue",
      bck: "#FF5363",
    },
    {
      id: 3,
      number: meta?.Revenue_Variance,
      link: "",
      name: "Revenue Variance",
      bck: "#FFA901",
    },
    {
      id: 4,
      number: meta?.Projected_Expenditure,
      link: "",
      name: "Projected Expenditure",
      bck: "#7A6EFE",
    },
    {
      id: 5,
      number: meta?.Actual_Expenditure,
      link: "",
      name: "Actual Expenditure",
      bck: "#FF5363",
    },
    {
      id: 6,
      number: meta?.Expenditure_Variance,
      link: "",
      name: "Expenditure Variance",
      bck: "#FFA901",
    }
  ];  

  useEffect(() => {
    dispatch(getFiscalById(id))
  }, [id])
  
  return (
    <div>
      <div className="flex flex-row overflow-y-hidden">
        {dataItems.map((item) => (
          <div className="w-48 sm:w-72">
            <Widget
              id={item.id}
              link={item.link}
              number={item.number}
              name={item.name}
              bck={item.bck}
              widgetFont="text-2xl"
              scaleOnHover=""
              lastItem={dataItems.length}
            />
          </div>
        ))}
      </div>

      <Revenue />

      <Expenditure />

      <Audit />
    </div>
  );
};

export default Fiscal;
