import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { Dropdown, DropdownItem, DropdownToggle, DropdownMenu, NavLink, NavItem } from "reactstrap";

// Images
import Logout from  "../../asset/images/MenuIcons/Logout.svg";
import ProfileIcon from "../../asset/images/navbarMenus/pfofileIcons/ProfileIcon";
import MessagesIcon from "../../asset/images/navbarMenus/pfofileIcons/MessagesIcon";
import TasksIcon from "../../asset/images/navbarMenus/pfofileIcons/TasksIcon";
import basketIcon from "../../asset/images/navbarMenus/basketIcon.svg";
import calendarIcon from "../../asset/images/navbarMenus/calendarIcon.svg";
import envelopeIcon from "../../asset/images/navbarMenus/envelopeIcon.svg";
import mariaImage from "../../asset/images/navbarMenus/mariaImage.jpg";
import notificationImage from "../../asset/images/navbarMenus/notificationImage.jpg";
import userImg from "../../asset/images/Profile.jpg";

import s from "./Header.module.scss";

const Header = ({
  pageTitle="",
  breadcrumb=[]
}) => {
  let navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const [notificationsOpen, setNotificationsOpen] = useState(false);

  const toggleNotifications = () => {
    setNotificationsOpen(!notificationsOpen);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const doLogout = () => {
    sessionStorage.removeItem("Token");
    toast.info("User logged out");
    navigate("/");
  }  

  return (
    <section className="w-full">
      <div className="w-full flex justify-between items-center p-8">
        <div className="w-full sm:w-3/5">
          <h1 className="mb-0 text-2xl font-bold">{pageTitle}</h1>
        </div>

        <div className="flex items-center justify-center">
          <ul className={`${s.profile_logo} px-0 m-0 flex items-center justify-center`}>
            <Dropdown nav isOpen={menuOpen} toggle={() => toggleMenu()} className="mr-2">
              <DropdownToggle nav>
                <div className={`${s.navbarBlock} positoin-relative`}>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 17.8476C17.6392 17.8476 20.2481 17.1242 20.5 14.2205C20.5 11.3188 18.6812 11.5054 18.6812 7.94511C18.6812 5.16414 16.0452 2 12 2C7.95477 2 5.31885 5.16414 5.31885 7.94511C5.31885 11.5054 3.5 11.3188 3.5 14.2205C3.75295 17.1352 6.36177 17.8476 12 17.8476Z" stroke="#000D1F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M14.3889 20.8574C13.0247 22.3721 10.8967 22.3901 9.51953 20.8574" stroke="#000D1F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>

                  <div className={s.count}></div>
                </div>
              </DropdownToggle>

              <DropdownMenu right className="navbar-dropdown notifications-dropdown" style={{ width: "340px" }}>
                <DropdownItem><img src={basketIcon} alt="Basket Icon"/><span>12 new orders have arrived today</span></DropdownItem>
                <DropdownItem>
                  <div>
                    <div className="d-flex flex-row mb-1">
                      <img src={mariaImage} alt="Maria" className={s.mariaImage} />
                      <div className="d-flex flex-column">
                        <p className="body-3">Maria</p>
                        <p className="label muted">15 min ago</p>
                      </div>
                    </div>
                    <img src={notificationImage} alt="Notification Icon" className={s.notificationImage}/>
                    <p className="body-2 muted">It is just a simple image that can define th..</p>
                  </div>
                </DropdownItem>
                <DropdownItem><img src={calendarIcon} alt="Calendar Icon"/><span>1 event has been canceled and ...</span></DropdownItem>
                <DropdownItem><img src={envelopeIcon} alt="Envelope Icon"/><span>you have 2 new messages</span></DropdownItem>
              </DropdownMenu>
            </Dropdown>

            <Dropdown isOpen={notificationsOpen} toggle={() => toggleNotifications()} nav id="basic-nav-dropdown">
              <DropdownToggle nav className="navbar-dropdown-toggle text-black d-flex justify-content-center align-items-center bg-transparent px-0">
                <div className={`${s.avatar} rounded-circle mr-2`}>
                  <img src={userImg} alt="User"/>
                </div>
                <div className="d-none d-sm-block text-base pr-2">Arman Rokni</div>
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                  <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                </svg>              
              </DropdownToggle>

              <DropdownMenu className="navbar-dropdown profile-dropdown" style={{ width: "194px" }}>
                <DropdownItem className={s.dropdownProfileItem}><ProfileIcon/><span>Profile</span></DropdownItem>
                <DropdownItem className={s.dropdownProfileItem}><TasksIcon/><span>Tasks</span></DropdownItem>
                <DropdownItem className={s.dropdownProfileItem}><MessagesIcon/><span>Messages</span></DropdownItem>
                <NavItem>
                  <NavLink onClick={() => doLogout()} href="#">
                    {/* <button className="btn btn-primary w-full flex items-center" type="submit"><img src={logoutIcon} alt="Logout"/><span className="ml-1">Logout</span></button> */}
                    <Link to="/" className="w-full flex items-center w-full text-danger-1 cursor-pointer">
                      <img src={Logout} className="w-5" alt="" />
                      <span className="pl-3">Logout</span>
                    </Link>                  
                  </NavLink>
                </NavItem>
              </DropdownMenu>
            </Dropdown>
          </ul>
        </div>
      </div>

      {breadcrumb.length > 0 &&
        <nav className="px-8" aria-label="breadcrumb">
          <ol className="breadcrumb">
            {breadcrumb.map((el, index) => (
              <li className="breadcrumb-item text-base" aria-current="page" key={index}>
                <Link to={el.link || ''}>{el.name}</Link>
              </li>
            ))}
          </ol>
        </nav>        
      }
    </section>
  )
}

export default Header