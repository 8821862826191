import * as types from "./types";

const initState = {
  meta: {},
  upcomingMeetings: [],
  meetingsHeld: [],
};

export const deliberative = (state = initState, action) => {
  switch (action.type) {
    case types.SET_UPCOMING_MEETINGS:

      return {
        ...state,
        meta: action.payload?.meta,
        upcomingMeetings: action.payload?.results,
      };

    case types.SET_MEETINGS_HELD:

      return {
        ...state,
        meetingsHeld: action.payload,
      };

    case types.SET_DELIBERATIVE:
      console.log('Payload:', action.payload);
      const { meta, results } = action.payload;
      let tmp_meta = {};

      Object.keys(meta).forEach((key) => {
        if (key.includes("Upcoming")) tmp_meta[key] = meta[key];
      })

      return {
        ...state,
        meta: tmp_meta,
        upcomingMeetings: results[0]?.upcoming,
        meetingsHeld: results[0]?.held
      };

    default:
      return state;
  }
};
