import React, { useState, useEffect } from "react";
import ProfileTable from "./Tables/ProfileTable";
import { useSelector } from "react-redux";

const tableHeading = {
  stations: "No. Of Stations",
  personnel: "No. Of Personnel",
  domestic: "No. of Domestic Violence",
  crimes: "No. Of Reported Crimes",
  armed_robbery: "Armed Robbery",
  stealing: "Stealing",
};

const OtherSecurity = () => {
  const { security } = useSelector((state) => state.socialServices);
  const list = security.list_tabs;

  const [profile, setprofile] = useState({});
  const [data, setdata] = useState([]);

  const SetProfile = (profile) => {
    setprofile(profile);
  };

  const TableDetailsInfo = () => (
    <section className="text-lg">
      <div className="w-full flex items-start justify-start pb-3">
        <div className="w-1/2">Number Of Stations</div>
        <div className="text-gray-1 w-1/2 text-end">{profile.stations}</div>
      </div>
      <div className="w-full flex items-start justify-start pb-3">
        <div className="w-1/2">Number Of Personnel</div>
        <div className="text-gray-1 w-1/2 text-end">{profile.personnel}</div>
      </div>
      <div className="w-full flex items-start justify-start pb-3">
        <div className="w-1/2">Number Of Domestic Violence</div>
        <div className="text-gray-1 w-1/2 text-end">{profile.domestic}</div>
      </div>
      <div className="w-full flex items-start justify-start pb-3">
        <div className="w-1/2">Number Of Armed-Robbery</div>
        <div className="text-gray-1 w-1/2 text-end">{profile.armed_robbery}</div>
      </div>
      <div className="w-full flex items-start justify-start pb-3">
        <div className="w-1/2">Stealing</div>
        <div className="text-gray-1 w-1/2 text-end">{profile.stealing}</div>
      </div>
    </section>
  );

  useEffect(() => {
    let tepmArray = list?.other?.map((el) => {
      return {
        id: el.id,
        stations: el?.no_of_stations,
        personnel: el?.no_of_personnels,
        domestic: el?.no_of_domestic_violence,
        crimes: el?.no_of_reported_crimes,
        armed_robbery: el?.no_armed_robbery_cases,
        stealing: el?.no_of_stealing,
      };
    });
    setdata(tepmArray);
  }, [list]);

  return (
    <div>
      <ProfileTable
        tableHeading={tableHeading}
        list={data}
        SetProfile={SetProfile}
      >
        <TableDetailsInfo />
      </ProfileTable>
    </div>
  );
};

export default OtherSecurity;
