import * as types from "./types";

const initState = {
  basicEducation: {
    list: [],
    meta: {},
    pagination: {},
  },
  healthcare: {
    list: [],
    meta: {},
    pagination: {},
  },
  electricity: {
    list: [],
    meta: {},
    pagination: {},
  },
  waterCoverage: {
    list: [],
    meta: {},
    pagination: {},
  },
  landUseAndSpatialPlanning: {
    list: [],
    meta: {},
    pagination: {},
  },
  security: {
    list: [],
    list_tabs: {
      police: [],
      fire_service: [],
      other: []
    },
    police: {
      list: [],
      pagination: {}
    },
    fire_service: {
      list: [],
      pagination: {}
    },
    other: {
      list: [],
      pagination: {}
    },   
    meta: {},
    pagination: {},
  },
  roads: {
    list: [],
    meta: {},
    pagination: {},
  },
};

export const socialServices = (state = initState, action) => {
  switch (action.type) {
    case types.BASIC_EDUCATION:
      return {
        ...state,
        basicEducation: {
          list: action.payload.results,
          meta: action.payload.meta,
          pagination: {
            count: action.payload.count,
            limit: action.payload.limit,
            totalPages: action.payload.pages,
            next: action.payload.next,
            previous: action.payload.previous,
          },
        },
      };

    case types.HEALTHCARE:
      return {
        ...state,
        healthcare: {
          list: action.payload.results,
          meta: action.payload.meta,
          pagination: {
            count: action.payload.count,
            limit: action.payload.limit,
            totalPages: action.payload.pages,
            next: action.payload.next,
            previous: action.payload.previous,
          },
        },
      };

    case types.ELECTRICITY:
      return {
        ...state,
        electricity: {
          list: action.payload.results,
          meta: action.payload.meta,
          pagination: {
            count: action.payload.count,
            limit: action.payload.limit,
            totalPages: action.payload.pages,
            next: action.payload.next,
            previous: action.payload.previous,
          },
        },
      };

    case types.WATER_COVERAGE:
      return {
        ...state,
        waterCoverage: {
          list: action.payload.results,
          meta: action.payload.meta,
          pagination: {
            count: action.payload.count,
            limit: action.payload.limit,
            totalPages: action.payload.pages,
            next: action.payload.next,
            previous: action.payload.previous,
          },
        },
      };

    case types.LAND_USE_AND_SPATIAL_PLANNING:
      return {
        ...state,
        landUseAndSpatialPlanning: {
          list: action.payload.results,
          meta: action.payload.meta,
          pagination: {
            count: action.payload.count,
            limit: action.payload.limit,
            totalPages: action.payload.pages,
            next: action.payload.next,
            previous: action.payload.previous,
          },
        },
      };

    case types.SECURITY:
      return {
        ...state,
        security: {
          ...state.security,
          list: action.payload.results,
          meta: action.payload.meta,
          pagination: {
            count: action.payload.count,
            limit: action.payload.limit,
            totalPages: action.payload.pages,
            next: action.payload.next,
            previous: action.payload.previous,
          },
        },
      };

    case types.POLICE:
      return {
        ...state,
        security: {
          ...state.security,
          police: {
            list: action.payload.results,
            pagination: {
              count: action.payload.count,
              limit: action.payload.limit,
              totalPages: action.payload.pages,
            },
          },         
        },
      };

      case types.FIRE_SERVICE:
        return {
          ...state,
          security: {
            ...state.security,
            fire_service: {
              list: action.payload.results,
              pagination: {
                count: action.payload.count,
                limit: action.payload.limit,
                totalPages: action.payload.pages,
              },
            },         
          },
        };

        case types.OTHER_SECURITY:
          return {
            ...state,
            security: {
              ...state.security,
              other: {
                list: action.payload.results,
                pagination: {
                  count: action.payload.count,
                  limit: action.payload.limit,
                  totalPages: action.payload.pages,
                },
              },         
            },
          };
    
    case types.ROADS:
        return {
          ...state,
          roads: {
            list: action.payload.results,
            meta: action.payload.meta,
            pagination: {
              count: action.payload.count,
              limit: action.payload.limit,
              totalPages: action.payload.pages,
              next: action.payload.next,
              previous: action.payload.previous,
            },
          },
        };  

    case types.SET_SOCIAL_SERVICE:
      const payload = action.payload;

      return {
        ...state,
        basicEducation: {
          ...state.basicEducation,
          list: payload.results[0]?.education,
          meta: payload?.meta
        },
        healthcare: { 
          ...state.healthcare,
          list: payload.results[0]?.health
        },
        electricity: {
          ...state.electricity,
          list: payload.results[0]?.electricity
        },
        waterCoverage: {
          ...state.waterCoverage,
          list: payload.results[0]?.water_coverage
        },
        landUseAndSpatialPlanning: { 
          ...state.landUseAndSpatialPlanning,
          list: payload.results[0]?.spatial_planning
        },
        security: {
          ...state.security,
          list_tabs: {
            police: payload.results[0]?.police,
            fire_service: payload.results[0]?.fire_service,
            other: payload.results[0]?.other_planning
          }
        },
      };

    default:
      return state;
  }
};
