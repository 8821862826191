import axios from 'axios';

let config = null;

// For setting baseUrls and other global configs
const token = sessionStorage.getItem('Token');

export const urls = {
    test: 'https://mmda-data-submission.herokuapp.com/api',
    production: 'https://submission.lgdashboard.gov.gh/api',
    development: 'https://submission.lgdashboard.gov.gh/api'
    // development: 'https://mmda-data-submission.herokuapp.com/api'
};

export const TOKENS = {
    production: '200930feca7badfb600681effc07df04f89564d4',
    development: '200930feca7badfb600681effc07df04f89564d4',
    // development: 'df4c283a3199cc5ae19a2b688574548e852d4253'
};

const api = axios.create({
    baseURL: urls[process.env.NODE_ENV],
    headers: {
        'Content-Type': 'application/json'
    }
});

if (token) {
    api.defaults.headers.Authorization = `Token ${ TOKENS[process.env.NODE_ENV]}`
}

export const configs = config;
export default api;
