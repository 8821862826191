import React, { useEffect } from 'react';
import DelibrativeFunction from "../DeliberativeFunctions/Deliberative";
import { getDeliberativeById, getUpcomingMeetings, getMeetingsHeld } from '../../redux/deliberative/action';

import { useDispatch } from 'react-redux';

const Deliberative = ({ id }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDeliberativeById(id));
    // dispatch(getUpcomingMeetings(id));
    // dispatch(getMeetingsHeld(id));    
  }, [id]);
  
  return (
    <DelibrativeFunction />
  )
}

export default Deliberative;