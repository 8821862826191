import React, { useState, useEffect } from "react";
import ProfileTable from "./Tables/ProfileTable";
import utils from "../utils";

// Redux
import { useSelector } from "react-redux";

const Expenditure = () => {
  const { expenditure } = useSelector(state=>state.fiscal);
  const [data, setdata] = useState([]);
  const [reason, setReason] = useState();
  const [profile, setprofile] = useState({})

  const tableHeading =  {
    expenditure: "Projected expenditure",
    actual: "Actual Revenue",
    variance: "expenditure Variance",
    reason: "Reasons for Variance"
  }; 

  const SetProfile = (expenditure) => {
    setprofile(expenditure);
  };

  const TableDetailsInfo = () => (
    <section className="text-lg">
      <div className="w-full flex items-start justify-start pb-1">
        <div className="w-1/2">Projected Expenditure </div>
        <div className="text-gray-1 w-1/2">{profile.expenditure}</div>
      </div>
      <div className="w-full flex items-start justify-start pb-1">
        <div className="w-1/2">Actual Expenditure</div>
        <div className="text-gray-1 w-1/2">{profile.actual}</div>
      </div>
      <div className="w-full flex items-start justify-start pb-3">
        <div className="w-1/2">Expenditure Variance</div>
        <div className="text-gray-1 w-1/2">{profile.variance}</div>
      </div>
      <div className="w-full flex items-start justify-start pb-1">
        <div className="w-1/2">Reasons for Variance</div>
        <div className="text-gray-1 w-1/2">{profile.reason}</div>
      </div>
    </section>
  );

  useEffect(() => {
    let tepmArray = expenditure?.list?.map((el) => {
      setReason(el.reasons_for_variance);

      return  {
        expenditure: utils.numberWithCommas(el.projected_quarterly_expenditure),
        actual: utils.numberWithCommas(el.actual_expenditure),
        variance: utils.numberWithCommas(el.expenditure_variance),
        reason: el.reasons_for_variance
      };
    });

    setdata(tepmArray);
  },[expenditure]);   

  return (
    <div className="">
      <div>
        <ProfileTable
          title="Expenditure"
          tableHeading={tableHeading}
          list={data}
          SetProfile={SetProfile}
        >
          <TableDetailsInfo />
        </ProfileTable>
      </div>

      {/* <div>
        <p className="px-3 align-middle whitespace-nowrap text-left uppercase font-medium text-gray-500">
          Reasons for Variance
        </p>
        <p className="px-3 leading-loose capitalize">{reason}</p>
      </div> */}
    </div>
  );
};

export default Expenditure;
