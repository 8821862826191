/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Layout from "../component/Layout/Layout";
import ProfileComp from '../component/Profile/Profile';

// Redux
import { useDispatch } from 'react-redux';
import { getProfiles } from "../redux/profile/action";

const Profile = () => {
  const dispatch = useDispatch();
  const [loading, setloading] = useState(false);

  const fetchProfiles = (page, limit, search) => {
    setloading(true);
    dispatch(getProfiles(page, limit, search))
    .then((response) => {
      setloading(false);
      console.log('All profiles for a district', response.data);
    }, (err) => {
      setloading(false);
      console.error('Error fetching profiles:', err);
    })
  };
  
  useEffect(() => {
    fetchProfiles();
  }, []);   

  return (
    <Layout pageTitle="Profile"> 
      <ProfileComp 
        loading={loading}
        setloading={setloading}   
        fetchProfiles={fetchProfiles}   
      />
    </Layout> 
  )
}

export default Profile;
