import api from "../../services/config";
import * as types from './types';

export const getProfiles = (page=1, limit=10, search="") => async (dispatch) => {
    return new Promise((resolve, reject) => {
        // let url = `/get_assemblies/`;
        let url = `/all_profiles/`;
        if (page) url += `?page=${page}`
        if (limit) url += `&limit=${limit}`
        if (search) url += `&search=${search}`;

        return api.get(url)
        .then((response) => {
            dispatch({
                type: types.SET_PROFILES,
                payload: response.data
            })

            resolve(response);
        })
        .catch((error) => {
            reject(error);
        })
    });
};

export const getProfilesById = (id, page=1, limit=10) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        let url = `/all_profiles/districts/${id}`;
        if (page) url += `?page=${page}`
        if (limit) url += `&limit=${limit}`

        return api.get(url)
        .then((response) => {
            dispatch({
                type: types.SET_PROFILES,
                payload: response.data
            })

            resolve(response);
        })
        .catch((error) => {
            reject(error);
        })
    });
};

export const getDistrictsSubmitted = (page=1, limit=10, cached=true, search="") => async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        let url = `/all_profiles/districts/`;
        if (page) url += `?page=${page}`;
        if (limit) url += `&limit=${limit}`;
        if (search) url += `&search=${search}`;
    
        
        const localState = getState()?.profile?.districts?.list;

        if (localState?.length > 0 && cached) {
            return resolve(localState);
        }

        return api.get(url)
        .then((response) => {
            dispatch({
                type: types.SET_DISTRICTS,
                payload: response.data
            })

            resolve(response);
        })
        .catch((error) => {
            reject(error);
        })
    });
};

export const getRegions = (page=1, limit=10, cached=true) => async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        let url = `/all_profiles/regions/`;
        if (page) url += `?page=${page}`;
        if (limit) url += `&limit=${limit}`;
        
        const localState = getState()?.profile?.regions?.list;

        if (localState?.length > 0 && cached) {
            return resolve(localState);
        }

        return api.get(url)
        .then((response) => {
            dispatch({
                type: types.SET_REGIONS,
                payload: response.data
            })

            resolve(response);
        })
        .catch((error) => {
            reject(error);
        })
    });
};

export const getRegionDistricts = (region, page=1, limit=10, cached=true, search) => async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        let url = `/all_profiles/regions/${region}/`;
        if (page) url += `?page=${page}`;
        if (limit) url += `&limit=${limit}`;
        if (search) url += `&search=${search}`;
        
        // const localState = getState()?.profile?.regions?.list;

        // if (localState?.length > 0 && cached) {
        //     return resolve(localState);
        // }

        return api.get(url)
        .then((response) => {
            dispatch({
                type: types.SET_DISTRICTS,
                payload: response.data
            })

            resolve(response);
        })
        .catch((error) => {
            reject(error);
        })
    });
};

export const getDistrictById = (id, page=1, limit=10) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        let url = `/get_assembly/${id}/`;
        // let url = `/all_profiles/districts/${id}`;
        // if (page) url += `?page=${page}`
        // if (limit) url += `&limit=${limit}`

        return api.get(url)
        .then((response) => {
            dispatch(setDistrictInfo(response.data))
            .then(() => {
                resolve(response.data)
            })
        })
        .catch((error) => {
            reject(error);
        })
    });
};

export const getRegionInfo = (id) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        let url = `/get_region/${id}/`;

        return api.get(url)
        .then((response) => {
            dispatch(setRegionInfo(response.data))
            .then(() => {
                resolve(response.data)
            })
        })
        .catch((error) => {
            reject(error);
        })
    });
};

export const setDistrictInfo = (info) => (dispatch) => {
    return new Promise((resolve) => {
        dispatch({
            type: types.SET_DISTRIC_INFO,
            payload: info
        })

        resolve(info);
    })
};

export const setRegionInfo = (info) => (dispatch) => {
    return new Promise((resolve) => {
        dispatch({
            type: types.SET_REGION_INFO,
            payload: info
        })

        resolve(info);
    })
};
