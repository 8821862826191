import { combineReducers } from 'redux';
import { user } from '../redux/user/reducer';
import { profile } from "../redux/profile/reducer";
import { deliberative } from "../redux/deliberative/reducer";
import { administrative } from '../redux/administrative/reducer';
import { economicData } from '../redux/EconomicData/reducer';
import { fiscal } from '../redux/fiscal/reducer';
import { socialServices } from '../redux/SocialServices/reducer';
import { environmentAndSanitation } from '../redux/EnvironmentAndSanitation/reducer';
import { politicalIssues } from '../redux/PoliticalIssues/reducer';
import { specialInitiatives } from '../redux/SpecialInitiatives/reducer';
import { infrastructure } from '../redux/infrastructure/reducer';
import { otherIssuesOfInterest } from '../redux/OtherIssuesOfInterest/reducer';
import { general } from '../redux/general/reducer';

const rootReducer = combineReducers({
    // Add more store here
    user: user,
    profile: profile,
    deliberative: deliberative,
    administrative: administrative,
    economicData: economicData,
    fiscal: fiscal,
    socialServices: socialServices,
    environmentAndSanitation: environmentAndSanitation,
    politicalIssues: politicalIssues,
    specialInitiatives: specialInitiatives,
    infrastructure: infrastructure,
    otherIssuesOfInterest: otherIssuesOfInterest,
    general : general
});

export default rootReducer;
