import React, {useState,useEffect} from 'react'
import Widget from "../../component/DashbordWidget";
import ProfileTable from '../Tables/ProfileTable';

import { getSpecialInitiativesById } from '../../redux/SpecialInitiatives/action';
import { useDispatch, useSelector } from 'react-redux';

const SpecialInitiatives = ({id}) => {
  const dispatch = useDispatch()
  const {specialInitiatives, meta } = useSelector(state=>state.specialInitiatives)
  
  const [profile, setprofile] = useState({});

  const SetProfile = (profile) => {
    setprofile(profile);
  };

  const tableHeading = (info) => {
    switch (info) {
      case '1D1F':
        return {
          // no_of_factories: "Number of Factories",
          name_of_factory: "Name of Factory",
          location: "Location of Factory",
          expected_no_of_jobs: "Expected Number of Jobs",
          feedback_on_performance: "Performance Feedback",
          status: "Status of Factory",
        };

      case '1V1D':
        return {
          // no_of_dams: "Number of Dams",
          location: "Location of Dam",
          feedback_on_performance: "Performance Feedback",
          "status": "Status of Dam",
        };

      case 'Greenery Ghana':
        return {
          no_of_trees_planted: "Number of Trees Planted",
          feedback_on_performance_on_flagship_program: "Feedback on the performance",
          short_term_experts: "Experts",
          specific_support_provided: "Specific Support Provided"
        };

      case 'PERD':
        return {
          no_of_seedling_developed: "Number of Seedlings Developed so Far",
          no_of_beneficiary: "Number of Beneficiary Farmers",
          total_size_land_planted: "Total Size of Land Planted",
          "type_of_crops": "Type of Crops",
          "feedback": "Feedback on the performance of the Flagship Programme" 
        };

      case 'Agenda111':
        return {
          name_of_hospital: "Name of Hospital",
          start_date: "Start Date",
          expected_date: "Expected Completion Date",
          percentage_of_completion: "Percentage of Completion",
          contractors: "Contractor(s)"
        };
      
      case 'PFJ':
        return {
          no_of_farmers: "Number of  Farmers",
          feedback: "Feedback on the performance of the Flagship Programme",
        };

      case 'Scholarship For Needy':
        return {
          amount_disbursed_assembly_scholarship: "District Scholarship Amount Disbursed",
          amount_disbursed_mps_scholarship: "Mps Scholarship Amount Disbursed",
          no_of_beneficiaries_assembly_scholarship: "No. of Beneificiaries for District Scholarship",
          no_of_beneficiaries_mps_scholarship: "No. of Beneficiaries for MPS Scholarship",
        };

      case 'School Feeding Program':
        return {
          no_of_schools: "Number of Schools on the Program",
          no_of_beneficiary: "No. of Beneficiary Pupils",
          no_of_caterers: "Number of Caterers",
          no_of_school_without_feeding: "No. of Schools without School feeding"
        };
        
      default:
        break;
    }    
  };

  const SetTableBody = (info="", data=[]) => {
    switch (info) {
      case '1D1F':
        return data.map((el) => {
          return {
            // no_of_factories: el?.no_of_factories,
            name_of_factory: el?.name_of_factory,
            location: el?.location_of_factory,
            expected_no_of_jobs: el?.expected_no_of_jobs,
            feedback_on_performance: el?.feedback_on_performance,
            status: <span className="text-center text-white bg-green-600 rounded-full px-4 py-1">{el?.status_of_factory || "N/A"}</span>,
          }
        })

      case '1V1D':
        return data.map((el) => {
          return {
            // no_of_dams: el?.no_of_dams,
            location: el?.location_of_dam,
            feedback_on_performance: el?.feedback_on_performance,
            status: <span className="text-center text-white bg-green-600 rounded-full px-4 py-1">{el?.status_of_dam || "N/A"}</span>,
          };
        })      

      case 'Greenery Ghana':
        return data.map((el) => {
          return {
            no_of_trees_planted: el?.no_of_trees,
            feedback_on_performance_on_flagship_program: el?.feedback_on_performance,
            short_term_experts: el?.short_term_experts,
            specific_support_provided: el?.specific_support_provided
          };
        })

      case 'PERD':
        return data.map((el) => {
          return {
            no_of_seedling_developed: el?.no_of_seedlings, 
            no_of_beneficiary: el?.no_of_farmers, 
            total_size_land_planted: el?.total_size_of_land, 
            type_of_crops: el?.type_of_crop,
            feedback: el?.feedback_on_performance
          };
        })

      case 'Agenda111':
        return data.map((el) => {
          return {
            name_of_hospital: el?.name_of_hospital,
            start_date: el?.start_date,
            expected_date: el?.expected_date,
            percentage_of_completion: el?.percentage_of_completion,
            contractors: el?.contractors,
          };
        })      
      
      case 'PFJ':
        return data.map((el) => {
          return {
            no_of_farmers: el?.no_of_farmers,
            feedback: el?.feedback,
          };
        })

      case 'Scholarship For Needy':
        return data.map((el) => {
          return {
            amount_disbursed_assembly_scholarship: el?.amount_disbursed_assembly_scholarship,
            amount_disbursed_mps_scholarship: el?.amount_disbursed_mps_scholarship,
            no_of_beneficiaries_assembly_scholarship: el?.no_of_beneficiaries_assembly_scholarship,
            no_of_beneficiaries_mps_scholarship: el?.no_of_beneficiaries_mps_scholarship,
          }
        })      

      case 'School Feeding Program':
        return data.map((el) => {
          return {
            no_of_schools: el?.no_of_schools,
            no_of_beneficiary: el?.no_of_beneficiary,
            no_of_caterers: el?.no_of_caterers,
            no_of_school_without_feeding: el?.no_of_school_without_feeding,
          };
        })      
        
      default:
        break;
    } 
  };

  const ModalInfo = (info="", el={}) => {
    switch (info) {
      case '1D1F':
        return {
          // "Number of Factories": el?.no_of_factories,
          "Name of Factory": el?.name_of_factory,
          "Location of Factory": el?.location_of_factory,
          "Feedback On Performance": el?.feedback_on_performance,
          "Status of Factory": <span className="text-center text-white bg-green-600 rounded-full px-4 py-1">{el?.status_of_factory || "N/A"}</span>,
          "Expected Number of Jobs": el?.expected_no_of_jobs
        };

      case '1V1D':
        return {
          // "Number of Dams": el?.no_of_dams,
          "Location of Dam": el?.location_of_dam,
          "Feedback On Performance": el?.feedback_on_performance,
          "Status of Dam": <span className="text-center text-white bg-green-600 rounded-full px-4 py-1">{el?.status_of_dam || "N/A"}</span>,
        };

      case 'Greenery Ghana':
        return {
          "Number of Trees Planted": el?.no_of_trees,
          "Feedback on the performance of the Flagship Programme": el?.feedback_on_performance,
          "Experts": el?.short_term_experts,
          "Specific Support Provided": el?.specific_support_provided
        };

      case 'PERD':
        return {
          "Number of Seedlings Developed so Far": el?.no_of_seedlings, 
          "Number of Beneficiary Farmers": el?.no_of_farmers, 
          "Total Size of Land Planted": el?.total_size_of_land, 
          "Type of Crops": el?.type_of_crop,
          "Feedback on the performance of the Flagship Programme": el?.feedback_on_performance
        };

      case 'Agenda111':
        return {
          "Name of Hospital": el?.name_of_hospital,
          "Start Date": el?.start_date,
          "Expected Completion Date": el?.expected_date,
          "Percentage of Completion": el?.percentage_of_completion,
          "Contractor(s)": el?.contractors,
        };
      
      case 'PFJ':
        return {
          "Number of  Farmers": el?.no_of_farmers,
          "Feedback on the performance of the Flagship Programme": el?.feedback,
        };

      case 'Scholarship For Needy':
        return {
          "District Scholarship Amount Disbursed": el?.amount_disbursed_assembly_scholarship,
          "Mps Scholarship Amount Disbursed": el?.amount_disbursed_mps_scholarship,
          "No. of Beneificiaries for District Scholarship": el?.no_of_beneficiaries_assembly_scholarship,
          "No. of Beneficiaries for MPS Scholarship": el?.no_of_beneficiaries_mps_scholarship,
        };

      case 'School Feeding Program':
        return {
          "Number of Schools on the Program": el?.no_of_schools,
          "No. of Beneficiary Pupils": el?.no_of_beneficiary,
          "Number of Caterers": el?.no_of_caterers,
          "No. of Schools without School feeding": el?.no_of_school_without_feeding,
        };
        
      default:
        break;
    }      
  };

  const dataItems = [
    {
      id: 1,
      number: meta["1D1F"] ||  meta["1D1F"] === 0 ? meta["1D1F"] : "N/A",
      link: " ",
      name: "1D1F",
      bck: "#7A6EFE"
    },
    {
      id: 2,
      number: meta["Scholarship for the Needy"] || meta["Scholarship for the Needy"] === 0 ? meta["Scholarship for the Needy"] : "N/A",
      link: " ",
      name: "Scholarship for the Needy",
      bck: "#FF5363"
    },
    {
      id: 3,
      number: meta["PFJ"] || meta["PFJ"] === 0 ? meta["PFJ"] : "N/A",
      link: " ",
      name: "PFJ",
      bck: "#FFA901"
    },
    {
      id: 4,
      number: meta["School Feeding Program"] || meta["School Feeding Program"] === 0 ? meta["School Feeding Program"] : "N/A",
      link: " ",
      name: "School Feeding Program",
      bck: "#24A8FA"
    },
  ];

  const TableDetailsInfo = ({ info={} }) => {
    return (
      <section className="text-lg">
        {Object.keys(info).map((oneKey, i) => {
          return (
            <div className="w-full flex items-start justify-between pb-3" key={i}>
              <div className="w-1/2">{oneKey}</div>
              <div className="text-gray-1 text-end w-1/2">{info[oneKey] || info[oneKey] === 0 ? info[oneKey] : "N/A"}</div>
            </div>
          )
        })}
      </section>
    );
  }
  
  useEffect(() => {
    dispatch(getSpecialInitiativesById(id));
  }, [id]);

  return (
    <div>
      <div className="w-full flex flex-row">
        {dataItems.map((item) => (
          <div className="w-1/2 sm:w-1/4">
            <Widget id={item.id} link={item.link} number={item.number} name={item.name} bck={item.bck} lastItem={dataItems.length} />
          </div>
        ))}
      </div>

        {Object.keys(specialInitiatives?.tab_list)
          .map((oneKey, i) => {
            // let tableData = SetTableBody(oneKey, specialInitiatives?.tab_list[oneKey]);

            return (
              <ProfileTable 
                key={i}
                title={oneKey} 
                tableHeading={tableHeading(oneKey)} 
                list={SetTableBody(oneKey, specialInitiatives?.tab_list[oneKey])}
                SetProfile={SetProfile}
                // otherInfo={SetOtherInfo(specialInitiatives?.tab_list[oneKey][0])}
              >
                <TableDetailsInfo 
                  info={ModalInfo(oneKey, specialInitiatives?.tab_list[oneKey][0])} 
                />
              </ProfileTable>
            )
          }
        )}
    </div>
  );
}

export default SpecialInitiatives