import React, { useEffect, useState } from "react";
import Card from "../Card";
import TableFilter from "../Tables/TableFilter";
import OtherModal from "../Profile/ProfileInfo";
import utils from "../../utils";
import NoData from "../NoData";

import { getOtherIssuesOfInterest } from "../../redux/OtherIssuesOfInterest/action";
import { useDispatch, useSelector } from "react-redux";

const OtherIssuesOfInterest = ({ id }) => {
  const dispatch = useDispatch();
  const { otherissues } = useSelector((state) => state.otherIssuesOfInterest);

  const [showDetails, setshowDetails] = useState(false);
  const [loading, setloading] = useState(false);
  const [info, setinfo] = useState({});
  const [data, setdata] = useState([]);

  const handleShowModal = (val) => {
    setinfo(val);
    setshowDetails(true);
  };

  const handleCloseDetails = () => {
    setinfo({});
    setshowDetails(false);
  };

  useEffect(() => {
    console.log("other Issuses", otherissues);
    if (utils.present(otherissues)) {
      let tepmArray = otherissues.map((el) => {
        return {
          date: new Date(el.submitted_on).toLocaleDateString(),
          label: el.district,
          text:
            el.issues_of_interest_to_central_government ||
            el.decisions_taken ||
            "N/A",
          view: "View Issue",
          ...el,
        };
      });

      setdata(tepmArray);
    }
  }, [otherissues]);

  const fetchOtherIssues = (id, search = "") => {
    setloading(true);
    dispatch(getOtherIssuesOfInterest(id, search)).then(
      () => {
        setloading(false);
      },
      () => {
        setloading(false);
      }
    );
  };
  useEffect(() => {
    fetchOtherIssues(id);
  }, [id]);

  const OtherIssuesDetails = () => (
    <section className="text-lg">
      <div className="w-full pb-3">
        <div className="w-full">District</div>
        <div className="text-gray-1">{info.district}</div>
      </div>
      <div className="w-full pb-3">
        <div className="w-full">Issues Of Interest to Central Government</div>
        <div className="text-gray-1">
          {info.issues_of_interest_to_central_government || "N/A"}
        </div>
      </div>
      <div className="w-full pb-3">
        <div className="w-full">Confidential Information</div>
        <div className="text-gray-1">
          {info.confidential_information || "N/A"}
        </div>
      </div>
      <div className="w-full pb-3">
        <div className="w-full">Date Submitted On</div>
        <div className="text-gray-1">
          {new Date(info.submitted_on).toLocaleDateString()}
        </div>
      </div>
    </section>
  );

  return (
    <div className="relative">
      <TableFilter
        parentSearchStyle="w-2/5 bg-light border border-gray-100 rounded-lg"
        placeholder="Search Other Issues of Interest"
        showExport={false}
        handleSearch={(e) => fetchOtherIssues(id, e)}
      />

      <div className="mt-4 bg-white pt-4">
        <div className="flex flex-wrap items-stretch mt-3">
          {data.length > 0 &&
            data.map((item) => (
              <Card
                date={item.date}
                label={item.label}
                text={item.text}
                view={item.view}
                item={item}
                setInfo={handleShowModal}
              />
            ))}
        </div>

        {loading ? (
          <div
            className={`${
              data.length > 0 ? "h-full" : "h-64"
            } w-full absolute inset-0 bg-primary bg-opacity-25 rounded-b-lg transition-all ease-in duration-700 flex justify-center items-center`}
          >
            <svg
              className="animate-spin h-10 w-10 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </div>
        ) : (
          data && data?.length === 0 && <NoData entity="Issues" />
        )}
      </div>

      {showDetails && (
        <OtherModal
          title="Other Issues Of Interest"
          showModal={info}
          handleClose={handleCloseDetails}
        >
          <OtherIssuesDetails />
        </OtherModal>
      )}
    </div>
  );
};

export default OtherIssuesOfInterest;
