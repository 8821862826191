import Layout from "../component/Layout/Layout";
import AdministrativeTabs from '../component/Administrative/AdministrativeTabItems'

const Administrative = () => {
  return (
    <Layout pageTitle="Administrative"> 
      <AdministrativeTabs/>
    </Layout> 
  )
}

export default Administrative