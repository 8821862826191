import React, { useState  } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import styles from "../../tabs/Tabs.module.scss"
// import GreeningProject from "./GreeningProject";
import LiquidWaste from "./LiquidWaste";
import SolidWaste from "./SolidWaste";

import ParksAndGardens from "./ParksAndGardens";
import Pollution from "./Pollution";

const EnvironmentTabItems = () => {
  const [active, setactive] = useState(1);

  const tabList = [
    {
      id:1,
      name: "Parks And Gardens",
      link: "/revenue", 
    },
    // {
    //   id:2,
    //   name: "Greening Project",
    //   link: "",
    // },
    {
      id:2,
      name: "Pollution",
      link: "",
    },
    // {
    //   id:3,
    //   name: "Solid Waste",
    //   link: "",
    // },
    // {
    //   id:4,
    //   name: "Liquid Waster",
    //   link: "",
    // },
   
  ];

  return (
    <div >
      <Nav className={`${styles.navparent} flex flex-nowrap mb-4 bg-gray-100 overflow-y-hidden tab`}  tabs>
        {tabList.map((el) => (
          <NavItem>
            <NavLink 
              className={`${styles.link} ${active === el.id ? 'active bg-transparent' : ''} text-sm border-none uppercase`}
              onClick={() => { setactive(el.id) }}
            >
              {el.name}
          </NavLink>
          </NavItem>
        ))}
      </Nav>

      <TabContent activeTab={active}>
        {tabList.map((el) => (
          <TabPane tabId={el.id} >
            {el.id===1 && <ParksAndGardens/>}
            {/* {el.id===2 && <GreeningProject/>} */}
            {el.id===2 && <Pollution/>} 
            {/* {el.id===3 && <SolidWaste/>} 
            {el.id===4 && <LiquidWaste/>}  */}
          </TabPane>
        ))}
      </TabContent>
    </div>
  );
};

export default EnvironmentTabItems;
