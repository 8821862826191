import React, { useState, useEffect } from 'react'
import ProfileTable from './Tables/ProfileTable';
import utils from "../utils";

// Redux
import { useSelector } from "react-redux";

const BasicEducation = () => {
  const { basicEducation } = useSelector((state) => state.socialServices);
  const meta = basicEducation?.meta;

  const [profile, setprofile] = useState({});
  const [data, setdata] = useState([]);
  const [title, settitle] = useState('Basic Education');

  const tableHeading =  {
    name: "Education type",
    enrollment: "enrollment status",
    dropouts: "No. of dropouts",
    pass: "pass rate",
    absence: "Absence",
    teachers: "no. of teachers"
  };
  
  const SetProfile = (Education) => {
    const updatedBasicEdu = basicEducation?.list?.find((el) => el.id === Education?.id);
    setprofile(updatedBasicEdu);
  };

  const TableDetailsInfo = () => (
    <section classNaemptyState
    emptyStateme="text-lg">
    <div className="w-full flex items-center justify-start pb-1">
      <div className="w-1/2">Education type</div>
      <div className="text-gray-1">{profile.type_of_education}</div>
    </div>
    <div className="w-full flex items-center justify-start pb-1">
      <div className="w-1/2">Enrollment status</div>
      <div className="text-gray-1">{profile.enrollment}</div>
    </div>
    <div className="w-full flex items-center justify-start pb-1">
      <div className="w-1/2">No. of dropouts</div>
      <div className="text-gray-1">{profile.dropouts}</div>
    </div>
    <div className="w-full flex items-center justify-start pb-1">
      <div className="w-1/2">Pass rate</div>
      <div className="text-gray-1">{profile.pass || 'N/A'}</div>
    </div>
    <div className="w-full flex items-center justify-start pb-1">
      <div className="w-1/2">Absence</div>
      <div className="text-gray-1">{profile.absence || 'N/A'}</div>
    </div>
    <div className="w-full flex items-center justify-start pb-1">
      <div className="w-1/2">No. of teachers</div>
      <div className="text-gray-1">{profile.no_of_teachers || 'N/A'}</div>
    </div>
  </section>
  );

  useEffect(() => {
    let tepmArray = basicEducation?.list?.map((el) => {
      return {
        id: el.id,
        name: el.type_of_education,
        enrollment: utils.numberWithCommas(el.enrollment),
        dropouts: el.dropouts,
        pass: el.pass_rate,
        absence: el.absence,
        teachers: utils.numberWithCommas(el.no_of_teachers)
      };
    });

    setdata(tepmArray);
  }, [basicEducation]);  

  useEffect(() => {
    if (meta.basic_sch_population) settitle(`Basic Education (Total: ${utils.numberWithCommas(meta?.basic_sch_population)})`);
  }, [meta]);
  

  return (
    <div>
        <ProfileTable
        title={title}
        tableHeading={tableHeading}
        list={data}
        showDivider
        SetProfile={SetProfile}
      >
        <TableDetailsInfo/>
      </ProfileTable>
    </div>
  )
}

export default BasicEducation