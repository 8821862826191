import React, { useState, useEffect } from "react";
import ProfileTable from "./Tables/ProfileTable";
import utils from "../utils";

// Redux
import { useSelector } from "react-redux";

const Revenue = () => {
  const { revenue } = useSelector(state=>state.fiscal);
  const [data, setdata] = useState([]);
  const [reason, setReason] = useState();
  const [profile, setprofile] = useState({})

  const tableHeading =  {
    name: "Projected revenue",
    actual: "Actual Revenue",
    variance: "Revenue Variance",
    reasons: "Reasons for Variance"
  }; 

  const SetProfile = (revenue) => {
    setprofile(revenue);
  };

  const TableDetailsInfo = () => (
    <section className="text-lg">
      <div className="w-full flex items-start justify-start pb-1">
        <div className="w-1/2">Projected revenue </div>
        <div className="text-gray-1 w-1/2">{profile.name}</div>
      </div>
      <div className="w-full flex items-start justify-start pb-1">
        <div className="w-1/2">Actual Revenue</div>
        <div className="text-gray-1 w-1/2">{profile.actual}</div>
      </div>
      <div className="w-full flex items-start justify-start pb-3">
        <div className="w-1/2">Revenue Variance</div>
        <div className="text-gray-1 w-1/2">{profile.variance}</div>
      </div>
      <div className="w-full flex items-start justify-start pb-1">
        <div className="w-1/2">Reasons for Variance</div>
        <div className="text-gray-1 w-1/2">{profile.reasons}</div>
      </div>
    </section>
  );

  useEffect(() => {
    let tepmArray = revenue?.list?.map((el) => {
      setReason(el.reasons_for_variance);

      return  {
        name: utils.numberWithCommas(el.projected_quarterly_revenue),
        actual: utils.numberWithCommas(el.actual_revenue_received),
        variance: utils.numberWithCommas(el.revenue_variance),
        reasons: el.reasons_for_variance
      };
    });

    setdata(tepmArray);
  },[revenue]);   

  return (
    <div className="">
      <div>
        <ProfileTable
          title="Revenue"
          tableHeading={tableHeading}
          list={data}
          SetProfile={SetProfile}
        >
          <TableDetailsInfo />
        </ProfileTable>
      </div>

      {/* <div>
        <p className="px-3 align-middle whitespace-nowrap text-left uppercase font-medium text-gray-500">
          Reasons for Variance
        </p>
        <p className="px-3 leading-loose capitalize">{reason}</p>
      </div> */}
    </div>
  );
};

export default Revenue;
