import React, { useState, useEffect, useMemo } from "react";
import { Button } from "reactstrap";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { toast } from "react-toastify";

// Component styling
import "react-big-calendar/lib/css/react-big-calendar.css";
// import "./BigCalender.module.scss";

// Components
import ModalInfo from "../Profile/ProfileInfo";

// Setup the localizer by providing the moment (or globalize, or Luxon) Object
// to the correct localizer.
const localizer = momentLocalizer(moment); // or globalizeLocalizer

const BigCalender = ({
  meetings=[]
}) => {
  const [myEvents, setEvents] = useState([]);
  const [show, setshow] = useState(false);
  const [showDetails, setshowDetails] = useState(false);
  const [title, settitle] = useState("");
  const [loading, setloading] = useState(false);
  const [details, setDetails] = useState();

  const handleSelectSlot = () => {
    // setshow(true);
  };

  // const handleSelectSlot = useCallback(({ start, end }) => 
  //   {
  //     const title = window.prompt('New Event name')
  //     if (title) {
  //       setEvents((prev) => [...prev, { start, end, title }])
  //     }
  //   }, [setEvents]);

  const handleSelectEvent = (event) => {
    console.log('Event:', event);
    setDetails(event);
    setshowDetails(true);
  };

  const { defaultDate, scrollToTime } = useMemo(() => ({
    defaultDate: new Date(),
    scrollToTime: new Date(1970, 1, 1, 6),
  }),[]);

  const handleModalClose = () => {
    setshow(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setloading(true);

    setTimeout(() => {
      setloading(false);
      toast.success('Added new event successfully');
      setshow(!show);
    }, 2000);
  };

  const MeetingDetails = () => (
    <section className="text-lg">
      <div className="w-full flex items-start justify-start pb-2">
        <div className="w-1/2">District</div>
        <div className="w-1/2 text-gray-1 capitalize">{details?.district || details?.created_by}</div>
      </div>
      <div className="w-full flex items-start justify-start pb-2">
        <div className="w-1/2">Type of Meeting</div>
        <div className="w-1/2 text-gray-1">{details.type_of_meeting}</div>
      </div>
      <div className="w-full flex items-start justify-start pb-2">
        <div className="w-1/2">Meeting Theme</div>
        <div className="w-1/2 text-gray-1">{details.meeting_theme_title}</div>
      </div>
      <div className="w-full flex items-start justify-start pb-2">
        <div className="w-1/2">Agenda</div>
        <div className="w-1/2 text-gray-1">{details.agenda || 'N/A'}</div>
      </div>
      <div className="w-full flex items-start justify-start pb-2">
        <div className="w-1/2">Proposed Date</div>
        <div className="w-1/2 text-gray-1">{new Date(details.proposed_date).toDateString()}</div>
      </div>
      <div className="w-full flex items-start justify-start pb-2">
        <div className="w-1/2">Venue</div>
        <div className="w-1/2 text-gray-1">{details.venue}</div>
      </div>
    </section>  
  );  

  useEffect(() => {
    const allEvents  = meetings.map(el => {
      return {
        ...el,
        title: el.meeting_theme_title,
        allDay: true,
        start: el.proposed_date,
        end: el.proposed_date,
      }
    });

    setEvents(allEvents);
  }, [meetings]);
  
  return (
    <div className="h-full">
      <Calendar
        defaultDate={defaultDate}
        defaultView={Views.MONTH}
        events={myEvents}
        localizer={localizer}
        onSelectEvent={handleSelectEvent}
        onSelectSlot={handleSelectSlot}
        selectable
        scrollToTime={scrollToTime}
      />

      {show && (
        <ModalInfo title="New Event" showModal={show} handleClose={handleModalClose} showFooter={false}>
          <form className="flex flex-col" onSubmit={handleSubmit}>
            <div className="rounded pb-3">
              <label className="block text-sm font-semibold pb-1">Name</label>
              <input
                type="text"
                name="event"
                onChange={(e) => { settitle(e.target.value) }}
                placeholder="Event"
                required
                className="rounded border border-gray-200 px-2 py-2 w-full"
              />
            </div>

            <div className="mt-6">
              <Button
                type="submit"
                color="primary"
                className="d-flex items-center justify-center w-full bg-blue-600 rounded shadow-lg px-3 py-2 text-white relative top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
              >
                {loading ? 
                  <svg className="animate-spin -ml-1 mr-3 h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>                     
                : 
                  'Add' 
                }
              </Button>
            </div>
          </form>
        </ModalInfo>
      )}

      {showDetails &&
        <ModalInfo 
          title={details?.title}
          showModal={showDetails}
          handleClose={() => setshowDetails(false)}
        >
          <MeetingDetails />
        </ModalInfo>
      }      
    </div>
  );
};

export default BigCalender;
