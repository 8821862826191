import * as types from "./types";

const initState = {
  pagination: {},
  economicData: [],
  meta: {},
  details: {
    list: [],
    count: 1,
    pages: null,
    limit: 10
  },
};

export const economicData = (state = initState, action) => {
  switch (action.type) {
    case types.ECONOMIC_DATA:
      const { results, meta, count, limit, pages, next, previous} = action.payload;
    
      return {
        ...state,
        economicData: results,
        pagination: { 
          count,
          limit,
          totalPages: pages,            
          next, 
          previous 
        },
        meta
      };

    case types.ECONOMIC_BY_TYPE:
      return {
        ...state, 
        details: {
          list: action.payload.results,
          ...action.payload
        }
      }
    default:
      return state;
  }
};
