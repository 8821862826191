import React, { useState, useEffect } from "react";
import ProfileTable from "./Tables/ProfileTable";
import { useSelector } from "react-redux";

const tableHeading = {
  waste_generated: "Tonnes of Waste Generated",
  percentage: "Percentage o Waste Disposed",
  availability: "Landfill Availability",
  no_of_availabel_waste_collection: "Number of Available Waste Collection",
  name_of_waste_company: "Name of Waste Company",
};

const SolidWaste = () => {
  const { solidWaste } = useSelector((state) => state.environmentAndSanitation);

  const [profile, setprofile] = useState({});
  const [data, setdata] = useState([]);

  const SetProfile = (profile) => {
    console.log("SolidWaste Info:", profile);
    setprofile(profile);
  };

  const TableDetailsInfo = () => (
    <section className="text-lg">
      <div className="w-full flex items-start justify-between pb-3">
        <div className="w-1/2">Tonnes of Waste Generated</div>
        <div className="text-gray-1 w-1/2 text-end">
          {profile.waste_generated}
        </div>
      </div>
      <div className="w-full flex items-start justify-between pb-1">
        <div className="w-1/2">Percentage o Waste Disposed</div>
        <div className="text-gray-1 w-1/2 text-end">{profile.percentage}</div>
      </div>
      <div className="w-full flex items-start justify-between pb-1">
        <div className="w-1/2">Landfill Availability</div>
        <div className="text-gray-1 w-1/2 text-end">{profile.availability}</div>
      </div>
      <div className="w-full flex items-start justify-between pb-1">
        <div className="w-1/2">Number of Available Waste Collection</div>
        <div className="text-gray-1 w-1/2 text-end">
          {profile.no_of_availabel_waste_collection}
        </div>
      </div>
      <div className="w-full flex items-start justify-between pb-1">
        <div className="w-1/2">Name of Waste Company</div>
        <div className="text-gray-1 w-1/2 text-end">
          {profile.name_of_waste_company}
        </div>
      </div>
    </section>
  );

  useEffect(() => {
    let tepmArray = solidWaste?.list?.map((el) => {
      return {
        id: el.id,
        waste_generated: el?.tonnes_of_waste_generated,
        percentage: el?.percenatge_waste_disposed,
        availability: el?.landfill_availability,
        no_of_availabel_waste_collection:
          el?.no_of_available_waste_collection_companies,
        name_of_waste_company: el?.name_of_waste_comppanines,
      };
    });
    setdata(tepmArray);
  }, [solidWaste]);

  return (
    <div>
      <ProfileTable
        title="Solid Waste"
        tableHeading={tableHeading}
        showDivider
        list={data}
        SetProfile={SetProfile}
      >
        <TableDetailsInfo />
      </ProfileTable>
    </div>
  );
};

export default SolidWaste;
