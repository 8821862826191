import React, { useState  } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import styles from "../../tabs/Tabs.module.scss"

//Tabs
import RoadInfrastructure from "./RoadInfrastructure";
import MarketInfrastructure from "./MarketInfrastructure";
import SchoolInfrastructure from "./Schools";
import HealthInfrastructure from "./Health";
import OtherInfrastructure from "./Others";

const InfrastructureTabItems = () => {
  const [active, setactive] = useState(1);

  const tabList = [
    {
      id:1,
      name: "Road Infrastructure",
      link: "/revenue", 
    },
    {
      id:2,
      name: "Market Infrastructure",
      link: "",
    },
    {
      id:3,
      name: "School Infrastructure",
      link: "",
    },
    // {
    //   id:4,
    //   name: "ICT",
    //   link: "",
    // },
    {
      id:4,
      name: "Health Infrastructure",
      link: "",
    },
    {
      id:5,
      name: "Other Infrastructure",
      link: "",
    },
    // {
    //   id:6,
    //   name: "Office Administration",
    //   link: "",
    // },
    // {
    //   id:7,
    //   name: "Electricity",
    //   link: "",
    // }
  ];

  return (
    <div >
      <Nav className={`${styles.navparent} flex flex-nowrap mb-4 bg-gray-100 overflow-y-hidden tab`}  tabs>
        {tabList.map((el) => (
          <NavItem>
            <NavLink 
              className={`${styles.link} ${active === el.id ? 'active bg-transparent' : ''} text-sm border-none uppercase`}
              onClick={() => { setactive(el.id) }}
            >
              {el.name}
          </NavLink>
          </NavItem>
        ))}
      </Nav>

      <TabContent activeTab={active}>
        {tabList.map((el) => (
          <TabPane tabId={el.id} >
            {el.id===1 && <RoadInfrastructure/>}
            {el.id===2 && <MarketInfrastructure/>}
            {el.id===3 && <SchoolInfrastructure/>}
            {el.id===4 && <HealthInfrastructure/>}
            {el.id===5 && <OtherInfrastructure/>}
          </TabPane>
        ))}
      </TabContent>
    </div>
  );
};

export default InfrastructureTabItems;
