import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import api, { TOKENS } from "../../services/config";

// Style
import "./Layout.scss";

// Components
import Sidebar from "../Sidebar/Sidebar";
import Header from "./Header";

const Layout = ({ 
  children, 
  pageTitle="", 
  breadcrumb=[],  
  pageSideSpacing="px-8" 
}) => {
  let navigate = useNavigate();

  const handleLogout = () => {
    sessionStorage.removeItem("Token");
    toast.info("User logged out");
    navigate("/");
  };   

  useEffect(() => {
    let authToken = sessionStorage.getItem('Token');
    
    if (!authToken) {
      navigate('/');
    } else {
      if (!api.defaults.headers.Authorization) 
      api.defaults.headers.Authorization = `Token ${ TOKENS[process.env.NODE_ENV]}`
    }
}, [navigate]);

  return (
    <div className="container-fluid pr-0 bg-gray-100">
      <div className="row">
        <Sidebar handleLogout={handleLogout} />

        <main className="col-md-9 ms-sm-auto col-lg-10 main__children min-vh-100 px-0">
          <Header pageTitle={pageTitle} breadcrumb={breadcrumb} />

          <div className={pageSideSpacing}>
            {children}
          </div>
        </main>
      </div>
    </div>
  );
}

export default Layout;
