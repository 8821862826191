import React, { useState, useEffect } from "react";
import Widget from "../DashbordWidget";
import ProfileTable from "../Tables/ProfileTable";
import utils from '../../utils'

import { getParksAndGardens } from "../../redux/EnvironmentAndSanitation/action";
import { useDispatch, useSelector } from "react-redux";

const tableHeading = {
  name: "District",
  site: "Specific sites",
  location: "location",
  support: "Specific Support Provided by the Assembly",
}

const ParksAndGardens = () => {
  const dispatch = useDispatch();
  const { parksAndGardens } = useSelector(
    (state) => state.environmentAndSanitation
  );
  let pagination = parksAndGardens.pagination;

  const [tableMeta, setTableMeta] = useState({
    currentPage: 1,
    totalPages: 1,
    total: 0,
    limit: 10    
  });  

  const [profile, setprofile] = useState({});
  const [data, setdata] = useState([])
  const [loading, setloading] = useState(false) 

  const SetProfile = (ParksAndGardens) => {
    setprofile(ParksAndGardens);
  };

  const dataItems = [
    {
      id: 1,
      number: parksAndGardens?.meta?.ongoing,
      link: "",
      name: "Ongoing Projects",
      bck: "#7A6EFE",
    },
    {
      id: 2,
      number: parksAndGardens?.meta?.new,
      link: "",
      name: "New Projects",
      bck: "#FF5363",
    },
    {
      id: 3,
      number: parksAndGardens?.meta?.completed,
      link: "",
      name: "Completed Projects",
      bck: "#FFA901",
    },
  ];

  const TableDetailsInfo = () => (
    <section className="text-lg">
      <div className="w-full flex items-center justify-start pb-1">
        <div className="w-1/2">District</div>
        <div className="text-gray-1 w-1/2">{profile.name}</div>
      </div>
      <div className="w-full flex items-center justify-start pb-1">
        <div className="w-1/2">Specific sites</div>
        <div className="text-gray-1 w-1/2">{profile.site}</div>
      </div>
      <div className="w-full flex items-center justify-start pb-1">
        <div className="w-1/2">Location</div>
        <div className="text-gray-1 w-1/2">{profile.location}</div>
      </div>
      <div className="w-full flex items-center justify-start pb-1">
        <div className="w-1/2">Specific Support Provided by the Assembly</div>
        <div className="text-gray-1 w-1/2">{profile.support}</div>
      </div>
    </section>
  );

  const fetchParksAndGarden = (page, limit, search="") => {
    setloading(true);
    dispatch(getParksAndGardens(page, limit, search))
    .then(() => {
      setloading(false);
    }, () => {
      setloading(false);
    })
  };

  const handleParkAndGardensFetch = (type, value) => {
    switch (type) {
      case 'limit':
        setTableMeta((prev) => { return { ...prev, currentPage: 1, limit: value } });
        fetchParksAndGarden(1, value);
      break;

      case 'currentPage':
        setTableMeta((prev) => { return {  ...prev, currentPage: value }});        
        fetchParksAndGarden(value, tableMeta.limit);
      break;
    
      default:
        break;
    }
  };  

  useEffect(() => {
    let tepmArray = parksAndGardens?.list?.map((el) => {
      return  {
        name: el.district,
        site: el.sites,
        location: el.location,
        support: el.specific_support_provided
      };
    });

    setdata(tepmArray);

    // if revenue.pagination is available
    if (utils.present(parksAndGardens?.pagination)) {
      setTableMeta({
        ...tableMeta,
        total: pagination?.count,
        totalPages: pagination.totalPages,
        // limit: pagination.limit
      });
    }
  },[parksAndGardens]);

  useEffect(() => {
    fetchParksAndGarden();
  }, []);

  return (
    <div>
      <div className="w-full flex flex-row" style={{ width: "75%" }}>
        {dataItems.map((item) => (
          <div className="w-1/2 sm:w-1/3">
            <Widget
              id={item.id}
              link={item.link}
              number={item.number}
              name={item.name}
              bck={item.bck}
              lastItem={dataItems.length}
            />
          </div>
        ))}
      </div>

      <ProfileTable
        title="Parks And Gardens"
        tableHeading={tableHeading}
        SetProfile={SetProfile}
        list={data}
        hasFilter
        hasPagination
        entity="List"
        loading={loading}
        meta={tableMeta}
        fetchList={handleParkAndGardensFetch}
        handleSearch={(e) => fetchParksAndGarden(1, 10, e)} 
      >
        <TableDetailsInfo />
      </ProfileTable>
    </div>
  );
};

export default ParksAndGardens;