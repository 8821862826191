import React, { useState, useEffect } from 'react';
import Profile from "../../component/Profile/Profile";

// Redux
import { useDispatch } from 'react-redux';
import { getProfilesById } from "../../redux/profile/action";

const ProfileTabs = ({ id }) => {
  const dispatch = useDispatch();
  const [loading, setloading] = useState(false);

  const fetchProfiles = (page, limit) => {
    setloading(true);
    dispatch(getProfilesById(id, page, limit))
    .then((response) => {
      setloading(false);
      console.log('All profiles for a district', response.data);
    }, (err) => {
      setloading(false);
      console.error('Error fetching profiles:', err);
    })
  };

  useEffect(() => {
    fetchProfiles();
  }, []);  
    
  return (
    <Profile
      loading={loading}
      setloading={setloading}
      fetchProfiles={fetchProfiles}
      extendLinks={false}
    />
  )
}

export default ProfileTabs