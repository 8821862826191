import NoData from "../NoData";
import Card from "../Card";
import TableFilter from "../Tables/TableFilter";

const BuildingPlans = ({plans, handleShowModal,handleSearch, showSearch, loading}) => (
  <section>
    {showSearch && 
    <div className="pb-5">
      <TableFilter 
        parentSearchStyle="w-1/2 bg-light border border-gray-100 rounded-lg"
        placeholder="Search capacity building plans"
        handleSearch={(e) => handleSearch(e)}
      />
    </div>
    }

    <div className="flex flex-wrap items-stretch">
      {plans?.map((item) => (
        <Card
          date={item.date}
          label={item.label}
          text={item.text}
          view={item.view}
          item={item}
          setInfo={handleShowModal}
        />
      ))}
    </div>

    {loading ? (
          <div
            className={`${
              plans.length > 0 ? "h-full" : "h-64"
            } w-full absolute inset-0 bg-primary bg-opacity-25 rounded-b-lg transition-all ease-in duration-700 flex justify-center items-center`}
          >
            <svg
              className="animate-spin h-10 w-10 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </div>
        ) : (
          plans && plans?.length === 0 && <NoData entity="Issues" />
        )}
    
  </section>
);

export default BuildingPlans;